import React from "react";
import "../../App.css";
import Account from "./pages/Account";
import Cart from "./pages/Cart";
import Catalog from "./pages/Catalog";
import Category from "./pages/Category";
import ConfirmOrder from "./pages/ConfirmOrder";
import Information from "./pages/Information";
import Login from "./pages/Login";
import PaymentSuccess from "./pages/PaymentSuccess";
import Product from "./pages/Product";
import Search from "./pages/Search";
import Home from "./pages/Store/Home";
import Success from "./pages/Success";
const Main = ({ props, name }) => {
  switch (name) {
    case "home":
      return (
        <div className="default-theme">
          <Home {...props} />
        </div>
      );
    case "product":
      return (
        <div className="default-theme">
          <Product {...props} />
        </div>
      );
    case "search":
      return (
        <div className="default-theme">
          <Search {...props} />
        </div>
      );
    case "category":
      return (
        <div className="default-theme">
          <Category {...props} />
        </div>
      );
    case "catalog":
      return (
        <div className="default-theme">
          <Catalog {...props} />
        </div>
      );
    case "confirm-order":
      return (
        <div className="default-theme">
          <ConfirmOrder {...props} />
        </div>
      );
    case "success":
      return (
        <div className="default-theme">
          <Success {...props} />
        </div>
      );
    case "payment-success":
      return (
        <div className="default-theme">
          <PaymentSuccess {...props} />
        </div>
      );
    case "information":
      return (
        <div className="default-theme">
          <Information {...props} />
        </div>
      );
    case "account":
      return (
        <div className="default-theme">
          <Account {...props} />
        </div>
      );
    case "login":
      return (
        <div className="default-theme">
          <Login {...props} />
        </div>
      );
    case "cart":
      return (
        <div className="default-theme">
          <Cart {...props} />
        </div>
      );
    default:
      return <div>PAGE NOT FOUND</div>;
  }
};

export default Main;

// Import React
import React, { useRef, useState, useCallback } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"; // Add "InfoWindow"
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
// Import custom styles to customize the style of Google Map
const styles = require("./GoogleMapStyles.json");

// Import custom icon for map marker
// const mapMarker = require('./GoogleMapMarker.svg')

// Google Map component
const GoogleMapComponentWithMarker = withScriptjs(
  withGoogleMap((props) => {
    const searchBoxRef = useRef(null);
    const googleMapRef = useRef(null);
    const [location, setLocation] = useState(props.userLocation);
    const [markers, setMarkers] = useState([
      {
        name: "Current position",
        position: {
          lat: location.latitude,
          lng: location.longitude,
        },
      },
    ]);

    const onMarkerDragEnd = (coord, index) => {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();

      const markers_temp = [...markers];
      markers_temp[index] = { ...markers_temp[index], position: { lat, lng } };
      setMarkers(markers_temp);
    };
    console.log("map rendered with location ", location);
    if (location.latitude === 0 || location.longitude === 0)
      return <div>asas</div>;
    else
      return (
        <div>
          <GoogleMap
            ref={googleMapRef}
            defaultZoom={13}
            defaultCenter={{
              lat: location.latitude,
              lng: location.longitude,
            }}
            defaultOptions={{
              disableDefaultUI: true,
              draggable: true,
              keyboardShortcuts: false,
              scaleControl: true,
              scrollwheel: true,
              styles: styles,
            }}
            onCenterChanged={() => {
              console.log(
                googleMapRef.current.getCenter().lat(),
                googleMapRef.current.getCenter().lng()
              );
              props.onCenterChange(
                googleMapRef.current.getCenter().lat(),
                googleMapRef.current.getCenter().lng()
              );
            }}
          >
            <SearchBox
              ref={searchBoxRef}
              controlPosition={2}
              onPlacesChanged={() => {
                props.onLocationChange(
                  searchBoxRef.current.getPlaces()[0].geometry.location.lat(),
                  searchBoxRef.current.getPlaces()[0].geometry.location.lng()
                );
              }}
            >
              <input
                type="text"
                placeholder="Search"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  marginTop: `27px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                }}
              />
            </SearchBox>
          </GoogleMap>
        </div>
      );
  })
);

// Export Google Map component
export default GoogleMapComponentWithMarker;

import { Drawer } from "@material-ui/core";
import React, { useEffect } from "react";
import { Label } from "../../../../globalComponents/Label";
import { WeightPerItem } from "../../../../globalComponents/WeightPerItem";
import AddToCartButton from "../../components/AddToCartButton";
import Button from "../../components/Button/Button";
import { convertMoneyToText } from "../../lib/money";
import { ProductImagesSlider } from "../../widgets/ProductImagesSlider/ProductImagesSlider";
import { ProductListSlider } from "../../widgets/ProductListSlider/ProductListSlider";
import styles from "./styles.module.css";

const Product = ({
  resultAPI,
  addToCart,
  storeNameLink,
  setBuyNowButton,
  buyNowButton,
  history,
  bottomDrawer,
  setBottomDrawer,
  drawerJSX,
  moveForwardButton,
  multipleOptionsDrawer,
  setMultipleOptionsDrawer,
  quantityInCart,
  setQuantityInCart,
}) => {
  const handleSecondaryAction = async () => {
    if (
      resultAPI.data.addon?.length > 0 ||
      resultAPI.data.variant?.length > 0
    ) {
      setBottomDrawer(true);
      return;
    }

    if (buyNowButton === "Buy Now") {
      setBuyNowButton("Loading....");
      await addToCart(
        resultAPI?.data?.product?.product_id,
        resultAPI?.data?.product?.minimum_quantity
          ? parseInt(resultAPI?.data.product.minimum_quantity)
          : 1
      );
    }

    history.push({
      pathname: "/" + storeNameLink + "/cart",
    });
  };
  useEffect(() => {
    if (resultAPI?.data?.quantity_in_cart > 0) setBuyNowButton("Go to Cart");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAPI]);
  if (resultAPI.data) {
    const priceText = convertMoneyToText(resultAPI.data.product.price);
    const specialText = convertMoneyToText(resultAPI.data.product.special);
    const deliveryFeePerItem =
      resultAPI?.data?.product?.delivery_fee_per_item > 0
        ? convertMoneyToText(resultAPI?.data?.product?.delivery_fee_per_item)
        : undefined;

    return (
      <div className="max-width mt-5">
        <div className="row p-0">
          <div className="col-sm-5">
            {resultAPI.data.product_images && (
              <ProductImagesSlider
                images={resultAPI.data.product_images}
                imageKey="image"
                youtubeVideoURL={resultAPI.data.product?.video_url}
              />
            )}
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-6">
            <h1 className={styles.title}>
              {resultAPI.data.product_description.name}
            </h1>
            <h2 className={styles.category}>{resultAPI.data.category_name}</h2>
            <div className="mt-4">
              {resultAPI.data.product.special &&
              resultAPI.data.product.special !== "0" ? (
                <div className="d-flex pb-2">
                  <div className={`${styles.priceStrike}`}>
                    <strike>{priceText}</strike>
                  </div>
                  <div className={`pl-2 ${styles.special}`}>{specialText}</div>
                </div>
              ) : (
                <div className={`${styles.price} pb-2`}>{priceText}</div>
              )}
            </div>
            {resultAPI?.data?.product?.gst > 0 &&
            resultAPI?.data?.product?.is_gst_excluding === "1" ? (
              <h4
                style={{
                  fontStyle: "italic",
                  color: "#999",
                }}
              >
                ({resultAPI?.data?.product?.gst}% GST EXTRA)
                <br />
              </h4>
            ) : null}
            {resultAPI?.data?.product?.minimum_quantity > 1 ? (
              <Label
                text={`Minimum Order Quantity: ${resultAPI.data.product.minimum_quantity}`}
                className="mr-2"
              />
            ) : null}
            {deliveryFeePerItem ? (
              <Label
                text={`Delivery Fee Per Item: ${deliveryFeePerItem}`}
                className="mr-2"
              />
            ) : null}
            <hr />
            <div className="d-flex mt-5">
              {resultAPI.data.addon?.length > 0 ||
              resultAPI.data.variant?.length > 0 ? (
                <Button
                  onClick={() => {
                    setBottomDrawer(true);
                  }}
                  text="Add to Cart"
                  className="ml-2 px-5"
                />
              ) : (
                <AddToCartButton
                  className="h-100"
                  quantityInCart={resultAPI.data.quantity_in_cart}
                  minimumOrderQuantity={
                    resultAPI.data?.product?.minimum_quantity
                  }
                  onClick={(quantity) => {
                    if (quantity > 0) setBuyNowButton("Go to Cart");
                    else setBuyNowButton("Buy Now");
                    setQuantityInCart(quantity);
                    addToCart(resultAPI.data.product.product_id, quantity);
                  }}
                />
              )}

              <Button
                onClick={handleSecondaryAction}
                text={buyNowButton}
                className="ml-2 px-5"
              />
            </div>
            <div className="mt-5">
              <div className={styles.descriptionHeading}>Details:</div>
              <WeightPerItem weight={resultAPI.data.product.weight} />
              <p className={styles.description}>
                {resultAPI.data.product_description.description}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          {resultAPI.data.category_products && (
            <ProductListSlider
              data={resultAPI.data.category_products}
              category={"More Like This"}
              categoryId={resultAPI.data.category_id}
              addToCart={addToCart}
              storeNameLink={storeNameLink}
              totalCount={resultAPI.data.total_category_products}
            />
          )}
        </div>
        <Drawer
          anchor={"bottom"}
          open={bottomDrawer}
          onClose={() => {
            setBottomDrawer(false);
          }}
        >
          {drawerJSX(resultAPI.data.variant, resultAPI.data.addon)}
          {moveForwardButton()}
        </Drawer>
        <Drawer
          anchor={"bottom"}
          open={multipleOptionsDrawer}
          onClose={() => {
            setMultipleOptionsDrawer(false);
          }}
        >
          <div className="multipleOptionDrawerText">
            This product have multiple customizations,what you like to do?
          </div>
          <div className="d-flex justify-content-evenly my-4 p-4">
            <Button
              text="Repeat Last"
              className="w-100"
              onClick={() => {
                setMultipleOptionsDrawer(false);
                setBottomDrawer(true);
                setQuantityInCart(parseInt(quantityInCart) + 1);
                addToCart(
                  resultAPI.data.product.product_id,
                  parseInt(quantityInCart) + 1
                );
              }}
            />

            <Button
              text="I'll Choose Again"
              className="w-100"
              onClick={() => {
                window.location =
                  "/" +
                  storeNameLink +
                  "/product/" +
                  resultAPI.data.product.product_id +
                  "/addtocart";
              }}
            />
          </div>
        </Drawer>
      </div>
    );
  }
  return <></>;
};
export default Product;

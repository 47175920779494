import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import { OptionDetails } from "./OptionDetails";
import { styles } from "./styles";

export const ProductDetails = ({ isGstAllowed, invoiceData }) => {
  return (
    <>
      <View style={styles.tableHeader}>
        <Text style={styles.tableHeaderCell}>#</Text>
        <Text style={styles.tableHeaderCell}>Item</Text>
        <Text style={styles.tableHeaderCellTextRight}>Rate/Item</Text>
        <Text style={styles.tableHeaderCellTextRight}>Qty</Text>
        {isGstAllowed ? (
          <>
            <Text style={styles.tableHeaderCellTextRight}>Taxable Value</Text>
            <Text style={styles.tableHeaderCellTextRight}>Tax Amount</Text>
          </>
        ) : null}
        <Text style={styles.tableHeaderCellTextRight}>Amount</Text>
      </View>
      {invoiceData?.orderProduct?.map((item, index) => (
        <View style={styles.tableRow} key={`productlistrow/product${index}`}>
          <Text style={styles.tableCell}>{index + 1}</Text>
          <View style={styles.tableCell}>
            <Text style={styles.productName}>{item?.name}</Text>
            <>
              <OptionDetails options={item?.options_json} />
            </>
          </View>
          <Text style={styles.tableCellTextRight}>{item?.price}</Text>
          <Text style={styles.tableCellTextRight}>{item?.quantity}</Text>
          {isGstAllowed ? (
            <>
              <Text style={styles.tableCellTextRight}>
                {item?.taxable_value}
              </Text>
              <Text style={styles.tableCellTextRight}>
                {item?.tax_amount} ({item?.gst}%)
              </Text>
            </>
          ) : null}
          <Text style={styles.tableCellTextRight}>{item?.total}</Text>
        </View>
      ))}
      {invoiceData?.orderTotals?.map((item, index) =>
        item.code !== "total" ? (
          <View
            style={styles.tableRowSubtotal}
            key={`subtotallistrow/subtotal${index}`}
          >
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCellTextRight}>
              {item.code === "sub_total" && isGstAllowed
                ? "Taxable Value"
                : item.title}
            </Text>
            <Text style={styles.tableCellTextRight}>{item.value}</Text>
          </View>
        ) : (
          <View
            style={styles.tableRowSubtotal}
            key={`subtotallistrow/subtotal${index}`}
          >
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={[styles.tableCellTextRight, styles.totalCell]}>
              {item.title}
            </Text>
            <Text style={[styles.tableCellTextRight, styles.totalCell]}>
              <Image
                style={styles.rsLogo}
                src={`https://www.nearbybazar.com/favicon/image.php?src=https://www.nearbybazar.com/invoices/rs.jpg`}
              />
              {item.value}
            </Text>
          </View>
        )
      )}

      <View style={[styles.tableRow, styles.marginTop20]}>
        {invoiceData?.orderTotalInWords ? (
          <Text style={[styles.tableCellTextRight, styles.capitalize]}>
            Total Amount (in words): INR {invoiceData.orderTotalInWords}
          </Text>
        ) : null}
      </View>
    </>
  );
};

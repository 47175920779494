import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const CustomerDetails = ({ invoiceData }) => {
  return (
    <View style={styles.row}>
      <View style={styles.section}>
        <Text style={styles.invoice}>Invoice #: {invoiceData?.invoiceNo}</Text>
        <Text style={styles.normalText}>Customer Name:</Text>
        <Text style={styles.boldText}>{invoiceData?.customerName}</Text>
      </View>
      <View style={styles.section}>
        <Text style={[styles.invoiceDate]}>
          Invoice Date: {invoiceData?.invoiceDate}
        </Text>
        <Text style={styles.boldText}>Billing Address:</Text>
        <Text style={styles.address}>{invoiceData?.customerAddress}</Text>
        <Text style={styles.address}>
          {invoiceData?.customerCity}, {invoiceData?.customerState},{" "}
          {invoiceData?.customerPincode}
        </Text>
        <Text style={styles.address}>Mobile {invoiceData?.customerPhone}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.invoice}> </Text>
        <Text style={styles.boldText}>Shipping Address:</Text>
        <Text style={styles.address}>{invoiceData?.customerAddress}</Text>
        <Text style={styles.address}>
          {invoiceData?.customerCity}, {invoiceData?.customerState},{" "}
          {invoiceData?.customerPincode}
        </Text>
        <Text style={styles.address}>Mobile {invoiceData?.customerPhone}</Text>
      </View>
    </View>
  );
};

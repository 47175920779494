import { LinearProgress } from "@material-ui/core";
import ls from "local-storage";
import React, { useRef, useState } from "react";
import { Button, Carousel, Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import Logo from "../../../../globalComponents/Logo/Logo";
import Reviews from "../../../../globalComponents/Reviews";
import CartButton from "../../components/CartButton/CartButton";
import ProductCard from "../../components/DetailsCard";
import { Footer } from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar";
import OverlayTextCard from "../../components/OverlayTextCard";
import Searchbar from "../../components/Searchbar";

const Home = ({ resultAPI, addToCart }) => {
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const location = useLocation();
  const hostDomain = require("../../../../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;
  const [swiperWidth, setSwiperWidth] = useState(0);
  const swiperRef = useRef(null);

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  var sliderViewCount = 3.3;

  if (window.innerWidth > 600) sliderViewCount = 7;
  if (swiperRef.current && swiperWidth !== swiperRef.current.offsetWidth - 10) {
    console.log("nhjb");
    setSwiperWidth(swiperRef.current.offsetWidth - 10);
    ls.set("swiperWidth", swiperRef.current.offsetWidth - 10);
  } else if (swiperWidth === 0 && ls.get("swiperWidth")) {
    setSwiperWidth(ls.get("swiperWidth"));
  }
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow:
      resultAPI.status === "success"
        ? resultAPI.data.all_category.length < 7
          ? 7.3
          : 7.3
        : 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div>
      <Navbar storeName={storeNameLink} />
      {resultAPI.status === "success" ? (
        resultAPI.data.is_premium !== 1 ? (
          <a
            style={{ textDecoration: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://play.google.com/store/apps/details?id=com.nearbybazarbusiness`}
          >
            <div className="lobecho-branding">
              Made using LoBecho. <u>Download App</u>
            </div>
          </a>
        ) : null
      ) : null}

      {resultAPI.status === "success" ? (
        <React.Fragment>
          <div className="container">
            <title>{resultAPI.data.store_name + ": Order Online Now"} </title>
            <meta
              name="description"
              content={
                "Order Online From " +
                resultAPI.data.store_name +
                " and get quick delivery on all of your orders, we are dedicated to serve you at the top most priority. Here at " +
                resultAPI.data.store_name +
                " we always give best and quality products and we are always there for your help. Why wait? Start Ordering Now!"
              }
            />
            <meta
              name="keywords"
              content={
                resultAPI.data.store_name +
                "," +
                resultAPI.data.store_name +
                " online store," +
                resultAPI.data.store_name +
                "order online, " +
                resultAPI.data.store_name +
                " website, " +
                resultAPI.data.store_name +
                " delivery"
              }
            />

            <div className="d-flex align-items-center justify-content-between">
              <Logo url={resultAPI?.data?.store_image} />
              <CartButton className="ml-3" storeNameLink={storeNameLink} />
            </div>

            <Searchbar
              placeholder="Search Products.."
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              onButtonHit={() => {
                history.push({
                  pathname: "/" + storeNameLink + "/search/" + searchValue,
                });
              }}
            />
            {resultAPI.data.free_delivery_above_status === "1" ? (
              <div className="freeDeliveryDiv">
                YEH! FREE DELIVERY ABOVE ₹{resultAPI.data.free_delivery_above}
              </div>
            ) : null}
            <div className="banner" style={{ paddingTop: "10px" }}>
              <Carousel controls={true}>
                {Object.values(resultAPI.data.banner).map((image) => (
                  <Carousel.Item>
                    <a
                      href={
                        image.link_type !== "none"
                          ? "/" +
                            storeNameLink +
                            "/" +
                            image.link_type +
                            "/" +
                            image.link_id
                          : null
                      }
                    >
                      <img
                        className="d-block banner-carousel-image"
                        src={image.image}
                        alt="First slide"
                      />
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>

            {resultAPI.data.all_catalog.length > 0 ? (
              <div className="categories-slider">
                <h5 className="categoriesSliderHeading">Featured Catalogs</h5>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={sliderViewCount}
                  freeMode={true}
                >
                  {Object.values(resultAPI.data.all_catalog).map((catalog) => (
                    <SwiperSlide ref={swiperRef}>
                      <Link
                        to={{
                          pathname: `/${storeNameLink}/catalog/${catalog.biz_shop_catalog_id}`,
                        }}
                      >
                        <OverlayTextCard
                          image={catalog.image}
                          text={catalog.name}
                          key={catalog.biz_shop_category_id}
                          width={swiperWidth > 0 ? swiperWidth : 90}
                        />
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : null}
            {resultAPI.data.all_category.length > 0 ? (
              <div className="categories-slider">
                <h5 className="categoriesSliderHeading">Featured Categories</h5>
                <Swiper
                  spaceBetween={5}
                  slidesPerView={sliderViewCount}
                  freeMode={true}
                >
                  {Object.values(resultAPI.data.all_category).map(
                    (category) => (
                      <SwiperSlide>
                        <Link
                          to={{
                            pathname: `/${storeNameLink}/category/${category.biz_shop_category_id}`,
                          }}
                        >
                          <OverlayTextCard
                            image={category.image}
                            text={category.name}
                            key={category.biz_shop_category_id}
                            width={swiperWidth > 0 ? swiperWidth : 90}
                          />
                        </Link>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            ) : resultAPI.data.all_category.length === 0 &&
              resultAPI.data.all_catalog.length === 0 ? (
              <div>
                <center>
                  <img
                    src="https://www.nearbybazar.com/biz_images/frontend/comingsoon.gif"
                    alt="coming soon"
                    style={{ height: "300px", marginTop: "100" }}
                  />
                </center>
              </div>
            ) : null}
            <div className="products-under-category-list">
              {Object.values(resultAPI.data.all_category).map((category) => (
                <React.Fragment>
                  {category.products.length > 0 && (
                    <Row>
                      <Col xl={10} sm={10} xs={8}>
                        <p className="category-heading">{category.name}</p>
                        <br />
                      </Col>
                      <Col xl={2} sm={2} xs={4}>
                        <p className="category-heading">
                          <Link
                            to={{
                              pathname: `/${storeNameLink}/category/${category.biz_shop_category_id}`,
                            }}
                          >
                            {category.total_products_count > 8 && (
                              <Button className="see-all-button">
                                See All{" "}
                                <span
                                  style={{
                                    color: " white",
                                    background: "#117fd6",
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    borderRadius: 4,
                                  }}
                                >
                                  {category.total_products_count}
                                </span>
                              </Button>
                            )}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={40}>
                    {Object.values(category.products).map(
                      (product, index) =>
                        index <= 7 && (
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 4 }}
                            md={{ span: 4 }}
                            lg={{ span: 3 }}
                            xl={{ span: 3 }}
                            key={category.id}
                          >
                            <ProductCard
                              image={product.product_data.image}
                              text={product.product_data.productname}
                              price={product.product_data.price}
                              special={product.product_data.special}
                              haveOptions={product.product_data.haveOptions}
                              storeName={storeNameLink}
                              productID={product.product_data.product_id}
                              minimumOrderQuantity={
                                product.product_data.minimum_quantity
                              }
                              quantityInCart={
                                product.product_data.quantity_in_cart
                              }
                              addToCart={(quantity) => {
                                addToCart(
                                  product.product_data.product_id,
                                  quantity
                                );
                              }}
                            />
                          </Col>
                        )
                    )}
                  </Row>
                </React.Fragment>
              ))}
            </div>
          </div>
          {resultAPI?.data?.reviews ? (
            <Reviews data={resultAPI.data.reviews} />
          ) : null}
          <Footer storeNameLink={storeNameLink} className="mt-5 pb-5 mb-5 " />
          <div className="mt-5 pt-5"></div>
        </React.Fragment>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </div>
  );
};

export default Home;

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
//import Button from "../../components/Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import Button from "../../components/Button/Button";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { ShowAllCard } from "../../components/ShowAllCard/ShowAllCard";
import styles from "./style.module.scss";

export const ProductListSlider = ({
  data,
  category,
  categoryId,
  storeNameLink,
  addToCart,
  totalCount = 8,
}) => {
  const swiperRef = useRef(null);
  const handlePrevClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const getSwiperSlide = () => {
    const renderableElements = [];
    var count = 0;
    var keyCounter = 0;
    if (data.length === 0) {
      renderableElements.push(<></>);
    }
    while (count < data.length) {
      keyCounter++;
      const renderableData = [];
      const end = count + 4 <= data.length ? count + 4 : data.length;
      for (let i = count; i < end; i++) {
        if (data[i]) renderableData.push(data[i]);
      }

      renderableElements.push(
        <SwiperSlide key={`${keyCounter}-product-${category}`}>
          <div className={`row max-width p-0 mt-4 ${styles.boxSizing}`}>
            {renderableData.map((item, index) => {
              return (
                <div
                  className="col-sm-3 col-6 mb-2"
                  key={`${index}-${item.name}`}
                >
                  {totalCount > 8 && index === 3 && end === data.length ? (
                    <Link
                      to={{
                        pathname: `/${storeNameLink}/category/${categoryId}`,
                      }}
                    >
                      <ShowAllCard actionText={`Show All ${totalCount}`} />
                    </Link>
                  ) : (
                    <ProductCard
                      image={item?.product_data?.image}
                      storeNameLink={storeNameLink}
                      name={item?.product_data?.productname}
                      category={category}
                      price={item.product_data?.price}
                      special={item.product_data?.special}
                      haveOptions={item.product_data?.haveOptions === "1"}
                      product_id={item.product_data.product_id}
                      minimumQuantity={item.product_data?.minimum_quantity}
                      addToCart={addToCart}
                      quantityInCart={item.product_data.quantity_in_cart}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </SwiperSlide>
      );
      count = count + 4 <= data.length ? count + 4 : data.length;
    }
    return renderableElements;
  };
  //<ShowAllCard actionText="Show All 49" />
  return (
    <>
      <div className={`max-width my-5 pt-5 px-4 ${styles.buttonContainer}`}>
        <div className={styles.title}>{category}</div>
        <div className="d-flex align-items-center">
          <Button
            Icon={ChevronLeftIcon}
            className={styles.leftButton}
            onClick={handlePrevClick}
          />

          <Button
            Icon={ChevronRightIcon}
            className={styles.rightButton}
            onClick={handleNextClick}
          />
        </div>
      </div>

      <Swiper spaceBetween={0} ref={swiperRef} slidesPerView={1}>
        {getSwiperSlide().map((item, index) => {
          return item;
        })}
      </Swiper>
    </>
  );
};

import React from "react";
const Success = ({ orderID }) => {
  return (
    <div className="success-page" style={{ background: "#aaa" }}>
      <div className="success-container">
        <div className="printer-top"></div>

        <div className="paper-container">
          <div className="printer-bottom"></div>

          <div className="paper">
            <div className="main-contents">
              <div className="success-icon">&#10004;</div>
              <div className="success-title">Order Placed</div>
              <div className="success-description">
                Thank you for order with us. We will deliver your order as soon
                as possible. You can view your order details on account section.
              </div>
              <div className="order-details">
                <div className="order-number-label"></div>
                <div className="order-number">ORDER # :{orderID}</div>
              </div>
              <div className="order-footer">Thank you!</div>
            </div>
            <div className="jagged-edge"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Success;

import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";

const PaymentSuccess = ({ ...props }) => {
  const history = useHistory();
  const [orderId, setOrderId] = useState(undefined);
  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const [nearbybazarAPIConfirmPayment, resultAPIConfirmPayment] = APIHook();

  const clearCart = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIConfirmPayment(data, "/front/clearcartafterpayment");
  };

  const confirmPayment = async () => {
    const tx_id = localStorage.getItem("pg_tx_id");
    if (tx_id) {
      const data = {
        tx_id: tx_id,
      };

      const response = await nearbybazarAPIConfirmPayment(
        data,
        "/universalpg/confirmpayment",
        "business"
      );
      if (response?.data) {
        setOrderId(response?.data?.orderId);
        setPaymentStatus(response?.data?.status);
        if (
          response?.data?.status === "SUCCESS" ||
          response?.data?.status === "FAILED"
        ) {
          clearCart();
        }
      }
    }
  };

  useEffect(() => {
    confirmPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (paymentStatus) {
    return (
      <ThemeChooser
        props={{
          storeNameLink: storeNameLink,
          orderID: orderId,
          status: paymentStatus,
          history: history,
        }}
        name="payment-success"
      />
    );
  } else {
    return <LinearProgress color="secondary" />;
  }
};

export default PaymentSuccess;

import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import styles from "./style.module.scss";

export const Pagination = ({
  className,
  limit = 12, //fix limit here
  offset = 0,
  total,
}) => {
  const history = useHistory();
  const start = offset + 1;
  const end = offset + limit > total ? total : offset + limit;

  const onClickNext = () => {
    history.push({
      pathname: window.location.pathname,
      search: "?offset=" + (parseInt(offset) + limit),
    });
  };
  const onClickPrev = () => {
    // if (!noLoading) setIsLoading(true);
    history.push({
      pathname: window.location.pathname,
      search: "offset=" + (parseInt(offset) - limit),
    });
  };

  return (
    <div
      className={`p-2 d-flex justify-content-between align-items-center ${className}`}
    >
      <Button text="<< Prev" md disabled={offset === 0} onClick={onClickPrev} />
      <div className="d-flex">
        <div className={`${styles.text} mr-2`} size="xs" weight="sm">
          Showing
        </div>
        <div className={styles.text} size="xs">
          {start} - {end}
        </div>
        <div className={`${styles.text}  mx-2`} size="xs" weight="sm">
          of
        </div>
        <div className={styles.text} size="xs">
          {total}
        </div>
      </div>
      <Button text="Next >>" md disabled={end == total} onClick={onClickNext} />
    </div>
  );
};

import React, { useState } from "react";
import Button from "../Button/Button";
import styles from "./styles.module.css";
const AddToCartButton = ({
  className,
  sm,
  md,
  quantityInCart,
  minimumOrderQuantity = 1,
  onClick,
  animated = false,
  Icon,
}) => {
  const [count, setCount] = useState(parseInt(quantityInCart) || 0);
  const handleFirstClick = () => {
    setCount(minimumOrderQuantity);
    onClick(minimumOrderQuantity);
  };
  const handlePlusClick = () => {
    setCount((prev) => prev + 1);
    onClick(count + 1);
  };
  const handleMinusClick = () => {
    if (count > minimumOrderQuantity) {
      setCount((prev) => prev - 1);
      onClick(count - 1);
    } else {
      setCount(0);
      onClick(0);
    }
  };

  return (
    <div
      className={`${styles.AddToCartButton} ${className} ${
        md ? styles.buttonMD : ""
      }`}
    >
      {count > 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          <Button text="-" onClick={handleMinusClick} className={"w-100"} />
          <div className={`${styles.count} w-100`}>{count}</div>
          <Button text="+" onClick={handlePlusClick} className={"w-100"} />
        </div>
      ) : (
        <Button
          className="w-100"
          text="Add to Cart"
          onClick={handleFirstClick}
          animated={animated}
          Icon={Icon}
        />
      )}
    </div>
  );
};
export default AddToCartButton;

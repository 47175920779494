export const convertMoneyToText = (val) => {
  const currency = "₹";
  val = parseFloat(val);
  if (val < 10000) val = val.toFixed(2).replace(/\.00$/, "");
  else if (val < 1000000)
    val = (val / 1000).toFixed(2).replace(/\.00$/, "") + "K";
  else val = (val / 1000000).toFixed(2).replace(/\.00$/, "") + "M";
  return currency + "" + val;
};
export const convertNumberToText = (val) => {
  val = parseFloat(val);
  if (val < 1000) val = val.toFixed(2).replace(/\.00$/, "");
  else if (val < 1000000)
    val = (val / 1000).toFixed(2).replace(/\.00$/, "") + "K";
  else val = (val / 1000000).toFixed(2).replace(/\.00$/, "") + "M";
  return val;
};

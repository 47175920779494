import React, { useEffect, useRef } from "react";

const PaymentForm = ({ tx_id }) => {
  const paymentFormRef = useRef(null);
  useEffect(() => {
    paymentFormRef.current.submit();
  }, []);

  return (
    <form
      id="submitform"
      ref={paymentFormRef}
      method="post"
      action="https://www.nearbybazar.com/api/business/universalpg/payment"
    >
      <input type="hidden" name="txid" value={tx_id} />
    </form>
  );
};

export default PaymentForm;

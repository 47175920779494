import React from "react";
import { BlankSpace } from "../../components/BlankSpace/BlankSpace";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import styles from "./styles.module.css";
const Search = ({ searchValue, products, addToCart, storeNameLink }) => {
  return (
    <div className="max-width pt-5">
      {products?.length > 0 ? (
        <>
          <h1 className={styles.heading}>
            Search Result{products.length > 1 ? "s" : ""} for "{searchValue}"
          </h1>
          <div className="row mt-5">
            {products.map((item, index) => {
              return (
                <div className="col-sm-3 col-6 md mb-5" key={index}>
                  <ProductCard
                    image={item.product_data.image}
                    category={item.product_data.category}
                    name={item.product_data.productname}
                    price={item.product_data.price}
                    special={item.product_data.special}
                    quantityInCart={item.product_data.quantity_in_cart}
                    minimumQuantity={item.product_data?.minimum_quantity}
                    product_id={item.product_data.product_id}
                    addToCart={addToCart}
                    storeNameLink={storeNameLink}
                    haveOptions={item.product_data.haveOptions === "1"}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div>
          <BlankSpace text={`No Products found for "${searchValue}"`} />
        </div>
      )}
    </div>
  );
};
export default Search;

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../../components/DetailsCard";
import { Footer } from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar";
import OverlayTextCard from "../../components/OverlayTextCard";
import Searchbar from "../../components/Searchbar";
const Catalog = ({
  resultAPI,
  searchValue,
  setSearchValue,
  history,
  storeNameLink,
  sliderViewCount,
  swiperWidth,
  addToCart,
  catalogID,
}) => {
  const [catalog, setCatalog] = useState({});
  useEffect(() => {
    const findCatalog = (catalogs, id) => {
      const result = catalogs.filter((item) => item.biz_shop_catalog_id === id);
      return result;
    };

    if (resultAPI?.data?.all_category) {
      setCatalog(findCatalog(resultAPI.data.all_catalog, catalogID));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAPI]);
  return (
    <div>
      <div className="container">
        <React.Fragment>
          <title>{resultAPI.data.store_name + ": Order Online Now"} </title>
          <meta
            name="description"
            content={
              "Order Online From " +
              resultAPI.data.store_name +
              " and get quick delivery on all of your orders, we are dedicated to serve you at the top most priority. Here at " +
              resultAPI.data.store_name +
              " we always give best and quality products and we are always there for your help. Why wait? Start Ordering Now!"
            }
          />
          <meta
            name="keywords"
            content={
              resultAPI.data.store_name +
              "," +
              resultAPI.data.store_name +
              " online store," +
              resultAPI.data.store_name +
              "order online, " +
              resultAPI.data.store_name +
              " website, " +
              resultAPI.data.store_name +
              " delivery"
            }
          />
          <div className="pl-2 py-4 d-flex align-items-center">
            <div
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIcon className="productHeaderIcon" />
            </div>

            <div className="product-page-heading-title">
              {" "}
              {catalog.length ? catalog[0].name : ""}
            </div>
          </div>

          <Searchbar
            placeholder="Search Products.."
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onButtonHit={() => {
              history.push({
                pathname: "/" + storeNameLink + "/search/" + searchValue,
              });
            }}
          />

          <div className="categories-slider">
            <h5 className="categoriesSliderHeading">Featured Categories</h5>
            <Swiper
              spaceBetween={5}
              slidesPerView={sliderViewCount}
              freeMode={true}
            >
              {Object.values(resultAPI.data.all_category).map((category) => (
                <SwiperSlide>
                  <Link
                    to={{
                      pathname: `/${storeNameLink}/category/${category.biz_shop_category_id}`,
                    }}
                  >
                    <OverlayTextCard
                      image={category.image}
                      text={category.name}
                      key={category.biz_shop_category_id}
                      width={swiperWidth}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="products-under-category-list">
            {Object.values(resultAPI.data.all_category).map((category) => (
              <React.Fragment>
                {category.products.length > 0 && (
                  <Row>
                    <Col xl={10} sm={10} xs={8}>
                      <p className="category-heading">{category.name}</p>
                      <br />
                    </Col>
                    <Col xl={2} sm={2} xs={4}>
                      <p className="category-heading">
                        <Link
                          to={{
                            pathname: `/${storeNameLink}/category/${category.biz_shop_category_id}`,
                          }}
                        >
                          {category.products.length > 8 && (
                            <Button className="see-all-button">
                              See All{" "}
                              <span
                                style={{
                                  color: " white",
                                  background: "#117fd6",
                                  paddingLeft: 6,
                                  paddingRight: 6,
                                  borderRadius: 4,
                                }}
                              >
                                {" "}
                                {category.products.length}{" "}
                              </span>
                            </Button>
                          )}
                        </Link>
                      </p>
                    </Col>
                  </Row>
                )}

                <Row gutter={40}>
                  {Object.values(category.products).map(
                    (product, index) =>
                      index <= 7 && (
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 3 }}
                          xl={{ span: 3 }}
                          key={category.id}
                        >
                          <ProductCard
                            image={product.product_data.image}
                            text={product.product_data.productname}
                            price={product.product_data.price}
                            special={product.product_data.special}
                            haveOptions={product.product_data.haveOptions}
                            minimumOrderQuantity={
                              product.product_data.minimum_quantity
                            }
                            storeName={storeNameLink}
                            productID={product.product_data.product_id}
                            quantityInCart={
                              product.product_data.quantity_in_cart
                            }
                            addToCart={(quantity) => {
                              addToCart(
                                product.product_data.product_id,
                                quantity
                              );
                            }}
                          />
                        </Col>
                      )
                  )}
                </Row>
              </React.Fragment>
            ))}
          </div>

          <div className="margin-bottom-150px"></div>
        </React.Fragment>
      </div>
      <Footer storeNameLink={storeNameLink} className="mt-5 pb-5 mb-5 " />
      <Navbar storeName={storeNameLink} />
    </div>
  );
};
export default Catalog;

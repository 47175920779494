import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ProductCard from "../../components/DetailsCard";
import { Footer } from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar";
import { Pagination } from "../../components/Pagination/Pagination";
import Searchbar from "../../components/Searchbar";

const Category = ({
  products,
  categoryData,
  storeNameLink,
  addToCart,
  limit,
  offset,
  totalProducts,
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="container">
        <div className="pl-0 py-4 d-flex align-items-center">
          <div
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBackIcon className="productHeaderIcon" />
          </div>

          <div className="product-page-heading-title">{categoryData?.name}</div>
        </div>
        <Searchbar placeholder="Search Products.." />
        <hr />
        <div>
          {products.length > 0 ? (
            <div className="products-under-category-list">
              <Row gutter={40}>
                {Object.values(products).map((product, index) => (
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 4 }}
                    md={{ span: 4 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                    key={products.product_id}
                  >
                    <ProductCard
                      image={product.product_data.image}
                      text={product.product_data.productname}
                      price={product.product_data.price}
                      special={product.product_data.special}
                      haveOptions={product.product_data.haveOptions}
                      storeName={storeNameLink}
                      productID={product.product_data.product_id}
                      minimumOrderQuantity={
                        product.product_data.minimum_quantity
                      }
                      quantityInCart={product.product_data.quantity_in_cart}
                      addToCart={(quantity) => {
                        addToCart(product.product_data.product_id, quantity);
                      }}
                    />
                  </Col>
                ))}
              </Row>

              <Pagination
                limit={limit}
                offset={parseInt(offset)}
                total={totalProducts}
                className="my-5"
              />
              <div class="margin-bottom-150px"></div>
            </div>
          ) : (
            <div className="noProductsFound">No Products Found.</div>
          )}
        </div>
      </div>
      <Footer storeNameLink={storeNameLink} className="mt-5 pb-5 mb-5 " />
      <Navbar storeName={storeNameLink} />
    </div>
  );
};
export default Category;

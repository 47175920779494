import SearchIcon from "@material-ui/icons/Search";
import ls from "local-storage";
import React, { useContext, useEffect, useRef, useState } from "react";
import { accountContext } from "../../../../helper/AccountContext";
import APIHook from "../../../../helper/APIHook";
import { cartContext } from "../../../../helper/cartContext";
import CartButton from "../../components/CartButton/CartButton";
import HeaderMessageBar from "../../components/HeaderMessageBar/HeaderMessageBar";
import InputText from "../../components/InputText/InputText";
import Logo from "../../components/Logo/Logo";
import MyAccountButton from "../../components/MyAccountButton/MyAccountButton";
import Nav from "../../components/Nav/Nav";
import { whichDevice } from "../../lib/device";
import styles from "./style.module.css";

const Header = ({ storeNameLink, storeName, initialSearchText = "" }) => {
  const [accountData, setAccountData] = useContext(accountContext);
  const [cartValue, setCartValue] = useContext(cartContext);
  const [data, setData] = useState({});
  const device = whichDevice();
  const mobileHeaderRef = useRef(null);
  const [headerHeight, setheaderHeight] = useState(0);
  const [nearbybazarAPIFRONT, resultAPIFRONT] = APIHook();

  const handleSearch = (e) => {
    if (e.target.value) {
      window.location = `/${storeNameLink}/search/${e.target.value}`;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const height = mobileHeaderRef?.current?.offsetHeight;
      setheaderHeight(height);
    }, 500);
  }, [mobileHeaderRef]);

  useEffect(() => {
    const localData = ls.get("storeData");
    if (localData) {
      setData(localData.data);
      if (!accountData.name && localData.data.customer_id !== "0")
        setAccountData({ name: localData.data.customer_id });
      //TODO: fix required for cart count not loading...
    } else {
      //get front api and set localstorage
      const payload = {
        link: storeName,
      };
      console.log("SENDING API REQUEST WITH DATA " + payload);

      nearbybazarAPIFRONT(payload, "/front/front/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resultAPIFRONT?.data) {
      setData(resultAPIFRONT.data);
      ls.set("storeData", resultAPIFRONT);
      if (resultAPIFRONT.data.customer_id !== "0")
        setAccountData({ name: resultAPIFRONT.data.customer_id });
      setCartValue(resultAPIFRONT.data.products_in_cart);
    }
  }, [resultAPIFRONT, setAccountData, setCartValue]);

  if (data?.store_image)
    return device === "mobile" ? (
      <div ref={mobileHeaderRef} className={styles.stickToTop}>
        {data?.free_delivery_above_status === "1" && (
          <HeaderMessageBar
            message={`FREE DELIVERY ABOVE ${data?.free_delivery_above}`}
          />
        )}
        <div className={`${styles.container}`}>
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <Nav
                headerHeight={headerHeight}
                data={data}
                storeNameLink={storeNameLink}
              />

              <Logo url={data?.store_image} storeNameLink={storeNameLink} />
            </div>

            <div className="col-6 d-flex justify-content-end align-items-center pr-5">
              <MyAccountButton className="mr-1" storeNameLink={storeNameLink} />
              <CartButton className="ml-1" storeNameLink={storeNameLink} />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <InputText
            Icon={SearchIcon}
            value={initialSearchText}
            placeholder="Search for Products..."
            onEnterAction={handleSearch}
            onClickAction={handleSearch}
          />
        </div>
      </div>
    ) : (
      <div className={styles.stickToTop}>
        {data?.free_delivery_above_status === "1" && (
          <HeaderMessageBar
            message={`FREE DELIVERY ABOVE ${data?.free_delivery_above}`}
          />
        )}
        <div className={`${styles.container}`}>
          <div className="row">
            <div className="col-sm-3 ">
              <Logo url={data?.store_image} storeNameLink={storeNameLink} />
            </div>
            <div className="col-sm-6 d-flex justify-content-center">
              <InputText
                Icon={SearchIcon}
                placeholder="Search for Products..."
                value={initialSearchText}
                onEnterAction={handleSearch}
                onClickAction={handleSearch}
              />
            </div>
            <div className="col-sm-3 d-flex justify-content-end align-items-center">
              <MyAccountButton className="mr-3" storeNameLink={storeNameLink} />
              <CartButton className="ml-3" storeNameLink={storeNameLink} />
            </div>
          </div>
        </div>

        <Nav data={data} storeNameLink={storeNameLink} />
      </div>
    );
  else return <></>;
};
export default Header;

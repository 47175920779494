import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const StoreDetails = ({ isGstAllowed, invoiceData }) => {
  return (
    <View style={styles.row}>
      <View style={styles.section}>
        <Text style={styles.heading}>{isGstAllowed ? "TAX " : ""}INVOICE</Text>
        <Text style={styles.storeName}>{invoiceData?.shopName}</Text>
        {isGstAllowed ? (
          <Text style={styles.gst}>GSTIN: {invoiceData?.gst}</Text>
        ) : null}
        <Text style={styles.address}>{invoiceData?.shopAddress}</Text>
        <Text style={styles.address}>
          {invoiceData?.shopCity}, {invoiceData?.shopState},{" "}
          {invoiceData?.shopPincode}
        </Text>
        <Text style={styles.address}>Mobile {invoiceData?.shopPhone}</Text>
        <Text style={styles.link}>Website: {invoiceData?.shopWebsite}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading2}>ORIGINAL FOR RECIPIENT</Text>
        {/* Proxy image request to get rid of cors, this image.php will send correct headers */}
        <Image
          style={styles.storeLogo}
          src={`https://www.nearbybazar.com/favicon/image.php?src=${invoiceData?.shopLogo}`}
        />
      </View>
    </View>
  );
};

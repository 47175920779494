/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@material-ui/core";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";

const ProductsList = () => {
  const location = useLocation();
  const categoryID = location.pathname.split("/")[3];
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const [offset, setOffset] = useState(0);
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  const [nearbybazarAPIProducts, resultAPIProducts] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [cartValue, setCartValue] = useContext(cartContext);
  const LIMIT = 12;
  const getProducts = async (currentOffset = 0) => {
    const data = {
      link: storeName,
      category_id: categoryID,
      limit: LIMIT,
      offset: currentOffset,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIProducts(data, "/front/categoryproducts");
  };
  const addToCart = async (pid, quantity) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: "{}",
      addon: "{}",
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/addtocart/");
  };

  useEffect(() => {
    const query = queryString.parse(location.search);
    //setting this for the sake of changing category through nav bar
    if (!query?.offset) setOffset(0);

    if (query?.offset && query?.offset !== offset) {
      setOffset(query.offset);
      if (categoryID) getProducts(query.offset);
    } else if (categoryID) getProducts();
  }, [location]);

  if (resultAPICart.status === "success") {
    if (cartValue !== parseInt(resultAPICart.data.cart_items))
      setCartValue(parseInt(resultAPICart.data.cart_items));
  }
  if (resultAPIProducts.status === "success") {
    const products = resultAPIProducts.data.products;
    const categoryData = resultAPIProducts.data.category;

    return (
      <ThemeChooser
        props={{
          products: products,
          categoryData: categoryData,
          storeNameLink: storeNameLink,
          addToCart: addToCart,
          totalProducts: resultAPIProducts.data.total_products,
          offset: offset,
          limit: LIMIT,
        }}
        name="category"
      />
    );
  } else {
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
  }
};

export default ProductsList;

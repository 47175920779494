import { LinearProgress } from "@material-ui/core";
import React from "react";
import { Image } from "react-bootstrap";

const styles = {
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    position: "absolute",
    width: " 100%",
    height: "100%",
  },
  image: {
    width: "200px",
    height: "auto",
  },
  linearProgress: {
    width: "200px",
    marginTop: "5px",
  },
};
export const LoaderComponent = ({ storeName }) => {
  return (
    <div style={styles.loaderContainer}>
      <Image
        src={`https://www.nearbybazar.com/favicon/storelogo.php?link=${storeName}`}
        style={styles.image}
      />
      <LinearProgress style={styles.linearProgress} color="secondary" />
    </div>
  );
};

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import Button from "../../components/Button/Button";
import { CategoryCard } from "../../components/CategoryCard/CategoryCard";
import styles from "./style.module.scss";

export const CategoryListSlider = ({
  forCatalog,
  title = "Default",
  data,
  storeNameLink,
  dataKey,
}) => {
  const swiperRef = useRef(null);
  const handlePrevClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const getSwiperSlide = () => {
    const renderableElements = [];
    var count = 0;
    while (count < data.length) {
      const renderableData = [];
      const end = count + 4 <= data.length ? count + 4 : data.length;
      for (let i = count; i < end; i++) {
        if (data[i]) renderableData.push(data[i]);
      }
      renderableElements.push(
        <SwiperSlide key={count + "category"}>
          <div className={`row max-width p-0 mt-4 ${styles.boxSizing}`}>
            {renderableData.map((item, index) => {
              return (
                <div className="col-sm-3 col-6 mb-2" key={index + item.name}>
                  <Link
                    to={{
                      pathname: `/${storeNameLink}/${item[dataKey]}`,
                    }}
                  >
                    <CategoryCard
                      image={item.image}
                      name={item.name}
                      catalogName={item.name}
                      forCatalog={forCatalog}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </SwiperSlide>
      );
      count = count + 4 <= data.length ? count + 4 : data.length;
    }
    return renderableElements;
  };

  return (
    <>
      <div className={`max-width pt-5 px-4 ${styles.buttonContainer}`}>
        <div className={styles.title}>{title}</div>
        <div className="d-flex align-items-center">
          <Button
            Icon={ChevronLeftIcon}
            className={styles.leftButton}
            onClick={handlePrevClick}
          />

          <Button
            Icon={ChevronRightIcon}
            className={styles.rightButton}
            onClick={handleNextClick}
          />
        </div>
      </div>

      <Swiper spaceBetween={0} ref={swiperRef} slidesPerView={1}>
        {getSwiperSlide().map((item, index) => {
          return item;
        })}
      </Swiper>
    </>
  );
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const SingleItemOrder = ({ product }) => {
  const location = useLocation();
  const hostDomain = require("../../../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const showOptions = (options) => {
    const optionData = JSON.parse(options);
    var variant = [];
    var addon = [];
    if (optionData.length !== 0) {
      variant = optionData[0];
      addon = optionData[1];
      return (
        <div>
          {" "}
          {variant !== undefined
            ? Object.values(variant).map((variantItem) => (
                <div className="cart-product-variant">
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {variantItem.name}
                  </span>{" "}
                  : {variantItem.value} (+₹{variantItem.price})
                </div>
              ))
            : null}
          {addon !== undefined
            ? Object.values(addon).map((addonItem) => (
                <div className="cart-product-variant">
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {addonItem.name}
                  </span>
                  <div>
                    {Object.values(addonItem.value).map((addonValue) => (
                      <div className="cart-product-variant">
                        {addonValue.name} (+₹{addonValue.price})
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : null}
        </div>
      );
    } else return null;
  };

  const productRow = () => {
    return (
      <Row
        style={{
          paddingTop: "4vh",
          paddingBottom: "4vh",
          borderBottom: "1px solid #efefef",
        }}
      >
        <Col lg={5} xs={4} style={{ paddingRight: 0, textAlign: "center" }}>
          <Link to={`/${storeNameLink}/product/${product.product_id}`}>
            <div className="cart-item-image">
              <img src={product.image} alt="product" />
            </div>
          </Link>
        </Col>

        <Col lg={7} xs={8} style={{ paddingLeft: 0 }}>
          <div className="cart-item-details">
            <Link to={`/${storeNameLink}/product/${product.product_id}`}>
              <p className="cart-product-name">
                {product.name} X {product.quantity}
                {product.option_json !== "[]" ? (
                  <span className="cart-product-variant">
                    (₹{parseFloat(product.price) / 1})
                  </span>
                ) : null}
              </p>
            </Link>
            {showOptions(product.option_json)}
            <Row>
              <Col lg={6} xs={6}>
                <p className="cart-product-price">
                  <span className="cart-product-price-rs-sign">₹</span>
                  {parseFloat(product.total) / 1}
                </p>
              </Col>
              <Col lg={6} xs={6}>
                <div style={{ paddingTop: "2vh" }}></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <Row>
      <Col xs={12} lg={12}>
        {productRow()}
      </Col>
    </Row>
  );
};

export default SingleItemOrder;

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import React from "react";
import { Link } from "react-router-dom";
import { whichDevice } from "../../lib/device";
import { convertMoneyToText } from "../../lib/money";
import AddToCartButton from "../AddToCartButton";
import Button from "../Button/Button";
import styles from "./style.module.scss";
export const ProductCard = ({
  image,
  category,
  name,
  price,
  special,
  haveOptions,
  storeNameLink,
  product_id,
  addToCart,
  quantityInCart,
  minimumQuantity = 1,
  quickView = false,
}) => {
  const isMobile = whichDevice() === "mobile";
  const priceText = convertMoneyToText(price);
  const specialText = convertMoneyToText(special);

  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <Link
          to={{
            pathname: `/${storeNameLink}/product/${product_id}`,
          }}
        >
          <img src={image} className={styles.image} />
        </Link>
        <Button Icon={RemoveRedEyeIcon} sm className={styles.eyeButton} />
      </div>
      <div className={`pt-4 pb-2 ${styles.categoryName}`}>{category}</div>
      <div className={`pb-2  ${styles.productName}`}>{name}</div>
      {special && special !== "0" ? (
        <div className="d-flex pb-2">
          <div className={`${styles.priceStrike}`}>
            <strike>{priceText}</strike>
          </div>
          <div className={`pl-2 ${styles.special}`}>{specialText}</div>
        </div>
      ) : (
        <div className={`${styles.price} pb-2`}>{priceText}</div>
      )}

      <div className="d-flex align-items-center justify-content-between">
        {haveOptions ? (
          <Button
            text="Add to Cart"
            md
            link={`/${storeNameLink}/product/${product_id}/addtocart`}
          />
        ) : (
          <AddToCartButton
            quantityInCart={quantityInCart}
            minimumOrderQuantity={parseInt(minimumQuantity)}
            onClick={(quantity) => {
              addToCart(product_id, quantity);
            }}
            md
          />
        )}
        {!isMobile && haveOptions && (
          <div className={`${styles.customizable}`}>customizable</div>
        )}
      </div>
    </div>
  );
};

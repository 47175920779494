import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { cartContext } from "../../../../helper/cartContext";
import styles from "./styles.module.css";

const CartButton = ({ onClick = () => {}, className, storeNameLink }) => {
  const [cartValue] = useContext(cartContext);
  return (
    <Link
      to={{
        pathname: `/${storeNameLink}/cart`,
      }}
    >
      <div
        className={`d-flex justify-content-center align-items-center cursor-pointer ${className}`}
        onClick={onClick}
      >
        <div className="position-relative">
          <ShoppingCartOutlinedIcon className={styles.icon} />
          {cartValue > 0 && <div className={styles.cartItems}>{cartValue}</div>}
        </div>

        <div className={styles.textContainer}>
          <div className={styles.title}>Cart</div>
          <div className={styles.subTitle}>
            {cartValue > 0 ? "Buy Now" : "No Items"}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CartButton;

import React from "react";
import { Image } from "react-bootstrap";

const OverlayTextCard = ({ image, text, width }) => {
  return (
    <div className="overlay-text-card">
      <div className="thumbnail-container">
        <Image
          className="category-card-image"
          src={image}
          style={{ height: width }}
        />
        <div className="gray-overlay">
          <p className="category-text px-2">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default OverlayTextCard;

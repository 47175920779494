import { Drawer, LinearProgress, TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getWeightInText } from "../../../../helper/weight";
import Navbar from "../../components/Navbar";
import QuantityButton from "../../components/QuantityButton";

const Cart = ({
  cartData,
  storeNameLink,
  showOptions,
  addToCart,
  setBottomDrawer,
  applyCoupon,
  bottomDrawer,
  setCoupon,
  resultAPICartAdd,
  applyCouponDirect,
  emptyCart,
}) => {
  return (
    <div>
      <Navbar storeName={storeNameLink} />

      <Container>
        {cartData.status === "success" ? (
          <React.Fragment>
            {cartData.data.products.length ? (
              <React.Fragment>
                <div className="cart-page-heading">
                  <h5>
                    Your Cart
                    <span
                      class="cart-product-count"
                      style={{ float: "right", marginRight: "2vh" }}
                    >
                      {cartData.data.products.length}
                    </span>
                  </h5>
                </div>

                <Row>
                  <Col xs={12} lg={8}>
                    {Object.values(cartData.data.products).map((cartItem) => (
                      <Row
                        key={cartItem.cart_id}
                        style={{
                          paddingTop: "4vh",
                          paddingBottom: "4vh",
                          borderBottom: "1px solid #efefef",
                        }}
                      >
                        <Col
                          lg={4}
                          xs={4}
                          style={{
                            paddingRight: 0,
                            textAlign: "center",
                            paddingLeft: 0,
                          }}
                        >
                          {" "}
                          <Link
                            to={`/${storeNameLink}/product/${cartItem.product_id}`}
                          >
                            <div className="cart-item-image">
                              <img src={cartItem.image} alt="product" />
                            </div>
                          </Link>
                        </Col>

                        <Col lg={8} xs={8} style={{ paddingLeft: 0 }}>
                          <div className="cart-item-details">
                            <Link
                              to={`/${storeNameLink}/product/${cartItem.product_id}`}
                            >
                              <p className="cart-product-name">
                                {cartItem.name}{" "}
                                <span className="cart-product-variant">
                                  (₹{cartItem.price})
                                </span>
                              </p>
                            </Link>
                            {showOptions(cartItem.option_json)}
                            <Row>
                              <Col lg={6} xs={6}>
                                <p className="cart-product-price">
                                  <span className="cart-product-price-rs-sign">
                                    ₹
                                  </span>
                                  {cartItem.product_total}
                                </p>
                                {cartItem?.tax > 0 ? (
                                  <div className="cart-product-variant">
                                    GST({cartItem?.gst}%): ₹{cartItem?.tax}
                                  </div>
                                ) : null}
                              </Col>
                              <Col lg={6} xs={6}>
                                <div style={{ paddingTop: "2vh" }}>
                                  <QuantityButton
                                    quantityInCart={parseInt(cartItem.quantity)}
                                    minimumOrderQuantity={
                                      cartItem.minimum_quantity
                                    }
                                    onChangeQuantity={(quantity) => {
                                      addToCart(
                                        cartItem.product_id,
                                        quantity,
                                        cartItem.variant,
                                        cartItem.addon
                                      );
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="cart-total">
                      <div className="cart-coupon-area-mobile">
                        <Row>
                          <Col xs="2">
                            <div className="cart-coupon-icon-mobile">%</div>
                          </Col>
                          <Col xs="7" style={{ padding: 0 }}>
                            {cartData.data.total.coupon.coupon_name === "" ? (
                              <div
                                className="cart-coupon-text-mobile"
                                onClick={() => {
                                  setBottomDrawer(true);
                                }}
                              >
                                APPLY COUPON
                              </div>
                            ) : (
                              <div>
                                <div
                                  className="cart-coupon-text-mobile"
                                  style={{ marginTop: "0.2vh" }}
                                >
                                  {cartData.data.total.coupon.coupon_code.toUpperCase()}
                                </div>
                                <div>
                                  {cartData.data.total.coupon.coupon_name.toUpperCase()}
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col
                            xs="3"
                            onClick={() => {
                              if (cartData.data.total.coupon.coupon_name !== "")
                                applyCoupon("remove");
                            }}
                          >
                            {cartData.data.total.coupon.coupon_name === "" ? (
                              <ChevronRightIcon className="cart-coupon-chevron-right-mobile" />
                            ) : (
                              <CancelIcon className="cart-coupon-chevron-right-mobile" />
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="cart-details">
                        <table className="table">
                          {cartData.delivery_fee_by_order_weight_flag ===
                          "1" ? (
                            <tr>
                              <td>Order Weight:</td>
                              <td style={{ textAlign: "right" }}>
                                {getWeightInText(cartData.total_weight)}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Sub-Total:</td>
                            <td style={{ textAlign: "right" }}>
                              ₹ {cartData.data.total.sub_total}
                            </td>
                          </tr>

                          {cartData.data.total.coupon.coupon_name !== "" ? (
                            <tr>
                              <td>Coupon:</td>
                              <td style={{ textAlign: "right" }}>
                                <div>
                                  <div>
                                    <div
                                      style={{
                                        color: "#009688",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      - ₹
                                      {cartData.data.total.coupon.coupon_value}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Delivery</td>
                            <td style={{ textAlign: "right" }}>
                              ₹ {cartData.data.total.deliveryfee}
                            </td>
                          </tr>
                          {cartData?.data?.total?.tax > 0 ? (
                            <tr>
                              <td>Taxes</td>
                              <td style={{ textAlign: "right" }}>
                                ₹ {cartData.data.total.tax}
                              </td>
                            </tr>
                          ) : null}
                          <tr className="total">
                            <td>Grand Total:</td>
                            <td style={{ textAlign: "right" }}>
                              {" "}
                              ₹ {cartData.data.total.total}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="cart-checkout-button">
                        <Row>
                          <Col
                            lg={0}
                            xs={5}
                            className="cart-mobile-final-total-container"
                          >
                            <div style={{ fontWeight: "bold" }}>TO PAY</div>
                            <div className="cart-mobile-final-total">
                              ₹{cartData.data.total.total}
                            </div>
                          </Col>
                          <Col lg={12} xs={7}>
                            {cartData.data.store_status === "1" ? (
                              <Link to={`/${storeNameLink}/login`}>
                                <Button className="cart-continue-button">
                                  Checkout
                                </Button>
                              </Link>
                            ) : (
                              <Button className="cart-continue-button btn-danger">
                                Store Offline
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="margin-bottom-150px"></div>
                    </div>
                    <Drawer
                      anchor={"bottom"}
                      open={bottomDrawer}
                      onClose={() => {
                        setBottomDrawer(false);
                      }}
                    >
                      <div className="cart-input-coupon-container">
                        <Row>
                          <Col xs={8} lg={10}>
                            <TextField
                              className="form-control"
                              onChange={(e) => setCoupon(e.target.value)}
                              label="Enter Coupon Code"
                              style={{ fontWeight: "bold" }}
                              size="medium"
                              row="1"
                            />
                          </Col>
                          <Col xs={4} lg={2}>
                            <Button
                              style={{
                                height: "40%",
                                width: "100%",
                                fontSize: "2vh",
                                marginTop: "2vh",
                              }}
                              onClick={() => {
                                applyCoupon("add");
                              }}
                            >
                              APPLY
                            </Button>
                          </Col>
                        </Row>

                        <div style={{ fontSize: "2vh", color: "#c71d10" }}>
                          {resultAPICartAdd.status === "error"
                            ? resultAPICartAdd.message_code === "COUPON"
                              ? resultAPICartAdd.message
                              : null
                            : null}
                        </div>
                        {Object.values(cartData.data.coupon).map((item) => (
                          <div
                            className={"col-sm-4"}
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              boxShadow: "0px 0px 10px #ccc",
                              marginTop: "20px",
                              padding: "10px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.3em",
                                  background: "#009688",
                                  padding: "5px",
                                  color: " white",
                                  borderRadius: "5px",
                                  display: "inline-block",
                                }}
                              >
                                {item.code}
                              </div>
                              <Button
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.3em",
                                  padding: "5px",
                                  color: "#007bff",
                                  borderRadius: "5px",
                                  display: "inline",
                                  float: "right",
                                  background: "white",
                                  border: 0,
                                }}
                                onClick={() => {
                                  applyCouponDirect("add", item.code);
                                }}
                              >
                                APPLY
                              </Button>
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.4em",
                                paddingTop: "5px",
                              }}
                            >
                              {item.name}
                            </div>
                            <div>
                              Apply this coupon and get {item.value}
                              {item.type === "percent" ? "%" : "₹"} off upto ₹
                              {item.max_discount} instantly.
                              <div>
                                Applicable on order above ₹{item.min_amount}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="margin-bottom-150px"></div>
                    </Drawer>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <div>
                <div className="cart-page-heading">
                  <h5>
                    Your Cart{" "}
                    <span
                      class="cart-product-count"
                      style={{ float: "right", marginRight: "2vh" }}
                    >
                      {cartData.data.products.length}
                    </span>
                  </h5>
                </div>
                <div class="container-cart-empty">
                  <div class="cart">
                    <img src={emptyCart} alt="product" />
                    <h5 className="cart-empty-heading">Your cart is empty</h5>
                    <h5 className="cart-empty-detail">
                      Looks like you haven't added
                      <br />
                      anything in your cart yet
                    </h5>
                    <Link to={`/${storeNameLink}/store`}>
                      <Button className="cart-empty-back-button">
                        Continue Shopping
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <LinearProgress color="secondary" />
        )}
      </Container>
    </div>
  );
};
export default Cart;

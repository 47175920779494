import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const TaxDetails = ({ invoiceData }) => {
  return invoiceData?.totalTax > 0 ? (
    <View style={styles.tableRowSubtotal}>
      <Text style={styles.tableCell}></Text>

      <View style={[styles.tableCell]}>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCellTextCenter]}> TAX BREAKUP</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCellTextCenter]}> TAXABLE VALUE</Text>
          {invoiceData?.isIgst === "1" ? (
            <View style={[styles.tableCellTextCenter]}>
              <View style={styles.tableRowSubtotal}>
                <Text style={[styles.tableCellTextCenter]}> IGST</Text>
              </View>
              <View style={styles.tableRowSubtotal}>
                <Text style={[styles.tableCellTextCenter]}> RATE</Text>
                <Text style={[styles.tableCellTextCenter]}> TAX</Text>
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.tableCellTextCenter]}>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}> CGST</Text>
                </View>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}> RATE</Text>
                  <Text style={[styles.tableCellTextCenter]}> TAX</Text>
                </View>
              </View>
              <View style={[styles.tableCellTextCenter]}>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}> SGST</Text>
                </View>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}> RATE</Text>
                  <Text style={[styles.tableCellTextCenter]}> TAX</Text>
                </View>
              </View>
            </>
          )}
          <Text style={[styles.tableCellTextCenter]}> TOTAL TAX</Text>
        </View>
        {invoiceData?.taxPerSlab?.map((item, index) => (
          <View style={styles.tableRow} key={`taxdetailsrow/tax${index}`}>
            <Text style={[styles.tableCellTextCenter]}> {item.value}</Text>
            {invoiceData?.isIgst === "1" ? (
              <View style={[styles.tableCellTextCenter]}>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}>{item.rate}%</Text>
                  <Text style={[styles.tableCellTextCenter]}>
                    {item.amount}
                  </Text>
                </View>
              </View>
            ) : (
              <>
                <View style={[styles.tableCellTextCenter]}>
                  <View style={styles.tableRowSubtotal}>
                    <Text style={[styles.tableCellTextCenter]}>
                      {item.rate / 2}%
                    </Text>
                    <Text style={[styles.tableCellTextCenter]}>
                      {item.amount / 2}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableCellTextCenter]}>
                  <View style={styles.tableRowSubtotal}>
                    <Text style={[styles.tableCellTextCenter]}>
                      {item.rate / 2}%
                    </Text>
                    <Text style={[styles.tableCellTextCenter]}>
                      {item.amount / 2}
                    </Text>
                  </View>
                </View>
              </>
            )}
            <Text style={[styles.tableCellTextCenter]}> {item.amount}</Text>
          </View>
        ))}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCellTextCenter, styles.bold]}>
            {invoiceData.totalTaxableValue}
          </Text>
          {invoiceData?.isIgst === "1" ? (
            <View style={[styles.tableCellTextCenter]}>
              <View style={styles.tableRowSubtotal}>
                <Text style={[styles.tableCellTextCenter]}></Text>
                <Text style={[styles.tableCellTextCenter, styles.bold]}>
                  {invoiceData.totalTax}
                </Text>
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.tableCellTextCenter]}>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}></Text>
                  <Text style={[styles.tableCellTextCenter, styles.bold]}>
                    {invoiceData.totalTax / 2}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCellTextCenter]}>
                <View style={styles.tableRowSubtotal}>
                  <Text style={[styles.tableCellTextCenter]}></Text>
                  <Text style={[styles.tableCellTextCenter, styles.bold]}>
                    {invoiceData.totalTax / 2}
                  </Text>
                </View>
              </View>
            </>
          )}
          <Text style={[styles.tableCellTextCenter, styles.bold]}>
            {invoiceData.totalTax}
          </Text>
        </View>
      </View>
    </View>
  ) : (
    <></>
  );
};

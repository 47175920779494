import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProductImageBackdrop } from "./ProductImageBackdrop";
import QuantityButton from "./QuantityButton";

const styles = {
  productname: {
    fontWeight: "bolder",
    fontSize: "1.4rem",
    marginTop: "0.5rem",
    color: "#222",
  },
  special: {
    fontWeight: "bold",
    fontSize: "1.8rem",
    marginTop: "0.2rem",
    color: "#009688",
  },
  normalPrice: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginTop: "0.2rem",
    color: "#222",
  },
  price: {
    fontSize: "1.5rem",
    color: "#999",
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  customizable: {
    fontSize: "1.8vh",
    color: "#673AB7",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "10px",
    bottom: "0",
    position: " absolute",
  },
};

const DetailsCard = ({
  image,
  text,
  price,
  special,
  haveOptions,
  storeName,
  productID,
  quantityInCart,
  minimumOrderQuantity = 1,
  addToCart,
}) => {
  const discount = parseInt((1 - special / price) * 100);
  return (
    <div className="details-card">
      <Row>
        <Col xs={4} sm={12}>
          <Link
            to={{
              pathname: `/${storeName}/product/${productID}`,
            }}
          >
            <div className="product-thumbnail-image-container">
              <Image className="product-thumbnail-image" src={image} />
              <ProductImageBackdrop src={image} />
            </div>
          </Link>
        </Col>

        <Col xs={8} sm={12} className="product-details-card">
          <Row>
            <Col xs={12} sm={12}>
              <Link
                to={{
                  pathname: `/${storeName}/product/${productID}`,
                }}
              >
                {special > 0 ? (
                  <span class="discountCircleFront">{discount}% OFF</span>
                ) : null}
                <p className="product-name" style={styles.productname}>
                  {text}
                </p>
                {special > 0 ? (
                  <p style={styles.special}>
                    ₹{special / 1}{" "}
                    <span style={styles.price}>₹{price / 1}</span>
                  </p>
                ) : (
                  <p style={styles.normalPrice}>₹{price / 1}</p>
                )}
              </Link>
            </Col>
            <Col xs={12} sm={12}></Col>
            <Col xs={12} sm={12}>
              {haveOptions === "1" ? (
                <Link
                  to={{
                    pathname: `/${storeName}/product/${productID}/addtocart`,
                  }}
                >
                  <QuantityButton
                    quantityInCart={0}
                    onChangeQuantity={(quantity) => {
                      //addToCart(quantity);
                    }}
                  />
                  <div style={styles.customizable}>customizable</div>
                </Link>
              ) : (
                <QuantityButton
                  quantityInCart={parseInt(quantityInCart)}
                  minimumOrderQuantity={parseInt(minimumOrderQuantity)}
                  onChangeQuantity={(quantity) => {
                    addToCart(quantity);
                  }}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <hr />
    </div>
  );
};

export default DetailsCard;

import { Text } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const OptionDetails = ({ options }) => {
  const Data = ({ value, price }) => {
    const dataPrice = price;
    return (
      <Text style={styles.productOption}>
        {value} +{dataPrice}
      </Text>
    );
  };
  if (!options.length > 0) {
    return <></>;
  }
  const addon = options[1];
  const variants = options[0];
  return (
    <>
      {variants?.map((item, index) => {
        return (
          <div key={`optionlistvariant/option${index}`}>
            <Text style={styles.productOptionTitle}>{item.name}</Text>
            <Data value={item.value} price={item.price} />
          </div>
        );
      })}
      {addon?.map((item, index) => {
        return (
          <div key={`optionlistaddon/option${index}`}>
            <Text style={styles.productOptionTitle}>{item.name}</Text>
            <>
              {item.value?.map((item1, index) => {
                return (
                  <Data value={item1.name} price={item1.price} key={index} />
                );
              })}
            </>
          </div>
        );
      })}
    </>
  );
};

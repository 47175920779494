export const youtubeParser = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7]?.length === 11 ? match[7] : false;
};
export const getYoutubeVideoType = (url) => {
  const urlParts = url?.split("/");
  if (urlParts.length > 1) {
    return urlParts[3];
  } else return false;
};

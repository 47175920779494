import React from "react";
import { BlankSpace } from "../../components/BlankSpace/BlankSpace";
const PageNotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center max-width pt-5">
      <BlankSpace text="404 Page Not Found" className="mt-5" rounded />
    </div>
  );
};
export default PageNotFound;

/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@material-ui/core";
import ls from "local-storage";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "swiper/swiper.scss";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";

const Information = () => {
  const swiperRef = useRef(null);
  const [nearbybazarAPI, resultAPI] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [swiperWidth, setSwiperWidth] = useState(0);

  const [cartValue, setCartValue] = useContext(cartContext);

  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const location = useLocation();

  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  var typeoflink = window.location.pathname.split("/")[2];
  var heading = "";
  var call = "";
  if (typeoflink === "about-us") {
    heading = "About Us";
    call = "about_us";
  } else if (typeoflink === "terms-and-conditions") {
    heading = "Terms and Conditions";
    call = "tnc";
  } else if (typeoflink === "privacy-policy") {
    heading = "Privacy Policy";
    call = "privacy_policy";
  } else if (typeoflink === "return-policy") {
    heading = "Return and Refund Policy";
    call = "return_policy";
  } else if (typeoflink === "shipping-policy") {
    heading = "Shipping Policy";
    call = "shipping_policy";
  }

  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  var sliderViewCount = 3.3;

  if (window.innerWidth > 600) sliderViewCount = 7;

  const fetchCategories = async () => {
    const data = {
      link: storeName,
    };
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPI(data, "/front/front/");
  };

  const addToCart = async (pid, quantity) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: "{}",
      addon: "{}",
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/addtocart/");
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  //ls.clear();

  if (swiperRef.current && swiperWidth !== swiperRef.current.offsetWidth - 10) {
    console.log("nhjb");
    setSwiperWidth(swiperRef.current.offsetWidth - 10);
    ls.set("swiperWidth", swiperRef.current.offsetWidth - 10);
  } else if (swiperWidth === 0 && ls.get("swiperWidth")) {
    setSwiperWidth(ls.get("swiperWidth"));
  }
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow:
      resultAPI.status === "success"
        ? resultAPI.data.all_category.length < 7
          ? 7.3
          : 7.3
        : 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
    ],
  };
  if (resultAPI.status === "success") {
    // ls.set("storeData", resultAPI);
    if (cartValue === 0 && resultAPI.data.products_in_cart !== "0")
      setCartValue(parseInt(resultAPI.data.products_in_cart));
  }
  if (resultAPICart.status === "success") {
    if (cartValue !== parseInt(resultAPICart.data.cart_items))
      setCartValue(parseInt(resultAPICart.data.cart_items));
  }
  if (resultAPI.status === "success")
    return (
      <ThemeChooser
        props={{
          resultAPI: resultAPI,
          heading: heading,
          call: call,
          storeNameLink: storeNameLink,
        }}
        name="information"
      />
    );
  else return <LinearProgress color="secondary" />;
};

export default Information;

import { Facebook, Instagram, Star } from "@material-ui/icons";
import ls from "local-storage";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PoweredBy } from "../../../../globalComponents/PoweredBy/PoweredBy";
import Whatsapp from "../../../../resources/whatsapp.svg";
import styles from "./style.module.scss";

export const Footer = ({ className, storeNameLink }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    const localData = ls.get("storeData");
    if (localData) setData(ls.get("storeData").data);
    else {
      //hack to recheck in 2 secs, header will set it in the mean time
      setTimeout(() => {
        const localData = ls.get("storeData");
        if (localData) setData(ls.get("storeData").data);
      }, 1500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const StoreDetails = () => {
    return (
      <div className={styles.StoreDetailsContainer}>
        {data.whatsapp_support_status === "1" ? (
          <div className={`${styles.zIndex15} btn-fab`}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://wa.me/+91${data.seller_phone}?text=Hello, I just checked your website of ${data.store_name}. I need some help!`}
            >
              <Image src={Whatsapp} className="img-fluid" alt="wa-icon" />
            </a>
          </div>
        ) : null}
        <div className={styles.storeName}>{data.store_name}</div>
        <div className={styles.storeAddress}>{data.store_address}</div>
        <div className={styles.storeAddress}>
          {data.store_city}, {data.store_state}
        </div>
        <div className={styles.review}>
          <div>
            {data.rating}
            <Star className={styles.star} />
          </div>
          <div>
            {data.review_count} Review{data.review_count > 1 && "s"}
          </div>
        </div>
      </div>
    );
  };
  const ImportantLinks = () => {
    return (
      <>
        <div className={styles.impLink}>
          <Link to={{ pathname: `/${storeNameLink}/about-us` }}>About Us</Link>
        </div>
        <div className={styles.impLink}>
          {" "}
          <Link to={{ pathname: `/${storeNameLink}/terms-and-conditions` }}>
            Terms & Conditions
          </Link>
        </div>
        <div className={styles.impLink}>
          {" "}
          <Link to={{ pathname: `/${storeNameLink}/return-policy` }}>
            Return & Refund Policy
          </Link>
        </div>
        <div className={styles.impLink}>
          <Link to={{ pathname: `/${storeNameLink}/privacy-policy` }}>
            Privacy Policy
          </Link>
        </div>
        <div className={styles.impLink}>
          <Link to={{ pathname: `/${storeNameLink}/shipping-policy` }}>
            Shipping Policy
          </Link>
        </div>
      </>
    );
  };
  if (data?.store_image)
    return (
      <>
        {" "}
        {data.is_premium === 0 ? (
          <PoweredBy className="max-width mt-5 pt-5" />
        ) : null}
        <div className={`${styles.root} ${className}`}>
          <div className={styles.footerTop}>
            <a href={data.facebook}>
              <Facebook className={styles.iconFacebook} />
            </a>
            <a href={data.insta}>
              <Instagram className={styles.iconInsta} />
            </a>
          </div>
          <div className="max-width text-white pt-5">
            <div className="row mt-5">
              <div className="col-sm-4">
                <StoreDetails />
              </div>
              <div className="col-sm-4"></div>
              <div className="col-sm-4">
                <div className={styles.ImportantLinksContainer}>
                  <div className={styles.title}>Information </div>
                  <ImportantLinks />
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className={styles.copywrite}>
                &copy; 2023, {data.store_name} - All Right Reserved
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else return <></>;
};

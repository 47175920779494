import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import MainSite from "./screens/website/MainSite";
import * as serviceWorker from "./serviceWorker";

const hostDomain = require("./resources/hostdomain.json");

ReactDOM.render(
  <React.StrictMode>
    {window.location.pathname.split("/")[1] === "" &&
    window.location.hostname === hostDomain.domain ? (
      <MainSite />
    ) : (
      <App />
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import SingleItemOrder from "./SingleItemOrder";

const SingleOrder = ({ order, reorder, onPressReorder, reviewSectionOpen }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Row className="single-order-container">
        <Col xs={12} lg={12}>
          <p className="order-store-name">{order.biz_shop_name}</p>
        </Col>
        <Col xs={8} lg={10}>
          <div className="order-lhs">
            <div className="order-item-details">
              <div className="single-order-id">Order #{order.order_id}</div>
              <div className="single-order-date">{order.date_added}</div>

              <div className="single-order-item-n-total">
                <span>{order.products.length} Item</span>{" "}
                <span className="price">₹ {parseFloat(order.total) / 1}</span>
              </div>

              <div className="single-order-products">
                {order.product_string}
              </div>
            </div>
          </div>
        </Col>

        <Col xs={4} lg={2}>
          <div className="single-order-status">
            {order.order_status_name}{" "}
            <div className="single-order-status-circle order-status-1"></div>
          </div>
          <div className="order-rhs">
            <button
              className="btn btn-success single-order-button-details"
              onClick={() => {
                reorder(order.order_id, order.customer_id);
                onPressReorder();
              }}
              aria-controls="details-collapse"
              aria-expanded={open}
              style={{ marginBottom: "2vh" }}
            >
              Re-Order
            </button>

            <button
              className="btn btn-primary single-order-button-details"
              onClick={() => setOpen(!open)}
              aria-controls="details-collapse"
              aria-expanded={open}
              style={{ marginBottom: "2vh" }}
            >
              Details
            </button>
            <button
              className="btn btn-danger single-order-button-details"
              onClick={() => {
                reviewSectionOpen(order.order_id, order.review, order.rating);
              }}
              aria-controls="details-collapse"
              aria-expanded={open}
            >
              Review
            </button>
          </div>
        </Col>
        <Collapse in={open}>
          <Row id="details-collapse" style={{ width: "100%" }}>
            <Col xs={12} lg={8}>
              {order.products.length !== 0
                ? Object.values(order.products).map((productItem) => (
                    <SingleItemOrder
                      key={productItem.product_id}
                      product={productItem}
                    />
                  ))
                : null}
            </Col>
            <Col xs={12} lg={4}>
              <Row>
                <Col xs={12} lg={12}>
                  <div className="cart-details">
                    <table className="table">
                      {order.totals.length !== 0
                        ? Object.values(order.totals).map((totalItem) => (
                            <tr>
                              <td>{totalItem.title}</td>
                              <td style={{ textAlign: "right" }}>
                                ₹ {parseFloat(totalItem.value) / 1}
                              </td>
                            </tr>
                          ))
                        : null}
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <div
                    className="cart-details"
                    style={{ background: "#fff", marginTop: 0 }}
                  >
                    <div style={{ fontWeight: "bold", fontSize: "1.8vh" }}>
                      Delivery Details
                    </div>
                    <table className="table">
                      <tr>
                        <td>Name</td>
                        <td style={{ textAlign: "right" }}>
                          {order.firstname + " " + order.lastname}
                        </td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td style={{ textAlign: "right" }}>
                          {order.telephone}
                        </td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td style={{ textAlign: "right" }}>
                          {order.shipping_address_1}, {order.shipping_city} -{" "}
                          {order.shipping_postcode}, {order.shipping_zone},{" "}
                          {order.shipping_country}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="margin-bottom-150px"></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Collapse>
      </Row>
    </React.Fragment>
  );
};

export default SingleOrder;

/* eslint-disable jsx-a11y/accessible-emoji */
import { LinearProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";
import emptyCart from "../resources/empty_cart.jpg";

const ConfirmOrder = ({ ...props }) => {
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [nearbybazarAPIOrder, resultAPIOrder] = APIHook();
  const [nearbybazarAPICartAdd, resultAPICartAdd] = APIHook();
  const [nearbybazarAPILoginCheck, resultAPILoginCheck] = APIHook();
  const [nearbybazarAPIDelivery, resultAPIDelivery] = APIHook();
  const [commentValue, setCommentValue] = useState("");
  const [cartValue, setCartValue] = useContext(cartContext);
  const [paymentMethod, setPaymentMethod] = useState("cod");
  const [orderType, setOrderType] = useState("");

  const [cartData, setCartData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const getOrders = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPILoginCheck(data, "/front/getorders");
  };
  if (resultAPILoginCheck.status === "error") {
    if (resultAPILoginCheck.message_code === "LOGIN")
      history.push({
        pathname: "/" + storeNameLink + "/login",
      });
  }
  const setDeliveryFee = async (type) => {
    const data = {
      link: storeName,
      type: type,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    await nearbybazarAPICartAdd(data, "/front/deliveryfee");
  };
  const getCart = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/getcart");
  };
  useEffect(() => {
    getCart();
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const placeOrder = async () => {
    if (paymentMethod !== "cod") {
      history.push({
        pathname: "/" + storeNameLink + "/requestPayment",
        state: {
          orderType: orderType,
          comment: commentValue,
        },
      });
    } else {
      const data = {
        link: storeName,
        payment_method: paymentMethod,
        order_type: orderType,
        comment: commentValue,
      };

      nearbybazarAPIOrder(data, "/front/createorder");
    }
  };
  if (resultAPIOrder.status === "success") {
    setCartValue(0);
    history.push({
      pathname: "/" + storeNameLink + "/success",
      state: {
        orderid: resultAPIOrder.order_id,
      },
    });
  }

  const addToCart = async (pid, quantity, variant, addon) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: variant,
      addon: addon,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICartAdd(data, "/front/addtocart/");
  };

  const showOptions = (options) => {
    const optionData = JSON.parse(options);
    var variant = [];
    var addon = [];
    if (optionData.length !== 0) {
      variant = optionData[0];
      addon = optionData[1];
      return (
        <div>
          {" "}
          {variant !== undefined
            ? Object.values(variant).map((variantItem) => (
                <div className="cart-product-variant">
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {variantItem.name}
                  </span>{" "}
                  : {variantItem.value} (+₹{variantItem.price})
                </div>
              ))
            : null}
          {addon !== undefined
            ? Object.values(addon).map((addonItem) => (
                <div className="cart-product-variant">
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {addonItem.name}
                  </span>
                  <div>
                    {Object.values(addonItem.value).map((addonValue) => (
                      <div className="cart-product-variant">
                        {addonValue.name} (+₹{addonValue.price})
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : null}
        </div>
      );
    } else return null;
  };

  const handleAlignment = (event, newAlignment) => {
    console.log(newAlignment);
    if (newAlignment === "online") {
      //fixing bug found by Rishi Tandon. Taping on cash on delivery leads to switch to online payment even after online payment is switched off.
      if (
        resultAPICart.online_payment_status === "1" &&
        resultAPICart.data.is_premium === 1
      ) {
        setPaymentMethod(newAlignment);
      }
    } else {
      if (newAlignment != null) setPaymentMethod(newAlignment);
    }
  };
  const handleType = async (event, newAlignment) => {
    if (newAlignment != null) setOrderType(newAlignment);
    console.log(newAlignment);
    if (newAlignment === "pickup") {
      await setDeliveryFee("set");
      //getCart();
    } else if (newAlignment === "delivery") {
      await setDeliveryFee("unset");
      //getCart();
    }
  };
  if (resultAPICart.status === "success" && cartData.length === 0) {
    setCartData(resultAPICart);
    console.log("cart data updated with initial data");
  } else if (
    resultAPICartAdd.status === "success" &&
    cartData.length !== 0 &&
    JSON.stringify(cartData) !== JSON.stringify(resultAPICartAdd)
  ) {
    setCartData(resultAPICartAdd);
    console.log("cart data updated");
    setCartValue(parseInt(resultAPICartAdd.data.cart_items));
  }
  //fixing cod automatically bug
  if (cartData.status === "success") {
    if (cartData.cod_status === "0" && paymentMethod === "cod")
      setPaymentMethod("online");
  }
  //fixing pickup automatically bug
  if (cartData.status === "success") {
    if (cartData.store_pickup === "0" && orderType === "pickup") {
      setOrderType("delivery");
      setDeliveryFee("unset");
    } else if (cartData.data.order_type === "pickup" && orderType === "")
      setOrderType("pickup");

    if (cartData.data.order_type === "delivery" && orderType === "")
      setOrderType("delivery");
  }
  const handleBackEvent = () => {
    history.go(-2);
  };
  useEffect(() => {
    window.addEventListener("popstate", handleBackEvent);
    return () => window.removeEventListener("popstate", handleBackEvent);
  });

  return cartData.status === "success" ? (
    <ThemeChooser
      name="confirm-order"
      props={{
        cartData: cartData,
        storeNameLink: storeNameLink,
        showOptions: showOptions,
        addToCart: addToCart,
        resultAPICart: resultAPICart,
        history: history,
        orderType: orderType,
        handleType: handleType,
        paymentMethod: paymentMethod,
        handleAlignment: handleAlignment,
        commentValue: commentValue,
        setCommentValue: setCommentValue,
        placeOrder: placeOrder,
        emptyCart: emptyCart,
      }}
    />
  ) : (
    <div>
      <LinearProgress color="secondary" />
      <LoaderComponent storeName={storeName} />
    </div>
  );
};

export default ConfirmOrder;

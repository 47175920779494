import React from "react";
import styles from "./style.module.css";

const Information = ({ resultAPI, call, heading }) => {
  return (
    <div className="max-width py-5">
      <div className={styles.heading}>{heading}</div>
      <div className={styles.body}>{resultAPI.data[call]}</div>
    </div>
  );
};
export default Information;

import { Drawer, LinearProgress } from "@material-ui/core";
import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined"; //4 staR
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SentimentDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentDissatisfiedOutlined"; //2 staR
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined"; //3 staR
import SentimentVeryDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentVeryDissatisfiedOutlined"; //1 staR
import SentimentVerySatisfiedOutlinedIcon from "@material-ui/icons/SentimentVerySatisfiedOutlined"; //5 staR
import { Rating } from "@material-ui/lab";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SingleOrder from "../../components/SingleOrder";
import styles from "./styles.module.css";
const Account = ({
  resultAPICart,
  bottomDrawer,
  setBottomDrawer,
  ratingValue,
  setRatingValue,
  TextField,
  setReviewText,
  reviewText,
  submitReview,
  logout,
  reOrder,
  setReorderClick,
  openReviewSection,
  emptyCart,
  storeNameLink,
}) => {
  return (
    <div>
      {resultAPICart.status === "success" ? (
        <Container>
          <div className="cart-page-heading">
            <Drawer
              anchor={"bottom"}
              open={bottomDrawer}
              onClose={() => {
                setBottomDrawer(false);
              }}
            >
              {/* 5 Star */}
              {ratingValue > 4 ? (
                <div>
                  <div
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Thanks
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <SentimentVerySatisfiedOutlinedIcon
                      style={{ fontSize: "16rem", color: "#21a108" }}
                    />
                    <div style={{ fontSize: "1.7rem", color: "#21a108" }}>
                      Yeah!
                      <br />
                      We are really very thankful for this review.
                    </div>
                  </div>
                </div>
              ) : ratingValue > 3 ? (
                <div>
                  <div
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Thanks
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <EmojiEmotionsOutlinedIcon
                      style={{ fontSize: "16rem", color: "#eba834" }}
                    />
                    <div style={{ fontSize: "1.7rem", color: "#eba834" }}>
                      Yeah!
                      <br />
                      We are really very thankful for this review.
                    </div>
                  </div>
                </div>
              ) : ratingValue > 2 ? (
                <div>
                  <div
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Thanks
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <SentimentSatisfiedOutlinedIcon
                      style={{ fontSize: "16rem", color: "#eba834" }}
                    />
                    <div style={{ fontSize: "1.7rem", color: "#eba834" }}>
                      Yeah!
                      <br />
                      We are really very thankful for this review.
                    </div>
                  </div>
                </div>
              ) : ratingValue > 1 ? (
                <div>
                  <div
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Thanks
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <SentimentDissatisfiedOutlinedIcon
                      style={{ fontSize: "16rem", color: "#eb4c34" }}
                    />
                    <div style={{ fontSize: "1.7rem", color: "#eb4c34" }}>
                      That's Really Bad!
                      <br />
                      We will try to fix the issue.
                    </div>
                  </div>
                </div>
              ) : ratingValue > 0 ? (
                <div>
                  <div
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Thanks
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <SentimentVeryDissatisfiedOutlinedIcon
                      style={{ fontSize: "16rem", color: "#eb4c34" }}
                    />
                    <div style={{ fontSize: "1.7rem", color: "#eb4c34" }}>
                      That's Really Bad!
                      <br />
                      We will try to fix the issue.
                    </div>
                  </div>
                </div>
              ) : null}
              {/* 1 Star */}

              <div>
                <div
                  style={{
                    fontSize: "2rem",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "1rem",
                  }}
                >
                  Review for your Order
                </div>

                <div style={{ textAlign: "center" }}>
                  {" "}
                  <Rating
                    name="hover-feedback"
                    value={ratingValue}
                    size="large"
                    style={{ fontSize: "4rem" }}
                    onChange={(event, newValue) => {
                      if (newValue != null) setRatingValue(newValue);
                    }}
                  />
                </div>
                <div className="cart-input-coupon-container">
                  <TextField
                    className="form-control"
                    onChange={(e) => {
                      setReviewText(e.target.value);
                    }}
                    label="Your Review"
                    value={reviewText}
                    type="text"
                    style={{ fontWeight: "bold" }}
                    size="medium"
                  />
                  <Button
                    onClick={() => {
                      setBottomDrawer(false);
                      submitReview();
                    }}
                    style={{ fontSize: "2rem", width: "100%" }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Drawer>
            <Row>
              <Col xs={8} lg={10}>
                <div className="account-page-heading"> ACCOUNT</div>
              </Col>
              <Col xs={4} lg={2}>
                {" "}
                <button
                  onClick={() => {
                    logout();
                  }}
                  className="btn btn-danger single-order-button-details account-page-logout-button"
                >
                  <div className="account-page-logout-heading">LOGOUT</div>{" "}
                  <PowerSettingsNewIcon />
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12}>
                <div className={`${styles.name} account-page-name`}>
                  {" "}
                  Welcome, {resultAPICart.customer_name}
                </div>
                <div className={`${styles.name} account-page-name`}>
                  {resultAPICart.customer_phone}
                </div>
              </Col>
            </Row>
          </div>
          {resultAPICart.data.length !== 0 ? (
            Object.values(resultAPICart.data).map((orderItem) => (
              <SingleOrder
                key={orderItem.order_id}
                order={orderItem}
                reorder={(order_id, customer_id) => {
                  reOrder(order_id, customer_id);
                }}
                onPressReorder={() => {
                  setReorderClick(true);
                }}
                reviewSectionOpen={(order_id, review, rating) => {
                  openReviewSection(order_id, review, rating);
                }}
              />
            ))
          ) : (
            <div class="container-cart-empty">
              <div class="cart">
                <img src={emptyCart} alt="product" />
                <h5 className="cart-empty-heading">No Previous Orders</h5>
                <h5 className="cart-empty-detail">
                  Looks like you haven't ordered
                  <br />
                  anything from our store yet
                </h5>
                <Link to={`/${storeNameLink}/store`}>
                  <Button className="cart-empty-back-button">
                    Continue Shopping
                  </Button>
                </Link>
              </div>
            </div>
          )}
          <div className="margin-bottom-150px"></div>
        </Container>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </div>
  );
};
export default Account;

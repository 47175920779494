import { LinearProgress } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import ProductAddToCartButton from "../components/ProductAddToCartButton";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";

const Product = () => {
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [nearbybazarAPI, resultAPI] = APIHook();
  const [nearbybazarAPIOptions, resultAPIOptions] = APIHook();
  const [cartValue, setCartValue] = useContext(cartContext);
  const history = useHistory();
  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  const ProductID = location.pathname.split("/")[3];

  var addtocartByLink = false;

  if (location.pathname.split("/").length > 3)
    if (location.pathname.split("/")[4] === "addtocart") addtocartByLink = true;

  const [bottomDrawer, setBottomDrawer] = useState(addtocartByLink);
  const [multipleOptionsDrawer, setMultipleOptionsDrawer] = useState(false);
  const [variantSelection, setVariantSelection] = useState([]);
  const [addonSelection, setAddonSelection] = useState([]);
  const [nextRequired, setNextRequired] = useState("not set");
  const [optionScreen, setOptionScreen] = useState(1);
  const [currentVariant, setCurrentVariant] = useState([]);
  const [currentAddon, setCurrentAddon] = useState([]);
  const [currentVariantId, setCurrentVariantId] = useState("");
  const [isOptionApiCalled, setIsOptionApiCalled] = useState(0);
  const [quantityInCart, setQuantityInCart] = useState(-1);
  const [buyNowButton, setBuyNowButton] = useState("Buy Now");

  const fetchProduct = async () => {
    const data = {
      link: storeName,
      product_id: ProductID,
    };
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPI(data, "/front/product/");
  };

  const fetchProductOptions = async (variant_id) => {
    const data = {
      variant_id: variant_id,
    };
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIOptions(data, "/front/getoptionsforproduct/");
    setIsOptionApiCalled(1);
  };

  const fetchProductPreviousOptions = async (variant_id) => {
    const data = {
      variant_id: variant_id,
    };
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIOptions(data, "/front/getprevoptionforproduct/");
    setIsOptionApiCalled(1);
  };
  const prepareVariantForApi = (optionArray) => {
    var optionArrayObject = { ...JSON.parse(JSON.stringify(optionArray)) };
    optionArrayObject = Object.entries(optionArrayObject).reduce(
      (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
      {}
    );
    return JSON.stringify(optionArrayObject);
  };

  const prepareAddonForApi = (optionArray) => {
    var optionArrayObject = { ...JSON.parse(JSON.stringify(optionArray)) };
    optionArrayObject = Object.entries(optionArrayObject).reduce(
      (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
      {}
    );
    Object.keys(optionArrayObject).forEach((element) => {
      var AddonArrayObject = {
        ...JSON.parse(JSON.stringify(optionArrayObject[element])),
      };
      AddonArrayObject = Object.entries(AddonArrayObject).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : ((a[k] = v), a)),
        {}
      );
      optionArrayObject[element] = AddonArrayObject;
    });
    return JSON.stringify(optionArrayObject);
  };

  const addToCart = async (pid, quantity) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: prepareVariantForApi(variantSelection),
      addon: prepareAddonForApi(addonSelection),
    };
    // console.log("variants", prepareVariantForApi(variantSelection));

    //console.log("addon", prepareAddonForApi(addonSelection));

    console.log("SENDING API REQUEST WITH DATA " + data);
    await nearbybazarAPICart(data, "/front/addtocart/");
  };

  useEffect(() => {
    console.log("path: " + location.pathname);

    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const nextScreen = () => {
    setOptionScreen(optionScreen + 1);
    fetchProductOptions(currentVariantId);
  };
  const previousScreen = () => {
    setOptionScreen(optionScreen - 1);
    setNextRequired("true");
    fetchProductPreviousOptions(currentVariantId);
  };

  const moveForwardButton = () => {
    if (nextRequired === "true") {
      return (
        <React.Fragment>
          <Button
            className="btn-add pl-5 pr-5 rounded-sm width-100"
            variant="primary"
            onClick={() => {
              nextScreen();
            }}
          >
            <span className="addIcon">NEXT</span>
          </Button>
        </React.Fragment>
      );
    } else if (nextRequired === "false") {
      const minOrderQuantity = parseInt(
        resultAPI?.data?.product?.minimum_quantity
          ? resultAPI?.data?.product?.minimum_quantity
          : 1
      );
      return (
        <React.Fragment>
          <table className="width-100">
            <td className="width-50 p-1">
              <ProductAddToCartButton
                quantityInCart={quantityInCart === -1 ? 0 : quantityInCart}
                minimumOrderQuantity={minOrderQuantity}
                onChangeQuantity={(quantity) => {
                  if (
                    quantity > minOrderQuantity &&
                    quantityInCart < quantity
                  ) {
                    setBottomDrawer(false);
                    setMultipleOptionsDrawer(true);
                  } else {
                    setQuantityInCart(quantity);
                    addToCart(resultAPI.data.product.product_id, quantity);
                  }
                }}
              />
            </td>
            <td className="width-50 p-1">
              <React.Fragment>
                {quantityInCart < 1 ? (
                  <Button
                    className="btn-add pl-5 pr-5 rounded-sm width-100"
                    variant="primary"
                    onClick={async () => {
                      if (buyNowButton !== "Loading...") {
                        setBuyNowButton("Loading...");
                        await addToCart(
                          resultAPI.data.product.product_id,
                          minOrderQuantity
                        );

                        // window.location = "/" + storeName + "/cart";
                        history.push({
                          pathname: "/" + storeNameLink + "/cart",
                        });
                      }

                      //console.log(bottomDrawer);
                    }}
                  >
                    <span className="addIcon">{buyNowButton}</span>
                  </Button>
                ) : (
                  <Link to={`/${storeNameLink}/cart`}>
                    <Button
                      className="btn-add pl-5 pr-5 rounded-sm width-100"
                      variant="primary"
                    >
                      <span className="addIcon">GO TO CART</span>
                    </Button>{" "}
                  </Link>
                )}
              </React.Fragment>
            </td>
          </table>
        </React.Fragment>
      );
    }
  };

  const updateVariantSelected = (key, value, havechild) => {
    var temp = JSON.parse(JSON.stringify(variantSelection));
    temp[key] = value;

    setCurrentVariantId(value);

    if (havechild === "1") {
      setNextRequired("true");
    } else {
      setNextRequired("false");
    }
    setVariantSelection(temp);
  };

  const updateAddonSelected = (key, value) => {
    var temp = JSON.parse(JSON.stringify(addonSelection));

    if (temp[key][value] === "1") {
      temp[key][value] = "";
    } else temp[key][value] = "1";
    setAddonSelection(temp);
  };

  const drawerJSX = (variant, addon) => {
    if (variant.length === 0 && nextRequired === "not set") {
      setNextRequired("false");
    }
    if (variant.length > 0) {
      if (variantSelection.length === 0) {
        var temp = [];
        for (var i = 0; i < variant.length; i++) {
          temp[variant[i].variant_child_id] = "";
        }
        setVariantSelection(temp);
      }
    }

    if (addon.length > 0) {
      if (addonSelection.length === 0) {
        var temp1 = [];
        for (let i = 0; i < addon.length; i++) {
          temp1[addon[i].addon_child_id.toString()] = [];
          console.log("ADDON TEMP::", temp1);
          for (let j = 0; j < addon[i].options.length; j++) {
            temp1[addon[i].addon_child_id.toString()][
              addon[i].options[j].addon_child_option_id.toString()
            ] = "";
          }
        }

        setAddonSelection(temp1);
      }
    }
    if (resultAPICart.status === "success") {
      if (cartValue !== parseInt(resultAPICart.data.cart_items))
        setCartValue(parseInt(resultAPICart.data.cart_items));
    }

    if (resultAPIOptions.status !== "success" && isOptionApiCalled === 1)
      return (
        <div className="variantContainer">
          <LinearProgress color="secondary" />
        </div>
      );
    else
      return (
        <div className="variantContainer">
          {optionScreen !== 1 ? (
            <div
              className="buttonProductOptionBack"
              onClick={() => {
                previousScreen();
              }}
            >
              <ArrowBackIcon className="productHeaderIcon" />
              <span className="buttonProductOptionBackText"> BACK</span>
            </div>
          ) : null}

          {variant.length > 0
            ? variant.map((variant) => (
                <React.Fragment key={variant}>
                  <div className="variantHeading">
                    {variant.name}{" "}
                    <span className="optionSelectInstruction">
                      (select any one)
                    </span>
                  </div>
                  <Row>
                    {variant.options.map((variantOption) => (
                      <React.Fragment key={variantOption}>
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 3 }}
                          xl={{ span: 3 }}
                          key={variantOption.variant_option_child_id}
                        >
                          <div
                            className={
                              variantSelection[
                                variantOption.variant_child_id
                              ] === variantOption.variant_child_option_id
                                ? "variantOptionHeadingSelected"
                                : "variantOptionHeading"
                            }
                            onClick={() => {
                              updateVariantSelected(
                                variantOption.variant_child_id,
                                variantOption.variant_child_option_id,
                                variantOption.havechild
                              );
                            }}
                          >
                            <span className="variantOptionName">
                              {variantOption.name}{" "}
                            </span>
                            <span className="variantOptionPrice">
                              (₹{variantOption.price} Extra)
                            </span>
                          </div>
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                </React.Fragment>
              ))
            : null}

          {addon.length > 0
            ? addon.map((addon) => (
                <React.Fragment key={addon}>
                  <div className="variantHeading">
                    {addon.name}{" "}
                    <span className="optionSelectInstruction">
                      (Choose any of them)
                    </span>
                  </div>
                  <Row>
                    {addon.options.map((addonOption) => (
                      <React.Fragment key={addonOption}>
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 4 }}
                          md={{ span: 4 }}
                          lg={{ span: 3 }}
                          xl={{ span: 3 }}
                          key={addonOption.addon_option_child_id}
                        >
                          <div
                            className={
                              addonSelection.length > 0
                                ? addonSelection[addonOption.addon_child_id][
                                    addonOption.addon_child_option_id
                                  ] === "1"
                                  ? "variantOptionHeadingSelected"
                                  : "variantOptionHeading"
                                : "variantOptionHeading"
                            }
                            onClick={() => {
                              updateAddonSelected(
                                addonOption.addon_child_id,
                                addonOption.addon_child_option_id
                              );
                            }}
                          >
                            <span className="variantOptionName">
                              {addonOption.name}{" "}
                            </span>
                            <span className="variantOptionPrice">
                              (₹{addonOption.price} Extra)
                            </span>
                          </div>
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                </React.Fragment>
              ))
            : null}
        </div>
      );
  };

  return resultAPI.status === "success" ? (
    <ThemeChooser
      props={{
        storeNameLink: storeNameLink,
        resultAPI: resultAPI,
        resultAPIOptions: resultAPIOptions,
        quantityInCart: quantityInCart,
        setQuantityInCart: setQuantityInCart,
        currentVariant: currentVariant,
        setCurrentVariant: setCurrentVariant,
        currentAddon: currentAddon,
        setCurrentAddon: setCurrentAddon,
        variantSelection: variantSelection,
        setVariantSelection: setVariantSelection,
        setNextRequired: setNextRequired,
        addonSelection: addonSelection,
        setAddonSelection: setAddonSelection,
        bottomDrawer: bottomDrawer,
        setBottomDrawer: setBottomDrawer,
        addToCart: addToCart,
        buyNowButton: buyNowButton,
        setBuyNowButton: setBuyNowButton,
        history: history,
        drawerJSX: drawerJSX,
        moveForwardButton: moveForwardButton,
        multipleOptionsDrawer: multipleOptionsDrawer,
        setMultipleOptionsDrawer: setMultipleOptionsDrawer,
      }}
      name="product"
    />
  ) : (
    <div>
      <LinearProgress color="secondary" />
      <LoaderComponent storeName={storeName} />
    </div>
  );
};

export default Product;

import React from "react";
import styles from "./styles.module.css";

const Marquee = ({ text }) => {
  if (text) {
    const textArray = [];
    for (let i = 0; i < 100; i++) textArray.push(text);

    return (
      <div className={styles.marqueeContainer}>
        {textArray.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    );
  } else return <></>;
};

export default Marquee;

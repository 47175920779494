import React, { useContext, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import { cartContext } from "../../../helper/cartContext";

const QuantityButton = ({
  quantityInCart,
  onChangeQuantity,
  minimumOrderQuantity = 1,
}) => {
  const [quantity, setQuantity] = useState(quantityInCart);
  const [cartValue, setCartValue] = useContext(cartContext);

  return quantity === 0 ? (
    <div className="btn-add">
      <button
        type="button"
        class="btn btn-outline-primary btn-add"
        onClick={() => {
          setQuantity((prevState) => prevState + minimumOrderQuantity);

          onChangeQuantity(quantity + minimumOrderQuantity);
        }}
      >
        ADD
        <AddIcon className="addIcon" />
      </button>
    </div>
  ) : (
    <div className="quantity">
      <button
        type="button"
        className="quantity__minus"
        disabled={quantity === 0}
        onClick={() => {
          if (quantity > minimumOrderQuantity) {
            setQuantity((prevState) => prevState - 1);
            onChangeQuantity(quantity - 1);
          } else {
            setQuantity(0);
            onChangeQuantity(0);
          }
        }}
      >
        -
      </button>
      <input value={quantity} className="quantity__input" disabled />
      <button
        type="button"
        className="quantity__plus"
        onClick={() => {
          setQuantity((prevState) => prevState + 1);
          onChangeQuantity(quantity + 1);
        }}
      >
        +
      </button>
    </div>
  );
};

export default QuantityButton;

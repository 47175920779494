/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@material-ui/core";
import ls from "local-storage";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import CategoryCard from "../components/OverlayTextCard";
import Searchbar from "../components/Searchbar";
import APIHook from "../helper/APIHook";
import Navbar from "../themes/default/components/Navbar";

const Category = () => {
  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;
  const [swiperWidth, setSwiperWidth] = useState(0);
  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const [nearbybazarAPICategories, resultAPICategories] = APIHook();
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  const getCart = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICategories(data, "/front/categorylist");
  };
  useEffect(() => {
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (swiperWidth === 0 && ls.get("swiperWidth")) {
    setSwiperWidth(ls.get("swiperWidth"));
  } else if (swiperWidth === 0) {
    setSwiperWidth("12vh");
  }

  return resultAPICategories.status === "success" ? (
    <React.Fragment>
      <div className="container">
        <Searchbar
          placeholder="Search Products.."
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onButtonHit={() => {
            history.push({
              pathname: "/" + storeNameLink + "/search/" + searchValue,
            });
          }}
        />
      </div>
      <hr />
      <div className="container">
        <div className="products-under-category-list">
          <p>Listed Categories</p>
          <Row gutter={40} style={{ marginLeft: 0 }}>
            <React.Fragment>
              {Object.values(resultAPICategories.data).map((category) => (
                <Col
                  xs={{ span: 4 }}
                  sm={{ span: 2 }}
                  md={{ span: 2 }}
                  lg={{ span: 2 }}
                  xl={{ span: 2 }}
                  style={{ paddingRight: "ypx", paddingLeft: "5px" }}
                >
                  <Link
                    to={{
                      pathname: `/${storeNameLink}/category/${category.biz_shop_category_id}`,
                    }}
                  >
                    <CategoryCard
                      image={category.image}
                      text={category.name}
                      key={category.biz_shop_category_id}
                      width={swiperWidth}
                    />
                  </Link>
                </Col>
              ))}
            </React.Fragment>
          </Row>
        </div>
      </div>
      <div className="margin-bottom-150px"></div>
      <Navbar storeName={storeNameLink} />
    </React.Fragment>
  ) : (
    <div>
      <LinearProgress color="secondary" />
      <LoaderComponent storeName={storeName} />
    </div>
  );
};

export default Category;

import React from "react";
import { getWeightInText } from "../../helper/weight";
import { Label } from "../Label";
export const WeightPerItem = ({ weight }) => {
  return weight && weight > 0 ? (
    <Label text={`Weight Per Item:  ${getWeightInText(weight)}`} />
  ) : (
    <></>
  );
};

import { Drawer, LinearProgress, TextField } from "@material-ui/core";
import { ArrowRightAlt, WarningOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { cartContext } from "../../../../helper/cartContext";
import { getWeightInText } from "../../../../helper/weight";
import AddToCartButton from "../../components/AddToCartButton";
import Button from "../../components/Button/Button";
import { whichDevice } from "../../lib/device";
import styles from "./styles.module.css";
const Cart = ({
  cartData,
  storeNameLink,
  showOptions,
  addToCart,
  setBottomDrawer,
  applyCoupon,
  bottomDrawer,
  setCoupon,
  resultAPICartAdd,
  applyCouponDirect,
  emptyCart,
}) => {
  const isMobile = whichDevice() === "mobile";
  const [cartValue] = useContext(cartContext);

  return (
    <Container className="mt-5">
      {cartData.status === "success" ? (
        <React.Fragment>
          {cartData.data.products.length ? (
            <React.Fragment>
              <div className="cart-page-heading">
                <h5 className="d-flex justify-content-between align-items-center">
                  Your Cart
                  <Button text={cartValue} />
                </h5>
              </div>

              <Row>
                <Col xs={12} lg={8}>
                  {Object.values(cartData.data.products).map((cartItem) => (
                    <Row
                      key={cartItem.cart_id}
                      style={{
                        paddingTop: "4vh",
                        paddingBottom: "4vh",
                        marginRight: "3rem",
                        borderBottom: "1px solid #efefef",
                      }}
                    >
                      <Col
                        lg={4}
                        xs={4}
                        sm={3}
                        style={{
                          paddingRight: 0,
                          textAlign: "center",
                          paddingLeft: 0,
                        }}
                      >
                        {" "}
                        <Link
                          to={`/${storeNameLink}/product/${cartItem.product_id}`}
                        >
                          <div className="cart-item-image">
                            <img
                              src={cartItem.image}
                              className="img-fluid"
                              alt="product"
                            />
                          </div>
                        </Link>
                      </Col>

                      <Col lg={8} xs={8} sm={9} style={{ paddingLeft: 0 }}>
                        <div className="cart-item-details">
                          <Link
                            to={`/${storeNameLink}/product/${cartItem.product_id}`}
                          >
                            <p className="cart-product-name">
                              {cartItem.name}{" "}
                              <span className={styles.secondaryText}>
                                (₹{cartItem.price})
                              </span>
                            </p>
                          </Link>
                          {showOptions(cartItem.option_json)}
                          <Row>
                            <Col lg={6} xs={6}>
                              <p className="cart-product-price">
                                <span className="cart-product-price-rs-sign">
                                  ₹
                                </span>
                                {cartItem.product_total}
                              </p>
                              {cartItem?.tax > 0 ? (
                                <div className={styles.secondaryText}>
                                  GST({cartItem?.gst}%): ₹{cartItem?.tax}
                                </div>
                              ) : null}
                            </Col>
                            <Col lg={6} xs={6}>
                              <div style={{ paddingTop: "2vh" }}>
                                <AddToCartButton
                                  md
                                  quantityInCart={parseInt(cartItem.quantity)}
                                  minimumOrderQuantity={parseInt(
                                    cartItem.minimum_quantity
                                  )}
                                  onClick={(quantity) => {
                                    addToCart(
                                      cartItem.product_id,
                                      quantity,
                                      cartItem.variant,
                                      cartItem.addon
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col xs={12} lg={4}>
                  <div className="cart-total">
                    <div className="cart-coupon-area-mobile">
                      <Row>
                        <Col xs="2">
                          <div className="cart-coupon-icon-mobile">%</div>
                        </Col>
                        <Col xs="7" style={{ padding: 0 }}>
                          {cartData.data.total.coupon.coupon_name === "" ? (
                            <div
                              className="cart-coupon-text-mobile"
                              onClick={() => {
                                setBottomDrawer(true);
                              }}
                            >
                              APPLY COUPON
                            </div>
                          ) : (
                            <div>
                              <div
                                className="cart-coupon-text-mobile"
                                style={{ marginTop: "0.2vh" }}
                              >
                                {cartData.data.total.coupon.coupon_code.toUpperCase()}
                              </div>
                              <div>
                                {cartData.data.total.coupon.coupon_name.toUpperCase()}
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col
                          xs="3"
                          onClick={() => {
                            if (cartData.data.total.coupon.coupon_name !== "")
                              applyCoupon("remove");
                          }}
                          className="text-right"
                        >
                          {cartData.data.total.coupon.coupon_name === "" ? (
                            <ChevronRightIcon className="cart-coupon-chevron-right-mobile" />
                          ) : (
                            <CancelIcon className="cart-coupon-chevron-right-mobile" />
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="cart-details">
                      <table className="table">
                        {cartData.delivery_fee_by_order_weight_flag === "1" ? (
                          <tr>
                            <td>Order Weight:</td>
                            <td style={{ textAlign: "right" }}>
                              {getWeightInText(cartData.total_weight)}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>Sub-Total:</td>
                          <td style={{ textAlign: "right" }}>
                            ₹ {cartData.data.total.sub_total}
                          </td>
                        </tr>

                        {cartData.data.total.coupon.coupon_name !== "" ? (
                          <tr>
                            <td>Coupon:</td>
                            <td style={{ textAlign: "right" }}>
                              <div>
                                <div>
                                  <div
                                    style={{
                                      color: "#009688",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    - ₹{cartData.data.total.coupon.coupon_value}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>Delivery</td>
                          <td style={{ textAlign: "right" }}>
                            ₹ {cartData.data.total.deliveryfee}
                          </td>
                        </tr>
                        {cartData?.data?.total?.tax > 0 ? (
                          <tr>
                            <td>Taxes</td>
                            <td style={{ textAlign: "right" }}>
                              ₹ {cartData.data.total.tax}
                            </td>
                          </tr>
                        ) : null}
                        <tr className="total">
                          <td>Grand Total:</td>
                          <td style={{ textAlign: "right" }}>
                            {" "}
                            ₹ {cartData.data.total.total}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="cart-checkout-button" style={{ bottom: 0 }}>
                      <Row>
                        <Col
                          lg={0}
                          xs={5}
                          className="cart-mobile-final-total-container"
                        >
                          <div style={{ fontWeight: "bold" }}>TO PAY</div>
                          <div className="cart-mobile-final-total">
                            ₹{cartData.data.total.total}
                          </div>
                        </Col>
                        <Col lg={12} xs={7}>
                          {cartData.data.store_status === "1" ? (
                            <Link to={`/${storeNameLink}/login`}>
                              {isMobile ? (
                                <div className="d-flex justify-content-end mt-3">
                                  <Button text="Checkout >" />
                                </div>
                              ) : (
                                <Button
                                  text="Checkout"
                                  animated
                                  Icon={ArrowRightAlt}
                                  className="w-100"
                                />
                              )}
                            </Link>
                          ) : (
                            <Button
                              text="Store Offline"
                              animated
                              disabled
                              Icon={WarningOutlined}
                              className="w-100"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="margin-bottom-150px"></div>
                  </div>
                  <Drawer
                    anchor={"bottom"}
                    open={bottomDrawer}
                    onClose={() => {
                      setBottomDrawer(false);
                    }}
                  >
                    <div className="cart-input-coupon-container">
                      <Row>
                        <Col xs={8} lg={10}>
                          <TextField
                            className="form-control"
                            onChange={(e) => setCoupon(e.target.value)}
                            label="Enter Coupon Code"
                            style={{ fontWeight: "bold" }}
                            size="medium"
                            row="1"
                          />
                        </Col>
                        <Col xs={4} lg={2}>
                          <Button
                            md
                            text="Apply"
                            onClick={() => {
                              applyCoupon("add");
                            }}
                          />
                        </Col>
                      </Row>

                      <div style={{ fontSize: "2vh", color: "#c71d10" }}>
                        {resultAPICartAdd.status === "error"
                          ? resultAPICartAdd.message_code === "COUPON"
                            ? resultAPICartAdd.message
                            : null
                          : null}
                      </div>
                      {Object.values(cartData.data.coupon).map((item) => (
                        <div
                          className={"col-sm-4"}
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            boxShadow: "0px 0px 10px #ccc",
                            marginTop: "20px",
                            padding: "10px",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.3em",
                                background: "#009688",
                                padding: "5px",
                                color: " white",
                                display: "inline-block",
                              }}
                            >
                              {item.code}
                            </div>
                            <Button
                              md
                              text="APPLY"
                              onClick={() => {
                                applyCouponDirect("add", item.code);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.4em",
                              paddingTop: "5px",
                            }}
                          >
                            {item.name}
                          </div>
                          <div>
                            Apply this coupon and get {item.value}
                            {item.type === "percent" ? "%" : "₹"} off upto ₹
                            {item.max_discount} instantly.
                            <div>
                              Applicable on order above ₹{item.min_amount}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="margin-bottom-150px"></div>
                  </Drawer>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <div>
              <div className="cart-page-heading">
                <h5 className="d-flex justify-content-between">
                  Your Cart{" "}
                  <Button text={cartData.data.products.length} disabled />
                </h5>
              </div>
              <div class="container-cart-empty">
                <div class="d-flex flex-column">
                  <img src={emptyCart} alt="product" />
                  <h5 className="cart-empty-heading">Your cart is empty</h5>
                  <h5 className="cart-empty-detail mb-5">
                    Looks like you haven't added
                    <br />
                    anything in your cart yet
                  </h5>
                  <Link to={`/${storeNameLink}/store`}>
                    <Button
                      text="Continue Shopping"
                      animated
                      Icon={ArrowRightAlt}
                    />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </Container>
  );
};
export default Cart;

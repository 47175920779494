import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { whichDevice } from "../../lib/device";
import { useOutsideAlerter } from "../../lib/outsideAlerter";
import { slicedDataElements } from "../../lib/slicedata";
import styles from "./styles.module.css";

const Nav = ({ headerHeight = 0, data, storeNameLink }) => {
  //list of catalogs
  //list of categories
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const device = whichDevice();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [allCatalogMobile, setAllCatalogMobile] = useState([]);
  const [allCatalogPC, setAllCatalogPC] = useState([]);
  const [showMenuListPC, setShowMenuListPC] = useState([]);

  const handleHeadClick = (type, index) => {
    wrapperRef.current.style.display = "block";
    if (type === "category") setShowMenuListPC(defaultCategoriesPC());
    else if (type === "catalog") {
      setShowMenuListPC(allCatalogPC[index]);
    }
  };
  const toggleMobileNav = () => {
    setMobileNavOpen((prev) => !prev);
  };

  const defaultCategoriesPC = useCallback(() => {
    if (data?.all_category) {
      const batchSize = 5;
      return slicedDataElements(
        data.all_category,
        batchSize,
        storeNameLink,
        styles
      );
    }
  }, [data.all_category, storeNameLink]);

  const defaultCategoriesMobile = useCallback(() => {
    if (data?.all_category) {
      return (
        <>
          {data?.all_category?.map((item, index) => {
            if (item.products.length > 0)
              return (
                <Link
                  to={{
                    pathname: `/${storeNameLink}/category/${item.biz_shop_category_id}`,
                  }}
                >
                  <div
                    className={styles.accordionItemContainerBody}
                    key={index}
                  >
                    <div className={styles.mobileItem}>{item.name}</div>
                    <ArrowForwardIcon
                      className={styles.accordionItemIconLeft}
                    />
                  </div>
                </Link>
              );
          })}
        </>
      );
    } else return <div>loading...</div>;
  }, [data.all_category, storeNameLink]);

  useEffect(() => {
    if (data?.all_catalog) {
      const temp = [];
      data.all_catalog.map((item, index) => {
        temp.push(
          slicedDataElements(item.all_category, 5, storeNameLink, styles)
        );
        return null;
      });
      setAllCatalogPC(temp);
    }
  }, [data, storeNameLink]);

  return device === "mobile" ? (
    <>
      <div
        className={styles.mobileMenuButton}
        onClick={() => {
          toggleMobileNav();
        }}
      >
        {mobileNavOpen ? (
          <div>
            <CloseIcon className={styles.mobileMenuButtonIcon} />
          </div>
        ) : (
          <MenuOutlinedIcon className={styles.mobileMenuButtonIcon} />
        )}
      </div>
      <div
        className={`${styles.mobileNav} ${
          mobileNavOpen ? styles.showMobileNav : ""
        }`}
        style={{ height: `calc(100% - ${headerHeight + 10}px)` }}
      >
        <Accordion>
          <div className={styles.accordionItemContainer}>
            <Accordion.Toggle
              eventKey="0"
              className={styles.accordionItemContainerHeader}
            >
              <div className={styles.mobileItem}>Categories</div>
              <ExpandMoreOutlinedIcon className={styles.accordionItemIcon} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              {defaultCategoriesMobile()}
            </Accordion.Collapse>
          </div>
          {data?.all_catalog?.map((item, index) => {
            var rendercount = 0;
            if (item.all_category?.length > 0 && rendercount <= 10) {
              rendercount++;
              return (
                <div className={styles.accordionItemContainer} key={index}>
                  <Accordion.Toggle
                    eventKey={index + 1}
                    className={styles.accordionItemContainerHeader}
                  >
                    <div className={styles.mobileItem}>{item.name}</div>
                    <ExpandMoreOutlinedIcon
                      className={styles.accordionItemIcon}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <>
                      {item.all_category.map((category) => {
                        return (
                          <div
                            className={styles.accordionItemContainerBody}
                            key={category.biz_shop_category_id}
                          >
                            <Link
                              to={{
                                pathname: `/${storeNameLink}/category/${category.biz_shop_category_id}`,
                              }}
                            >
                              <div className={styles.mobileItem}>
                                {category.name}
                              </div>
                            </Link>
                            <ArrowForwardIcon
                              className={styles.accordionItemIconLeft}
                            />
                          </div>
                        );
                      })}
                    </>
                  </Accordion.Collapse>
                </div>
              );
            } else return <></>;
          })}
        </Accordion>
      </div>
    </>
  ) : (
    <div className={styles.navContainer}>
      <div className={styles.innerContainer}>
        <div
          className={styles.headItem}
          onClick={() => {
            handleHeadClick("category", 0);
          }}
        >
          <span className={styles.headItemText}>Categories</span>
          <ExpandMoreOutlinedIcon className={styles.headItemIcon} />
        </div>
        <>
          {data?.all_catalog?.map((item, index) => {
            var rendercount = 0;
            if (item.all_category?.length > 0 && rendercount <= 10) {
              rendercount++;
              return (
                <div
                  className={styles.headItem}
                  key={index}
                  onClick={() => {
                    handleHeadClick("catalog", index);
                  }}
                >
                  <span className={styles.headItemText}>{item.name}</span>
                  <ExpandMoreOutlinedIcon className={styles.headItemIcon} />
                </div>
              );
            }
            return <></>;
          })}
        </>
      </div>
      <div className={styles.menuListContainer} ref={wrapperRef}>
        <div className={`${styles.innerContainer} d-flex`}>
          {showMenuListPC}
        </div>
      </div>
    </div>
  );
};
export default Nav;

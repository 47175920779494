import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import PaymentForm from "../components/PaymentForm";
import APIHook from "../helper/APIHook";

const RequestPayment = ({ ...props }) => {
  const [nearbybazarAPIOrder, resultAPIOrder] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [createOrderFlag, setCreateOrderFlag] = useState(false);
  const [txId, setTxId] = useState(undefined);
  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;
  var orderType = "delivery";
  var comment = "";
  let forwardUrl = "";
  if (props.location.state === undefined) {
    history.push({
      pathname: "/" + storeNameLink + "/store",
    });
  } else {
    orderType = props.location.state.orderType;
    comment = props.location.state.comment;
  }

  if (domain !== hostDomain.domain) {
    storeName = domain;
    forwardUrl = `${
      storeName === "localhost"
        ? "http://localhost:50002"
        : "https://" + storeName
    }/home/PaymentSuccess`;
  } else forwardUrl = `https://www.lobecho.com/${storeName}/PaymentSuccess`;

  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  const history = useHistory();
  const paymentForm = useRef(null);
  const getOrders = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/getorders");
  };

  const createOrderForPayment = async () => {
    console.log("Set create order flag true");
    setCreateOrderFlag(true);
    if (!createOrderFlag) {
      const data = {
        link: storeName,
        payment_method: "cashfree",
        order_type: orderType,
        comment: comment,
      };

      console.log("SENDING API REQUEST WITH DATA " + data);
      //step 0: create order
      const response = await nearbybazarAPIOrder(
        data,
        "/front/createorderforpayment"
      );
      if (response) {
        if (response.biz_merchant_id === "0") {
          alert(
            "Online Payment is not possible at this point, please contact the seller"
          );
          history.push({
            pathname: "/" + storeNameLink + "/orderconfirm",
          });
          return;
        }
        //step 1: init payment
        const dataInitPayment = {
          order_id: response.order_id,
          biz_merchant_id: response.biz_merchant_id,
          amount: response.order_value,
          phone: response.customer_phone,
          forwardUrl: forwardUrl,
        };
        const initPaymentResponse = await nearbybazarAPIOrder(
          dataInitPayment,
          "/universalpg/initpayment",
          "business"
        );
        if (initPaymentResponse) {
          const tx_id = initPaymentResponse?.data?.tx_id;
          localStorage.setItem("pg_tx_id", tx_id);
          setTxId(tx_id);
        }
      }
    }
  };

  useEffect(() => {
    if (resultAPICart.status !== "error" && resultAPICart.status !== "success")
      getOrders();

    if (resultAPICart.status === "success") createOrderForPayment();

    if (resultAPICart.status === "error") {
      if (resultAPICart.message_code === "LOGIN")
        history.push({
          pathname: "/" + storeNameLink + "/login",
        });
    }

    if (resultAPIOrder.status === "error")
      history.push({
        pathname: "/" + storeNameLink + "/cart",
      });

    if (paymentForm.current !== null) paymentForm.current.submit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAPICart.status]);

  if (txId) {
    return (
      <div>
        <PaymentForm tx_id={txId} />
        <LinearProgress color="secondary" />
      </div>
    );
  } else {
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
  }
};
export default RequestPayment;

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getWeightInText } from "../../../../helper/weight";
import Navbar from "../../components/Navbar";
import QuantityButton from "../../components/QuantityButton";
const ConfirmOrder = ({
  cartData,
  storeNameLink,
  showOptions,
  addToCart,
  resultAPICart,
  history,
  orderType,
  handleType,
  paymentMethod,
  handleAlignment,
  commentValue,
  setCommentValue,
  placeOrder,
  emptyCart,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <div>
      <Navbar storeName={storeNameLink} />

      <Container>
        <React.Fragment>
          {cartData.data.products.length ? (
            <React.Fragment>
              <div className="cart-page-heading">
                <h5>Confirm Order </h5>
              </div>

              <Row>
                <Col xs={12} lg={8}>
                  {Object.values(cartData.data.products).map((cartItem) => (
                    <Row
                      key={cartItem.cart_id}
                      style={{
                        paddingTop: "4vh",
                        paddingBottom: "4vh",
                        borderBottom: "1px solid #efefef",
                      }}
                    >
                      <Col
                        lg={4}
                        xs={4}
                        style={{ paddingRight: 0, textAlign: "center" }}
                      >
                        {" "}
                        <Link
                          to={`/${storeNameLink}/product/${cartItem.product_id}`}
                        >
                          <div className="cart-item-image">
                            <img src={cartItem.image} alt="product" />
                          </div>
                        </Link>
                      </Col>

                      <Col lg={8} xs={8} style={{ paddingLeft: 0 }}>
                        <div className="cart-item-details">
                          <Link
                            to={`/${storeNameLink}/product/${cartItem.product_id}`}
                          >
                            <p className="cart-product-name">
                              {cartItem.name}{" "}
                              <span className="cart-product-variant">
                                (₹{cartItem.price})
                              </span>
                            </p>
                          </Link>
                          {showOptions(cartItem.option_json)}
                          <Row>
                            <Col lg={6} xs={6}>
                              <p className="cart-product-price">
                                <span className="cart-product-price-rs-sign">
                                  ₹
                                </span>
                                {cartItem.product_total}
                              </p>
                              {cartItem?.tax > 0 ? (
                                <div className="cart-product-variant">
                                  GST({cartItem?.gst}%): ₹{cartItem?.tax}
                                </div>
                              ) : null}
                            </Col>
                            <Col lg={6} xs={6}>
                              <div style={{ paddingTop: "2vh" }}>
                                <QuantityButton
                                  quantityInCart={parseInt(cartItem.quantity)}
                                  minimumOrderQuantity={
                                    cartItem.minimum_quantity
                                  }
                                  onChangeQuantity={(quantity) => {
                                    addToCart(
                                      cartItem.product_id,
                                      quantity,
                                      cartItem.variant,
                                      cartItem.addon
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col xs={12} lg={4}>
                  <div className="confirm-address">
                    <Row>
                      <Col xl={12} lg={12}>
                        <Row>
                          <Col xs={5} xl={5}>
                            <p>DELIVERY AT :</p>
                          </Col>
                          <Col xs={7} xl={7}>
                            {resultAPICart.status === "success" ? (
                              <p>
                                <div>
                                  {
                                    resultAPICart.data.customer_address
                                      .address_1
                                  }
                                </div>
                                <div>
                                  {resultAPICart.data.customer_address.city} -{" "}
                                  {resultAPICart.data.customer_address.postcode}
                                </div>
                                <div>
                                  {resultAPICart.data.customer_address.zone}{" "}
                                  <div>
                                    <Button
                                      onClick={() => {
                                        history.push({
                                          pathname:
                                            "/" +
                                            storeNameLink +
                                            "/editaddress",
                                          state: {
                                            address:
                                              resultAPICart.data
                                                .customer_address,
                                          },
                                        });
                                      }}
                                    >
                                      EDIT
                                    </Button>
                                  </div>
                                </div>
                              </p>
                            ) : null}
                            <Button
                              style={{
                                background: "transparent",
                                fontSize: "18px",
                                border: "none",
                                color: "white",
                                padding: "5px",
                              }}
                            >
                              EDIT
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} xl={12}>
                        <Row>
                          <Col xs={5} xl={5}>
                            <p>ORDER TYPE:</p>
                          </Col>

                          <Col xs={7} xl={7}>
                            <ToggleButtonGroup
                              value={orderType}
                              exclusive
                              onChange={handleType}
                              aria-label="text alignment2"
                            >
                              {resultAPICart.home_delivery === "1" ? (
                                <ToggleButton
                                  value="delivery"
                                  aria-label="online-delivery"
                                  className="payment-mode-delivery"
                                  style={{ color: "#007bff", outline: "none" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginBottom: -5,
                                        fontWeight: "bold",
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                      }}
                                    >
                                      DELIVERY
                                    </p>
                                  </div>
                                </ToggleButton>
                              ) : null}
                              {resultAPICart.store_pickup === "1" ? (
                                <ToggleButton
                                  value="pickup"
                                  aria-label="pickup"
                                  className="payment-mode-pickup"
                                  style={{ color: "#009688", outline: "none" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    <p
                                      style={{
                                        marginBottom: -5,
                                        fontWeight: "bold",
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                      }}
                                    >
                                      PICKUP
                                    </p>
                                  </div>
                                </ToggleButton>
                              ) : null}
                            </ToggleButtonGroup>
                          </Col>
                        </Row>{" "}
                        <br />
                        <Row>
                          <Col xs={5} xl={5}>
                            <p>PAYMENT MODE:</p>
                          </Col>

                          <Col xs={7} xl={7}>
                            <ToggleButtonGroup
                              value={paymentMethod}
                              exclusive
                              onChange={handleAlignment}
                              aria-label="text alignment"
                            >
                              {resultAPICart.online_payment_status === "1" &&
                              resultAPICart.data.is_premium === 1 ? (
                                <ToggleButton
                                  value="online"
                                  aria-label="online-payment"
                                  className="payment-mode-online"
                                  style={{ color: "#007bff", outline: "none" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      class="bi bi-credit-card"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                                      />
                                      <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                    </svg>
                                    <p
                                      style={{
                                        marginBottom: -5,
                                        fontWeight: "bold",
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                      }}
                                    >
                                      Online
                                    </p>
                                  </div>
                                </ToggleButton>
                              ) : null}
                              {resultAPICart.cod_status === "1" ? (
                                <ToggleButton
                                  value="cod"
                                  aria-label="cod"
                                  className="payment-mode-cash"
                                  style={{ color: "#009688", outline: "none" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      class="bi bi-cash-stack"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M14 3H1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1h-1z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M15 5H1v8h14V5zM1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H1z"
                                      />
                                      <path d="M13 5a2 2 0 0 0 2 2V5h-2zM3 5a2 2 0 0 1-2 2V5h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 13a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                                    </svg>
                                    <p
                                      style={{
                                        marginBottom: -5,
                                        fontWeight: "bold",
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                      }}
                                    >
                                      Cash
                                    </p>
                                  </div>
                                </ToggleButton>
                              ) : null}
                            </ToggleButtonGroup>
                          </Col>
                        </Row>{" "}
                        <Row>
                          <div style={{ padding: 15 }}>
                            <b>Comment:</b>
                            <br />
                            <textarea
                              style={{
                                minWidth: 340,
                                border: " 1px solid rgb(204, 204, 204)",
                                borderRadius: 5,
                                marginTop: 0,
                                marginBottom: 0,
                                height: 110,
                                padding: 10,
                              }}
                              value={commentValue}
                              onChange={(text) => {
                                setCommentValue(text.target.value);
                              }}
                            ></textarea>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <div className="cart-total">
                      <div className="confirm-order-cart-details">
                        <table className="table">
                          {cartData.delivery_fee_by_order_weight_flag ===
                          "1" ? (
                            <tr>
                              <td>Order Weight:</td>
                              <td style={{ textAlign: "right" }}>
                                {getWeightInText(cartData.total_weight)}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Sub-Total:</td>
                            <td style={{ textAlign: "right" }}>
                              ₹ {cartData.data.total.sub_total}
                            </td>
                          </tr>

                          {cartData.data.total.coupon.coupon_name !== "" ? (
                            <tr>
                              <td>Coupon:</td>
                              <td style={{ textAlign: "right" }}>
                                <div>
                                  <div>
                                    <div
                                      style={{
                                        color: "#009688",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      - ₹
                                      {cartData.data.total.coupon.coupon_value}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Delivery</td>
                            <td style={{ textAlign: "right" }}>
                              ₹ {cartData.data.total.deliveryfee}
                            </td>
                          </tr>
                          {cartData?.data?.total?.tax > 0 ? (
                            <tr>
                              <td>Taxes</td>
                              <td style={{ textAlign: "right" }}>
                                ₹ {cartData.data.total.tax}
                              </td>
                            </tr>
                          ) : null}
                          <tr className="total">
                            <td>Grand Total:</td>
                            <td style={{ textAlign: "right" }}>
                              {" "}
                              ₹ {cartData.data.total.total}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <Row>
                      <Col xs={12} sm={12} xl={12}>
                        <Row>
                          <Col xs={12} xl={12}>
                            {resultAPICart.data.store_status === "1" ? (
                              <Button
                                className="pay-now-button"
                                onClick={() => {
                                  setButtonLoading(true);
                                  placeOrder();
                                }}
                                disabled={buttonLoading}
                              >
                                {buttonLoading
                                  ? "Loading..."
                                  : paymentMethod === "cod"
                                  ? "CONFIRM ORDER"
                                  : "PAY NOW"}
                              </Button>
                            ) : (
                              <Button className="cart-continue-button btn-danger">
                                Store Offline
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className="cart-checkout-button">
                <Row>
                  <Col
                    lg={0}
                    xs={5}
                    className="cart-mobile-final-total-container"
                  >
                    <div style={{ fontWeight: "bold" }}>TO PAY</div>
                    <div className="cart-mobile-final-total">
                      ₹{cartData.data.total.total}
                    </div>
                  </Col>
                  <Col lg={12} xs={7}>
                    {resultAPICart.data.store_status === "1" ? (
                      <Button
                        className="confirm-order-continue-button"
                        onClick={() => {
                          setButtonLoading(true);
                          placeOrder();
                        }}
                        disabled={buttonLoading}
                      >
                        {buttonLoading
                          ? "Loading..."
                          : paymentMethod === "cod"
                          ? "CONFIRM ORDER"
                          : "PAY NOW"}
                      </Button>
                    ) : (
                      <Button className="cart-continue-button btn-danger">
                        Store Offline
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="margin-bottom-150px"></div>
            </React.Fragment>
          ) : (
            <div>
              <div className="cart-page-heading">
                <h5>
                  Your Cart{" "}
                  <span
                    class="cart-product-count"
                    style={{ float: "right", marginRight: "2vh" }}
                  >
                    {cartData.data.products.length}
                  </span>
                </h5>
              </div>
              <div class="container-cart-empty">
                <div class="cart">
                  <img src={emptyCart} alt="product" />
                  <h5 className="cart-empty-heading">Your cart is empty</h5>
                  <h5 className="cart-empty-detail">
                    Looks like you haven't added
                    <br />
                    anything in your cart yet
                  </h5>
                  <Link to={`/${storeNameLink}/store`}>
                    <Button className="cart-empty-back-button">
                      Continue Shopping
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>{" "}
      </Container>
    </div>
  );
};
export default ConfirmOrder;

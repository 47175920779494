import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Logo = ({ url, storeNameLink }) => {
  return (
    <div className={styles.logoContainer}>
      <Link
        to={{
          pathname: `/${storeNameLink}`,
        }}
      >
        <img src={url} alt="Logo" className={styles.logo} />
      </Link>
    </div>
  );
};

export default Logo;

import React, { useEffect, useState } from "react";
const PaymentSuccess = ({ storeNameLink, orderID, status, history }) => {
  const [pageData, setPageData] = useState({
    title: "",
    description: "",
    actionText: "",
    isAction: false,
    isSuccess: false,
    isPending: false,
    isFailed: false,
  });
  useEffect(() => {
    if (status === "SUCCESS") {
      setPageData({
        title: "Order Placed",
        description:
          "Thank you for order with us. We will deliver your order as soon as possible. You can view your order details on account section.",
        actionText: "See Your Order",
        isAction: true,
        isSuccess: true,
        isPending: false,
        isFailed: false,
      });
    } else if (status === "INITIATED") {
      setPageData({
        title: "Payment Pending",
        description:
          "Your Payment is pending with your bank, we will automatically place your order when we recieve confirmation from your bank about the payment.",
        actionText: "",
        isAction: false,
        isSuccess: false,
        isPending: true,
        isFailed: false,
      });
    } else if (status === "FAILED") {
      setPageData({
        title: "Payment Failed",
        description:
          "Oh ho, Your last payment is failed, you can try placing the order again. Any payment deducted will be refunded within 2-3 business days.",
        actionText: "",
        isAction: false,
        isSuccess: false,
        isPending: false,
        isFailed: true,
      });
    }
  }, [status]);
  return (
    <div className="success-page">
      <div className="success-container">
        <div className="printer-top"></div>

        <div className="paper-container">
          <div className="printer-bottom"></div>

          <div className="paper">
            <div className="main-contents">
              {pageData.isSuccess ? (
                <div className="success-icon">&#10004;</div>
              ) : null}
              {pageData.isFailed ? (
                <div className="failed-icon">&#10005;</div>
              ) : null}
              {pageData.isPending ? (
                <div className="pending-icon">!</div>
              ) : null}
              <div className="success-title">{pageData.title}</div>
              <div className="success-description">{pageData.description}</div>
              <div className="order-details">
                <div className="order-number-label"></div>
                {pageData.isSuccess ? (
                  <div className="order-number">ORDER # :{orderID}</div>
                ) : null}
              </div>
              <div className="order-footer">
                {pageData.isAction ? (
                  <div
                    className="btn btn-primary"
                    style={{
                      marginTop: 10,
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      letterSpacing: 1,
                      backgroundColor: "#666666",
                      borderColor: "#666666",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: "/" + storeNameLink + "/account",
                      });
                    }}
                  >
                    {pageData.actionText}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="jagged-edge"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentSuccess;

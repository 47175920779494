import React, { createContext, useState } from "react";
export const accountContext = createContext();
export const AccountProvider = (props) => {
  const [accountData, setAccountData] = useState({});

  return (
    <accountContext.Provider value={[accountData, setAccountData]}>
      {props.children}
    </accountContext.Provider>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@material-ui/core";
import ls from "local-storage";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "swiper/swiper.scss";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";

const Catalog = () => {
  const [swiperWidth, setSwiperWidth] = useState(0);
  const [nearbybazarAPI, resultAPI] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [cartValue, setCartValue] = useContext(cartContext);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const location = useLocation();

  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  var catalogID = window.location.pathname.split("/")[3];

  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  var sliderViewCount = 3.3;

  if (window.innerWidth > 600) sliderViewCount = 7;

  const fetchCategories = async () => {
    const data = {
      link: storeName,
      catalog_id: catalogID,
    };
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPI(data, "/front/catalog/");
  };

  const addToCart = async (pid, quantity) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: "{}",
      addon: "{}",
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/addtocart/");
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow:
      resultAPI.status === "success"
        ? resultAPI.data.all_category.length < 7
          ? 7.3
          : 7.3
        : 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          speed: 500,
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
    ],
  };
  if (resultAPI.status === "success") {
    // ls.set("storeData", resultAPI);
    if (cartValue === 0 && resultAPI.data.products_in_cart !== "0")
      setCartValue(parseInt(resultAPI.data.products_in_cart));
  }
  if (resultAPICart.status === "success") {
    if (cartValue !== parseInt(resultAPICart.data.cart_items))
      setCartValue(parseInt(resultAPICart.data.cart_items));
  }
  if (swiperWidth === 0 && ls.get("swiperWidth")) {
    setSwiperWidth(ls.get("swiperWidth"));
  } else if (swiperWidth === 0) {
    setSwiperWidth("12vh");
  }
  if (resultAPI.status === "success")
    return (
      <ThemeChooser
        props={{
          resultAPI: resultAPI,
          searchValue: searchValue,
          history: history,
          storeNameLink: storeNameLink,
          sliderViewCount: sliderViewCount,
          swiperWidth: swiperWidth,
          setSearchValue: setSearchValue,
          addToCart: addToCart,
          catalogID: catalogID,
        }}
        name="catalog"
      />
    );
  else
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
};

export default Catalog;

/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@material-ui/core";
import ls from "local-storage";
import React, { useContext, useEffect } from "react";
import "swiper/swiper.scss";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import { accountContext } from "../helper/AccountContext";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";
const Store = () => {
  const [nearbybazarAPI, resultAPI] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const domain = window.location.hostname;
  const hostDomain = require("../resources/hostdomain.json");
  const storeName =
    domain !== hostDomain.domain
      ? domain
      : window.location.pathname.split("/")[1];

  const [cartValue, setCartValue] = useContext(cartContext);
  const [accountData, setAccountData] = useContext(accountContext);

  const fetchCategories = async () => {
    const data = {
      link: storeName,
    };
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPI(data, "/front/front/");
  };

  const addToCart = async (pid, quantity) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: "{}",
      addon: "{}",
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/addtocart/");
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  //ls.clear();

  if (resultAPI.status === "success") {
    ls.set("storeData", resultAPI);
    if (cartValue === 0 && resultAPI.data.products_in_cart !== "0")
      setCartValue(parseInt(resultAPI.data.products_in_cart));
    if (resultAPI.data.customer_id !== "0" && !accountData.name)
      setAccountData({ name: resultAPI.data.customer_id });
  }
  if (resultAPICart.status === "success") {
    if (cartValue !== parseInt(resultAPICart.data.cart_items))
      setCartValue(parseInt(resultAPICart.data.cart_items));
  }
  if (resultAPI.status === "success") {
    return (
      <ThemeChooser
        props={{ addToCart: addToCart, resultAPI: resultAPI }}
        name="home"
      />
    );
  } else
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
};

export default Store;

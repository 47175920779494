import React, { useEffect, useState } from "react";
import Reviews from "../../../../globalComponents/Reviews";
import { ItemInFocus } from "../../components/ItemInFocus/ItemInFocus";
import Marquee from "../../components/Marquee/Marquee";
import Slider from "../../components/Slider/Slider";
import "../../global.css";
import { CategoryListSlider } from "../../widgets/CategoryListSlider/CategoryListSlider";
import { InfoBand } from "../../widgets/InfoBand/InfoBand";
import { ProductListSlider } from "../../widgets/ProductListSlider/ProductListSlider";

const Home = ({ resultAPI, addToCart, storeNameLink, storeName }) => {
  const [randomProductIndicies, setRandomProductIndicies] = useState([]);

  const randomProductIndex = (productList) => {
    if (productList.length)
      return Math.floor(Math.random() * productList.length);
    return 0;
  };
  const generateRandomProductIndicies = (data) => {
    if (data) {
      const temp = [];
      data.map((item, index) => {
        temp.push(randomProductIndex(item.products));
        return 0;
      });
      return temp;
    }
  };

  useEffect(() => {
    //setting theme color

    if (resultAPI?.data?.theme?.color)
      document.documentElement.style.setProperty(
        "--theme-primary-color",
        resultAPI.data.theme.color
      );
    if (resultAPI?.data?.all_category) {
      setRandomProductIndicies(
        generateRandomProductIndicies(resultAPI.data.all_category)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAPI]);

  return resultAPI?.data ? (
    <div>
      <Slider
        images={resultAPI?.data?.banner}
        callSign="image"
        dark
        storeNameLink={storeNameLink}
      />

      {resultAPI.data.marqueeStatus === "1" && resultAPI.data.marquee ? (
        <Marquee text={resultAPI.data.marquee} />
      ) : null}
      {resultAPI.data.all_catalog?.length > 0 && (
        <CategoryListSlider
          forCatalog
          title="Featured Catalog"
          data={resultAPI.data.all_catalog}
          storeNameLink={`${storeNameLink}/catalog`}
          dataKey="biz_shop_catalog_id"
        />
      )}
      {randomProductIndicies.length > 0 && (
        <>
          <ProductListSlider
            data={resultAPI.data.all_category[0].products}
            category={resultAPI.data.all_category[0].name}
            categoryId={resultAPI.data.all_category[0].biz_shop_category_id}
            storeNameLink={storeNameLink}
            addToCart={addToCart}
            totalCount={resultAPI.data.all_category[0].total_products_count}
          />
          {resultAPI.data.all_category[0].products[randomProductIndicies[0]] ? (
            <ItemInFocus
              description=""
              link={`/${storeNameLink}/product/${
                resultAPI.data.all_category[0].products[
                  randomProductIndicies[0]
                ].product_data.product_id
              }`}
              className="my-5 py-5"
              image={
                resultAPI.data.all_category[0].products[
                  randomProductIndicies[0]
                ].product_data.image
              }
              detailsOnLeft
              name={
                resultAPI.data.all_category[0].products[
                  randomProductIndicies[0]
                ].product_data.productname
              }
              price={
                resultAPI.data.all_category[0].products[
                  randomProductIndicies[0]
                ].product_data.price
              }
              special={
                resultAPI.data.all_category[0].products[
                  randomProductIndicies[0]
                ].product_data.special !== "0"
                  ? resultAPI.data.all_category[0].products[
                      randomProductIndicies[0]
                    ].product_data.special
                  : undefined
              }
            />
          ) : (
            <></>
          )}
        </>
      )}

      {resultAPI.data.all_category?.length > 0 && (
        <CategoryListSlider
          forCatalog
          title="Shop By Categories"
          data={resultAPI.data.all_category}
          storeNameLink={`${storeNameLink}/category`}
          dataKey="biz_shop_category_id"
        />
      )}
      {randomProductIndicies.length > 0 &&
        resultAPI.data.all_category?.map((item, index) => {
          if (index !== 0 && item.products.length > 0)
            return (
              <div key={index + "productslider"}>
                <ProductListSlider
                  category={item.name}
                  data={item.products}
                  storeNameLink={storeNameLink}
                  addToCart={addToCart}
                  categoryId={item.biz_shop_category_id}
                  totalCount={item.total_products_count}
                />
                {item.products.length > 5 && (
                  <ItemInFocus
                    link={`/${storeNameLink}/product/${
                      item.products[randomProductIndicies[index]].product_data
                        .product_id
                    }`}
                    description=""
                    className="my-5 py-5"
                    image={
                      item.products[randomProductIndicies[index]].product_data
                        .image
                    }
                    detailsOnLeft={index % 2 === 0}
                    name={
                      item.products[randomProductIndicies[index]].product_data
                        .productname
                    }
                    price={
                      item.products[randomProductIndicies[index]].product_data
                        .price
                    }
                    special={
                      item.products[randomProductIndicies[index]].product_data
                        .special !== "0"
                        ? item.products[randomProductIndicies[index]]
                            .product_data.special
                        : undefined
                    }
                  />
                )}
              </div>
            );
        })}
      {resultAPI?.data?.reviews ? (
        <Reviews data={resultAPI.data.reviews} />
      ) : null}
      <InfoBand />
    </div>
  ) : (
    <></>
  );
};

export default Home;

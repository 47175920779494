import React from "react";
import { Row, Col } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";

const Searchbar = ({
  placeholder,
  searchValue,
  setSearchValue,
  onButtonHit,
}) => (
  <Row className="search-container">
    <Col xl={12} xs={12} className="search-bar">
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <button
        className="search-button"
        onClick={() => {
          onButtonHit();
        }}
      >
        <SearchIcon />
      </button>
    </Col>
  </Row>
);

export default Searchbar;

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import React from "react";
import { Carousel } from "react-bootstrap";
import Button from "../Button/Button";
import styles from "./styles.module.css";

const Slider = ({
  images,
  callSign,
  dark,
  storeNameLink,
  centerTitle,
  lessHeight,
}) => {
  return (
    <Carousel controls={false} interval={3000}>
      {images?.map((image, index) => {
        const Body = () => {
          return (
            <div
              className={
                lessHeight
                  ? styles.imageContainerLessHeight
                  : styles.imageContainer
              }
            >
              {dark && <div className={styles.dark}></div>}
              <div className={styles.textContainer}>
                <div className={styles.textInsideContainer}>
                  {centerTitle && (
                    <h1
                      className={`${styles.text} ${styles.textH1} text-center`}
                    >
                      {centerTitle}
                    </h1>
                  )}
                  {image?.heading && (
                    <h1 className={`${styles.text} ${styles.textH1}`}>
                      {image.heading || " this is a test"}
                    </h1>
                  )}
                  {image?.sub_heading && (
                    <h2 className={`${styles.text} ${styles.textH2}`}>
                      {image.sub_heading || "this is test subheading"}
                    </h2>
                  )}
                  {image?.button_text && (
                    <div className={styles.buttonContainer}>
                      <Button
                        text={image.button_text || "Click Me"}
                        Icon={ArrowRightAltIcon}
                        link={
                          image.link_type &&
                          `/${storeNameLink}/${image.link_type}/${image.link_id}`
                        }
                        animated
                      />
                    </div>
                  )}
                </div>
              </div>

              <img
                className={`d-block w-100 img-responsive ${styles.image}`}
                src={callSign ? image[callSign] : image}
                alt="First slide"
              />
            </div>
          );
        };

        return (
          <Carousel.Item key={index}>
            {image.button_text ? (
              <Body />
            ) : (
              <a
                href={
                  callSign && image?.link_type && image.link_type !== "none"
                    ? `/${storeNameLink}/${image.link_type}/${image.link_id}`
                    : "#"
                }
              >
                <Body />
              </a>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};
export default Slider;

import { ErrorMessage } from "@hookform/error-message";
import { LinearProgress, TextField } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import scrollToComponent from "react-scroll-to-component";
import APIHook from "../helper/APIHook";
import markerImage from "../resources/marker.png";
import GoogleMapComponentWithMarker from "./GoogleMapWithMarker";

const scrollToRef = (ref) => {
  scrollToComponent(ref.current, {
    offset: 1000,
    align: "top",
    duration: 500,
  });
};

const Checkout = ({ ...props }) => {
  console.log(props);
  var otp = "";
  var phone = "";
  //checking for otp
  const history = useHistory();
  if (props.location.state === undefined) {
    //redirect to login and then redirect back
    history.push({
      pathname: "/moradabadballon/login",
    });
  } else {
    otp = props.location.state.otp;
    phone = props.location.state.phone;
  }
  //if not otp redirect to login
  const locationlink = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const [infoboxMessage, setInfoBoxMessage] = useState("");
  const [isInfoboxVisible, setIsInfoboxVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zoneID, setZoneID] = useState("1475");
  const [markerLang, setMarkerLang] = useState(0);
  const [markerLat, setMarkerLat] = useState(0);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [draggedLocation, setDraggedLocation] = useState("0, 0");
  const [nearbybazarAPIRegister, resultAPIRegister] = APIHook();

  const seePosition = (position) => {
    //console.log("chal raha hu");
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      console.log(navigator.geolocation);
      navigator.geolocation.getCurrentPosition(seePosition);
    } else {
      alert("Geolocation is not supported by this browser");
    }
  }, []);
  const getMapLocation = () => {
    if (draggedLocation === "0, 0")
      return location.latitude + ", " + location.longitude;
    else return draggedLocation;
  };
  const registerCustomer = (dataFromForm) => {
    const data = {
      link: storeName,
      phone: phone,
      otp: otp,
      register: "1",
      name: dataFromForm.name,
      address: dataFromForm.address,
      city: dataFromForm.city,
      postcode: dataFromForm.pincode,
      zone_id: zoneID,
      country_id: "99",
      location: getMapLocation(),
    };

    console.log("SENDING API REQUEST WITH DATA ", data);
    nearbybazarAPIRegister(data, "/front/logincustomer/");
    setLoading(true);
  };

  if (resultAPIRegister.status === "success") {
    history.push({
      pathname: "/" + storeNameLink + "/orderconfirm",
      state: {
        data: resultAPIRegister.data,
      },
    });
  }
  if (resultAPIRegister.status === "error") {
    alert(resultAPIRegister.message);
    history.push({
      pathname: "/" + storeNameLink + "/login",
    });
  }

  const defaultValues = {
    name: "",
    address: "",
    city: "",
    pincode: "",
  };

  // Some default styles
  const styles = {
    width: "100%",
    height:
      window.innerWidth > 600 ? window.innerHeight : window.innerHeight / 2,
  };

  const { handleSubmit, control, errors } = useForm({
    defaultValues,
  });

  const submitForm = (data) => {
    registerCustomer(data);
    /* history.push({
      pathname: "/moradabadballon/orderconfirm",
      state: {
        data: data,
      },
    });*/
  };
  const handleMarkerClick = (message, lang, lat) => {
    setInfoBoxMessage(message);
    setIsInfoboxVisible((prevState) => !prevState);
    setMarkerLang(lang + 0.006);
    setMarkerLat(lat - 0.004);
  };

  const handleInfoboxClick = () => {
    setIsInfoboxVisible(false);
  };
  const handleLocationChange = (lat, lng) => {
    setLocation({
      latitude: 0,
      longitude: 0,
    });
    setLocation({
      latitude: lat,
      longitude: lng,
    });
  };
  const handleCenterChange = (lat, lng) => {
    setDraggedLocation(lat + ", " + lng);
  };
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  console.log("zone id", zoneID);
  if (loading) {
    return <LinearProgress color="secondary" />;
  } else
    return (
      <Row>
        <Col xl={8} xs={12}>
          <div style={styles}>
            <div
              style={{
                position: "absolute",
                zIndex: 1000,
                left: "50%",
                top: "50%",
              }}
            >
              <img
                src={markerImage}
                alt="marker"
                style={{ height: 32, position: "absolute", zIndex: 2 }}
              />
              <div
                style={{
                  position: "absolute",
                  width: 100,
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  right: -60,
                  top: -20,
                  zIndex: 1,
                }}
              >
                <div
                  className="marker-ring-animation"
                  style={{
                    borderRadius: 50,
                    zIndex: 1000,
                  }}
                ></div>
              </div>
            </div>
            {location.latitude !== 0 && location.longitude !== 0 ? (
              <GoogleMapComponentWithMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAjwtShwA7Adj10OnV0a9Cirl1nnnvqnJ8"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                isInfoboxVisible={isInfoboxVisible} // Show/hide info window
                infoboxMessage={infoboxMessage} // Message shown in info window
                handleInfoboxClick={handleInfoboxClick} // Handle closing of the info window
                handleMarkerClick={handleMarkerClick} // Handle click on Marker component
                infoboxPosY={markerLang} // Y coordinate for positioning info window
                infoboxPosX={markerLat} // X coordinate for positioning info window
                userLocation={location}
                onLocationChange={handleLocationChange}
                onCenterChange={handleCenterChange}
              />
            ) : null}
          </div>
        </Col>
        <Col xl={4} xs={12} className="addressFormContainer">
          <form
            onSubmit={handleSubmit((data) => submitForm(data))}
            className="addressForm"
          >
            <Row>
              <Col xs={12} lg={12}>
                <div className="flexColumn" style={{ paddingRight: 10 }}>
                  <h2 style={{ marginTop: "10px", marginLeft: "8px" }}>
                    Save delivery location
                  </h2>
                  <Controller
                    as={<TextField onFocus={executeScroll} />}
                    name="name"
                    label="Name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    className="checkout-field-form"
                  />
                  <p className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      message="Name is Required to Proceed"
                    />
                  </p>
                  <Controller
                    as={<TextField />}
                    name="address"
                    label="Address"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    className="checkout-field-form"
                  />
                  <p className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name="address"
                      message="Address is Required to Proceed"
                    />
                  </p>

                  <Controller
                    as={<TextField />}
                    name="city"
                    label="City"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    className="checkout-field-form"
                  />
                  <p className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name="city"
                      message="City is Required to Proceed"
                    />
                  </p>
                  <Controller
                    as={<TextField />}
                    name="pincode"
                    label="Pincode"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    className="checkout-field-form"
                  />
                  <p className="error-message">
                    {" "}
                    <ErrorMessage
                      errors={errors}
                      name="pincode"
                      message="Pincode is Required to Proceed"
                    />
                  </p>
                  <select
                    className="form-control"
                    name="zoneID"
                    label="State"
                    onChange={(event) => {
                      setZoneID(event.target.value);
                    }}
                    style={{
                      fontSize: "1.8rem",
                      marginBottom: 20,
                      marginTop: 20,
                      outline: "none",
                      border: 0,
                      borderColor: "#fff",
                      boxShadow: "0px 0px 0px #ccc",
                      borderBottom: "1px solid #777",
                    }}
                  >
                    <option value="1475">Andaman and Nicobar Islands</option>
                    <option value="1476">Andhra Pradesh</option>
                    <option value="1477">Arunachal Pradesh</option>
                    <option value="1478">Assam</option>
                    <option value="1479">Bihar</option>
                    <option value="1480">Chandigarh</option>
                    <option value="1481">Dadra and Nagar Haveli</option>
                    <option value="1482">Daman and Diu</option>
                    <option value="1483">Delhi</option>
                    <option value="1484">Goa</option>
                    <option value="1485">Gujarat</option>
                    <option value="1486">Haryana</option>
                    <option value="1487">Himachal Pradesh</option>
                    <option value="1488">Jammu and Kashmir</option>
                    <option value="1489">Karnataka</option>
                    <option value="1490">Kerala</option>
                    <option value="1491">Lakshadweep Islands</option>
                    <option value="1492">Madhya Pradesh</option>
                    <option value="1493">Maharashtra</option>
                    <option value="1494">Manipur</option>
                    <option value="1495">Meghalaya</option>
                    <option value="1496">Mizoram</option>
                    <option value="1497">Nagaland</option>
                    <option value="1498">Orissa</option>
                    <option value="1499">Puducherry</option>
                    <option value="1500">Punjab</option>
                    <option value="1501">Rajasthan</option>
                    <option value="1502">Sikkim</option>
                    <option value="1503">Tamil Nadu</option>
                    <option value="1504">Tripura</option>
                    <option value="1505">Uttar Pradesh </option>
                    <option value="1506">West Bengal</option>
                    <option value="4231">Telangana</option>
                  </select>
                  <div className="checkout-save-address-button-container">
                    <Button
                      variant="primary"
                      type="submit"
                      ref={myRef}
                      className="checkout-save-address-button"
                    >
                      Proceed To Checkout
                    </Button>
                  </div>
                </div>
              </Col>{" "}
            </Row>
          </form>
          <div className="margin-bottom-150px"></div>
        </Col>
      </Row>
    );
};
export default Checkout;

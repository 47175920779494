import React from "react";
import styles from "./style.module.scss";

export const BlankSpace = ({ text, className, rounded, height = 600 }) => {
  return (
    <div
      className={`${styles.root} ${className} ${rounded ? styles.rounded : ""}`}
      style={{ height: height }}
    >
      <div>{text}</div>
    </div>
  );
};

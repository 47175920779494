import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  page: {
    backgroundColor: "#fff",
  },
  pageContainer: {
    padding: 20,
  },
  section: {
    marginHorizontal: 10,
    marginTop: 10,
  },
  pageTitle: {
    fontWeight: "bold",
    fontSize: 40,
    textAlign: "center",
    color: "#888",
  },
  viewer: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  heading: {
    fontSize: 10,
    color: "blue",
    fontWeight: "bold",
    letterSpacing: 1.5,
    fontFamily: "Helvetica-Bold",
  },
  heading2: {
    fontSize: 10,
    color: "black",
    fontWeight: "bold",
    letterSpacing: 1.5,
  },
  storeName: {
    fontSize: 14,
    color: "black",
    marginTop: 10,
    marginBottom: 5,
  },
  address: {
    fontSize: 8,
    color: "black",
    lineHeight: 1.2,
    flexWrap: "wrap",
    overflowWrap: "break-word",
    maxWidth: 200,
  },
  gst: {
    fontSize: 8,
    color: "black",
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  link: {
    fontSize: 8,
    lineHeight: 1.2,
    color: "black",
    fontStyle: "underline",
  },
  invoice: {
    fontSize: 10,
    color: "black",
    marginVertical: 10,
  },
  invoiceDate: {
    fontSize: 10,
    color: "black",
    marginVertical: 10,
    fontFamily: "Helvetica-Bold",
  },
  normalText: {
    fontSize: 10,
    color: "black",
  },
  boldText: {
    fontSize: 10,
    color: "black",
    fontWeight: "bold",
    marginTop: 4,
    marginBottom: 4,
    fontFamily: "Helvetica-Bold",
  },
  storeLogo: {
    height: 64,
    width: "auto",
    objectFit: "contain",
    objectPositionX: "right",
    marginTop: 10,
  },
  rsLogo: {
    height: 8,
    width: 8,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: "#031e45",
    alignItems: "center",
    paddingBottom: 5,
    paddingTop: 5,
    marginBottom: 5,
    marginTop: 20,
    backgroundColor: "#bcd3f522",
    paddingHorizontal: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingBottom: 5,
    marginBottom: 5,
    paddingHorizontal: 5,
  },
  tableHeaderCell: {
    fontSize: 10,
    fontWeight: "bold",
    flex: 1,
    textAlign: "left",
  },
  tableHeaderCellTextRight: {
    fontSize: 10,
    fontWeight: "bold",
    flex: 1,
    textAlign: "right",
  },
  tableCell: {
    fontSize: 10,
    flex: 1,
    textAlign: "left",
  },
  tableCellTextCenter: {
    fontSize: 8,
    flex: 1,
    textAlign: "center",
  },
  tableRowSubtotal: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 2,
    paddingHorizontal: 5,
  },
  tableCellTextRight: {
    fontSize: 10,
    flex: 1,
    textAlign: "right",
  },
  textRight: {
    fontSize: 10,
    textAlign: "right",
  },
  productName: {
    fontSize: 10,
    textAlign: "left",
    marginBottom: 3,
    fontFamily: "Helvetica-Bold",
    textTransform: "capitalize",
  },
  productOption: {
    fontSize: 8,
    textAlign: "left",
  },
  productOptionTitle: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  subtotals: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  totalCell: {
    fontSize: 12,
    marginTop: 4,
    paddingTop: 4,
    borderTopWidth: 1,
    fontFamily: "Helvetica-Bold",
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop40: {
    marginTop: 40,
  },
  signAndStamp: {
    color: "#ccc",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  watermarkContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.05,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  watermarkImage: {
    height: 200,
    width: "auto",
    objectFit: "contain",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  lobechoBranding: {
    position: "absolute",
    backgroundColor: "#190b45",
    color: "white",
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    padding: 5,
    width: "100%",
    bottom: 0,
    right: 0,
    left: 0,
  },
});

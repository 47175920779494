import {
  Document,
  Font,
  PDFViewer,
  Page,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { CustomerDetails } from "../components/InvoiceComponents/CustomerDetails";
import { InvoiceFooter } from "../components/InvoiceComponents/InvoiceFooter";
import { LobechoBranding } from "../components/InvoiceComponents/LobechoBranding";
import { ProductDetails } from "../components/InvoiceComponents/ProductDetails";
import { StoreDetails } from "../components/InvoiceComponents/StoreDetails";
import { TaxDetails } from "../components/InvoiceComponents/TaxDetails";
import { Watermark } from "../components/InvoiceComponents/Watermark";
import { styles } from "../components/InvoiceComponents/styles";
import APIHook from "../helper/APIHook";
import Button from "../themes/vaquita/components/Button/Button";

Font.register({
  family: "Helvetica",
  fonts: [
    { src: "Helvetica" }, // font-style: normal, font-weight: normal
    { src: "Helvetica-Bold", fontWeight: "bold" },
  ],
});

const InvoiceDoc = ({ invoiceData }) => {
  const isGstAllowed = invoiceData?.isGstAllowed === "1";

  return invoiceData?.shopLogo ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageContainer}>
          <Watermark
            url={`https://www.nearbybazar.com/favicon/image.php?src=${invoiceData?.shopLogo}`}
          />
          <StoreDetails isGstAllowed={isGstAllowed} invoiceData={invoiceData} />
          <CustomerDetails invoiceData={invoiceData} />
          <ProductDetails
            isGstAllowed={isGstAllowed}
            invoiceData={invoiceData}
          />
          {isGstAllowed ? <TaxDetails invoiceData={invoiceData} /> : null}
          <InvoiceFooter invoiceData={invoiceData} />
        </View>
        {invoiceData?.shopPremium === "1" ? <LobechoBranding /> : null}
      </Page>
    </Document>
  ) : (
    <h3>Loading....</h3>
  );
};

const Invoice = ({ ...props }) => {
  const [nearbybazarAPI, resultAPI] = APIHook();
  const [invoiceData, setInvoiceData] = useState({});

  const generatePdfDocument = async (data, fileName) => {
    const blob = await pdf(<InvoiceDoc invoiceData={data} />).toBlob();
    saveAs(blob, fileName);
    //window.close();
  };
  useEffect(() => {
    const apiCall = async () => {
      const hostDomain = require("../resources/hostdomain.json");
      const orderIdEncrypted = window.location.pathname.split("/")[3];
      if (orderIdEncrypted) {
        var storeName = window.location.pathname.split("/")[1];
        const domain = window.location.hostname;
        if (domain !== hostDomain.domain) {
          storeName = domain;
        }
        const data = {
          link: storeName,
          order_id_encrypted: orderIdEncrypted,
        };
        const response = await nearbybazarAPI(data, "/front/invoice/");
        if (response.status === "success") {
          setInvoiceData(response.data);
          // const random = Math.random() * 100000;
          // generatePdfDocument(
          //   response.data,
          //   `invoice-${response.data.orderProduct[0]?.order_id}-${parseInt(
          //     random
          //   )}.pdf`
          // );
        }
      }
    };
    apiCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!invoiceData?.shopLogo)
    return (
      <h1>
        <center>
          {" "}
          <br />
          <br />
          Preparing Invoice....
        </center>
      </h1>
    );
  return window?.innerWidth > 500 ? (
    <PDFViewer style={styles.viewer}>
      <InvoiceDoc invoiceData={invoiceData} />
    </PDFViewer>
  ) : (
    <h1>
      <br />
      <br />
      <center>
        <div>
          <a href={invoiceData.shopWebsite}>
            <img src={invoiceData.shopLogo} style={styles.storeLogo} />
          </a>
        </div>
        Your Invoice is Ready to Download
        <br />
        <br />
        <Button
          onClick={() => {
            const random = Math.random() * 100000;
            generatePdfDocument(
              invoiceData,
              `invoice-${invoiceData.orderProduct[0]?.order_id}-${parseInt(
                random
              )}.pdf`
            );
          }}
          text="Download Now"
        />
      </center>
    </h1>
  );
};

export default Invoice;

import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { accountContext } from "../../../../helper/AccountContext";
import styles from "./styles.module.css";

const MyAccountButton = ({ onClick = () => {}, className, storeNameLink }) => {
  const [accountData] = useContext(accountContext);

  return (
    <Link
      to={{
        pathname: `/${storeNameLink}/${
          accountData?.name ? "account" : "login"
        }`,
      }}
    >
      <div
        className={`d-flex justify-content-center align-items-center cursor-pointer ${className}`}
        onClick={onClick}
      >
        <PersonOutlineIcon className={styles.icon} />
        <div className={styles.textContainer}>
          <div className={styles.title}>My Account</div>
          <div className={styles.subTitle}>
            {accountData?.name ? "View Orders" : "Log in"}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MyAccountButton;

import { Text } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const LobechoBranding = () => {
  return (
    <Text style={[styles.tableCellTextCenter, styles.lobechoBranding]}>
      This Invoice is generated for Free on LoBecho
    </Text>
  );
};

import React from "react";
import styles from "./style.module.scss";

export const PoweredBy = ({ className }) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className=" col-sm-6 col-lg-5 col-md-6 col-10 ml-auto mr-auto">
        <div className="col-10 mx-auto">
          <img
            src="https://www.nearbybazar.com/biz_images/lobecho_logo/lobecho_color.svg"
            alt="lobecho-logo"
          />
        </div>

        <div className={`${styles.subText} text-center mt-3 text-secondary`}>
          Create your own E-Commerce Website for Free
        </div>
      </div>
    </div>
  );
};

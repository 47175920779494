import React from "react";
import styles from "./styles.module.css";
const Button = ({
  text,
  link,
  Icon,
  iconSM,
  animated,
  sm,
  md,
  disabled,
  className,
  onClick = () => {},
}) => {
  const ButtonBody = () => {
    return (
      <div
        className={`${
          animated ? styles.buttonContainerAnimated : styles.buttonContainer
        }
        ${sm ? styles.buttonSM : ""}
        ${md ? styles.buttonMD : ""}
        ${className ? className : ""}
        ${disabled ? styles.disabled : ""}
        `}
        onClick={onClick}
      >
        <div
          className={animated ? styles.buttonTextAnimated : styles.buttonText}
        >
          {text}
        </div>
        {Icon && (
          <div
            className={
              animated
                ? styles.buttonIconContainerAnimated
                : styles.buttonIconContainer
            }
          >
            <Icon
              className={iconSM ? styles.buttonIconSM : styles.buttonIcon}
            />
          </div>
        )}
      </div>
    );
  };
  return link ? (
    <a href={link}>
      <ButtonBody />
    </a>
  ) : (
    <ButtonBody />
  );
};
export default Button;

import React from "react";
import { Link } from "react-router-dom";
export const slicedDataElements = (data, batchSize, storeNameLink, styles) => {
  const batches = Math.ceil(data.length / batchSize);

  const result = [];

  for (let i = 0; i < batches; i++) {
    const start = i * batchSize;
    const end = Math.min(start + batchSize, data.length);

    const batchItems = data.slice(start, end);

    const batchElements = batchItems.map((item, index) => (
      <Link
        to={{
          pathname: `/${storeNameLink}/category/${item?.biz_shop_category_id}`,
        }}
        key={index}
      >
        <div className={styles.menuListItem} key={index + start}>
          {item.name}
        </div>
      </Link>
    ));

    result.push(
      <div className={styles.menuListItemContainer} key={i}>
        {batchElements}
      </div>
    );
  }

  return (
    <>
      {result.map((item, index) => {
        return item;
      })}
    </>
  );
};

import CategoryIcon from "@material-ui/icons/Category";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { cartContext } from "../../../helper/cartContext";

const Navbar = ({ storeName }) => {
  const [cartValue, setCartValue] = useContext(cartContext);

  return (
    <div className="navbar">
      <Link to={`/${storeName}`}>
        <HomeIcon className="router-icon" />
        <div className="cartText">HOME</div>
      </Link>

      <Link to={`/${storeName}/categories`}>
        <CategoryIcon className="router-icon" />
        <div className="cartText">CATEGORY</div>
      </Link>

      <Link to={`/${storeName}/cart`}>
        <ShoppingCartIcon className="router-icon" />
        {cartValue > 0 ? <div className="cartCircle">{cartValue}</div> : null}
        <div className="cartText">CART</div>
      </Link>

      <Link to={`/${storeName}/account`}>
        <PersonIcon className="router-icon" />
        <div className="cartText">ACCOUNT</div>
      </Link>
    </div>
  );
};

export default Navbar;

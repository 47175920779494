import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const MainSite = () => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    const script2 = document.createElement("script");
    script2.src =
      "https://www.nearbybazar.com/lobecho/website/js/owl.carousel.min.js";
    script2.async = true;
    document.body.appendChild(script2);
    const script3 = document.createElement("script");
    script3.src = "https://www.nearbybazar.com/lobecho/website/js/script.js";
    script3.async = true;
    document.body.appendChild(script3);

    const script4 = document.createElement("script");
    script4.src =
      "https://www.nearbybazar.com/lobecho/website/js/bootstrap.bundle.min.js";
    script4.async = true;
    document.body.appendChild(script4);

    const css3 = document.createElement("link");
    css3.href =
      "https://www.nearbybazar.com/lobecho/website/css/themify-icons1.css";
    css3.rel = "stylesheet";
    document.body.appendChild(css3);
    var allstyle = document.getElementsByTagName("style");
    for (var i = 0; i < allstyle.length; i++) {
      var first = document.getElementsByTagName("style")[i];
      first.parentElement.removeChild(first);
    }
    allstyle = document.getElementsByTagName("link");

    for (var i = 0; i < allstyle.length; i++) {
      first = document.getElementsByTagName("link")[i];

      if (first.href.includes("chunk")) {
        console.log(first.href);
        first.remove();
      }
    }
    allstyle = document.getElementsByTagName("link");

    for (var i = 0; i < allstyle.length; i++) {
      first = document.getElementsByTagName("link")[i];

      if (first.href.includes("chunk")) {
        console.log(first.href);
        first.remove();
      }
    }
    setTimeout(() => {
      setDisplay(true);
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {!display ? (
        <div className="fixed-top">
          <LinearProgress color="secondary" />{" "}
        </div>
      ) : null}
      <div
        className="website-container"
        itemscope
        itemtype="http://schema.org/SoftwareApplication"
        style={{ visibility: !display ? "hidden" : "initial" }}
      >
        <title>LoBecho - Create Your Own Online Store in Seconds</title>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="Now with LoBecho, it is very easy to earn more money by building your own store online. This App will help you in growing your business to many folds. With LoBecho anybody can create an online store and start selling in few seconds. With Cash on delivery and online payment options it is very easy to take payments from customer using payment gateway. Plus point is that there is No Commission on sale. Its totally free with LoBecho to start and grow your business online."
        />
        <meta
          name="keywords"
          content="lo becho, lo becho online, lobecho india, how to sell online, lobecho,online store, start my online store, how to start my online store,make your store online, how to take my shop online, how to sell online, build your online dukaan in 30 seconds, create my online store, free online shop, how to make a free online shop, create an online store for free,create an online store for free in india,mystore,dukaan app,mystore app"
        />
        {/* Font */}

        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
        <link
          href="https://fonts.googleapis.com/css?family=Rubik:300,400,500"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://www.nearbybazar.com/lobecho/website/css/owl.carousel.min.css"
        />
        <link
          rel="stylesheet"
          href="https://www.nearbybazar.com/lobecho/website/css/bootstrap.min.css"
        />
        {/* Themify Icons */}
        <link
          rel="stylesheet"
          href="https://www.nearbybazar.com/lobecho/website/css/style1.css"
        />

        {/* Nav Menu */}
        <div className="nav-menu fixed-top">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav className="navbar navbar-dark navbar-expand-lg">
                  <a className="navbar-brand" href="index.html">
                    <img
                      itemprop="image"
                      src="https://ik.imagekit.io/imagesdata/biz_images/LoBecho-01_2-min__1___1_.png?updatedAt=1687705888738"
                      className="img-fluid img-responsive"
                      alt="lobecho-logo"
                      style={{ height: "6vh" }}
                    />
                  </a>{" "}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-controls="navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    {" "}
                    <span className="navbar-toggler-icon" />{" "}
                  </button>
                  <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link active" href="#home">
                          HOME <span className="sr-only">(current)</span>
                        </a>{" "}
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="#features">
                          FEATURES
                        </a>{" "}
                      </li>
                      {/* <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="#gallery">
                        GALLERY
                      </a>{" "}
  </li>*/}
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="#pricing">
                          PRICING
                        </a>{" "}
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="https://web.lobecho.com">
                          LOBECHO WEB
                        </a>{" "}
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="#contact">
                          CONTACT
                        </a>{" "}
                      </li>
                      <li className="nav-item">
                        <a
                          itemprop="downloadUrl"
                          href="https://play.google.com/store/apps/details?id=com.nearbybazarbusiness"
                          className="btn btn-outline-light my-3 my-sm-0 ml-lg-3"
                        >
                          Download
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <header className="bg-gradient" id="home">
          <div className="container mt-5">
            <h1>Create Your Own Online Store in Seconds</h1>
            <p className="tagline">
              There is No Commission on sale. Take Online Payments directly in
              your bank account using{" "}
              <span itemprop="name">Payment Gateway</span>{" "}
            </p>
          </div>
          <div className="img-holder mt-3">
            <img
              itemprop="screenshot"
              src="https://ik.imagekit.io/imagesdata/biz_images/Untitled_design__8___3_.png?updatedAt=1687708326304"
              alt="phone"
              className="img-fluid"
            />
          </div>
        </header>
        {/* <div className="client-logos my-5">
        <div className="container text-center">
          <img
            src="images/client-logos.png"
            alt="client logos"
            className="img-fluid"
          />
  </div>
      </div>*/}
        <div className="section light-bg" id="features">
          <div className="container">
            <div className="section-title">
              <small>HIGHLIGHTS</small>
              <h3>Features you love</h3>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="card features">
                  <div className="card-body">
                    <div className="media">
                      <span className="ti-face-smile gradient-fill ti-3x mr-3" />
                      <div className="media-body">
                        <h4 className="card-title">No Commission</h4>
                        <p className="card-text">
                          There is no commission on sale either you sale for
                          ₹100 or for ₹100K its 100% Yours. Its Free to use
                          LoBecho with limited functionality, but for all
                          features you may consider buying our Plans.{" "}
                          <div style={{ color: "#cdcdcd" }}>
                            (Its Free to start)
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card features">
                  <div className="card-body">
                    <div className="media">
                      <span className="ti-settings gradient-fill ti-3x mr-3" />
                      <div className="media-body">
                        <h4 className="card-title">Online Payments</h4>
                        <p className="card-text">
                          You can let the customer pay you by any mode of online
                          payment. Net Banking, Credit Cards, Debit Cards, UPI
                          or wallets. You will get money directly in your bank
                          account. <br></br>
                          <span style={{ color: "#cdcdcd" }}>
                            (Subject to Payment Gateway Integration)
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card features">
                  <div className="card-body">
                    <div className="media">
                      <span className="ti-lock gradient-fill ti-3x mr-3" />
                      <div className="media-body">
                        <h4 className="card-title">Unlimited Catalogue</h4>
                        <p className="card-text">
                          You can create any number of products and categories,
                          with multiple product images upto a total of 10. Its
                          your online store, so we keep it fast, simple and
                          reliable for you.{" "}
                          <div style={{ color: "#cdcdcd" }}>
                            (Available in Free Plan)
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // end .section */}
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <div className="box-icon">
                  <span className="ti-mobile gradient-fill ti-3x" />
                </div>
                <h2>Discover our App</h2>
                <p className="mb-4">
                  You can start building your online store in few clicks by just
                  downloading our App. Currently available for android, But soon
                  launching for iOS.{" "}
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.nearbybazarbusiness"
                  className="btn btn-primary"
                >
                  Download Now
                </a>
              </div>
            </div>
            <div className="perspective-phone">
              <img
                src="https://www.nearbybazar.com/lobecho/lobecho-perspective.webp"
                alt="perspective phone"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/* // end .section */}
        <div className="section light-bg">
          <div className="container">
            <div className="section-title">
              <small>FEATURES</small>
              <h3>How LoBecho is a one stop solution?</h3>
            </div>
            <ul className="nav nav-tabs nav-justified" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#communication"
                >
                  Multiple Stores
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#schedule">
                  Online Payments
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#livechat">
                  Full Control
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#training">
                  Training and development
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="communication">
                <div className="d-flex flex-column flex-lg-row">
                  {/*<img
                  src="images/graphic.png"
                  alt="graphic"
                  className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"
                />*/}
                  <div>
                    <h2>Multiple Stores</h2>
                    <p className="lead">
                      You can now create and manage multiple stores in one
                      account with separation of orders and product Catalogue to
                      cater more then one type of customers.{" "}
                    </p>
                    <p>
                      It is now very easy to create and manage multiple stores
                      online with LoBecho Mobile App, You can create as many
                      stores as you want and can create multiple products and
                      categories depending on that store.
                    </p>
                    <p>
                      {" "}
                      Also, You can create coupons, manage delivery executives,
                      assign them to orders, read and reply to reviews in your
                      dedicated store. All this in one single App.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="schedule">
                <div className="d-flex flex-column flex-lg-row">
                  <div>
                    <h2>Online Payments</h2>
                    <p className="lead">
                      You can take online payments or give customer an option to
                      go with cash on delivery the choice is totally yours.
                    </p>
                    <p>
                      In online payments, You can accept payments from Credit
                      Card, Debit Card, Net Banking, Bhim UPI, Google Pay, Phone
                      Pay, Paytm, other wallets.(We will help you to create your
                      account with the Payment Gateway Company). All this money
                      will be credited to your Bank Account by the payment
                      gateway directly.
                    </p>
                    <p>
                      We don't charge any money for online payments but the
                      payment gateway company can charge you around (~2% + GST)
                    </p>
                  </div>
                  {/* <img
                  src="images/graphic.png"
                  alt="graphic"
                  className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"
                />*/}
                </div>
              </div>
              <div className="tab-pane fade" id="messages">
                <div className="d-flex flex-column flex-lg-row">
                  <img
                    src="images/graphic.png"
                    alt="graphic"
                    className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"
                  />
                  <div>
                    <h2>Full Control, We mean it seriously!</h2>
                    <p className="lead">
                      Uniquely underwhelm premium outsourcing with proactive
                      leadership skills.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer rutrum, urna eu pellentesque pretium, nisi nisi
                      fermentum enim, et sagittis dolor nulla vel sapien.
                      Vestibulum sit amet mattis ante. Ut placerat dui eu nulla
                      congue tincidunt ac a nibh. Mauris accumsan pulvinar lorem
                      placerat volutpat. Praesent quis facilisis elit. Sed
                      condimentum neque quis ex porttitor,
                    </p>
                    <p>
                      {" "}
                      malesuada faucibus augue aliquet. Sed elit est, eleifend
                      sed dapibus a, semper a eros. Vestibulum blandit vulputate
                      pharetra. Phasellus lobortis leo a nisl euismod, eu
                      faucibus justo sollicitudin. Mauris consectetur, tortor
                      sed tempor malesuada, sem nunc porta augue, in dictum arcu
                      tortor id turpis. Proin aliquet vulputate aliquam.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="livechat">
                <div className="d-flex flex-column flex-lg-row">
                  <div>
                    <h2>You are your own boss</h2>
                    <p className="lead">Full Control, we mean it seriously!</p>
                    <p>
                      From chatting to your customer to delivery of their orders
                      and getting online payments. you can control your online
                      store like a boss. No ChatBots No Payment Delays.
                      Everything is totally in your control.
                    </p>
                    <p>
                      {" "}
                      We give you option to switch Cash on delivery on/off,
                      online payments on/off, whatsapp chat support on/off to
                      control your online store according to your needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="training">
                <div className="d-flex flex-column flex-lg-row">
                  <div>
                    <h2>We help you accelerate your business online.</h2>
                    <p className="lead">
                      With our groundbreaking training stuff, which is directly
                      available in the app. You can get extensive knowledge
                      which will be enough to grow your business to many folds.
                    </p>
                    <p>
                      Starting from zero, our training stuff will teach you to
                      how to use the app and from there to how to do online
                      marketing which includes facebook marketing, creating you
                      brand online and many more which will keep on increasing
                      every day.
                    </p>
                    <p>
                      {" "}
                      We give you this training material absolutely free. So
                      that you can start with literally zero money and earn a
                      lot very quickly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // end .section 
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="images/dualphone.png"
                alt="dual phone"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <div className="box-icon">
                  <span className="ti-rocket gradient-fill ti-3x" />
                </div>
                <h2>Launch your App</h2>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Obcaecati vel exercitationem eveniet vero maxime ratione{" "}
                </p>
                <a href="#" className="btn btn-primary">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
        {/* // end .section */}
        <div className="section light-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 d-flex align-items-center">
                <ul className="list-unstyled ui-steps">
                  <li className="media">
                    <div className="circle-icon mr-4">1</div>
                    <div className="media-body">
                      <h5>Create Your Online Store</h5>
                      <p>
                        Just download the app, register your phone number and
                        create your online store, which activates instantly.
                        Create your store categories and Add your products with
                        images quickly.{" "}
                      </p>
                    </div>
                  </li>
                  <li className="media my-4">
                    <div className="circle-icon mr-4">2</div>
                    <div className="media-body">
                      <h5>Share with Customers</h5>
                      <p>
                        You will get your store link instantly which you can
                        share to your customers directly from the app using your
                        social media and boom! you are all set to get your first
                        order online.
                      </p>
                    </div>
                  </li>
                  <li className="media">
                    <div className="circle-icon mr-4">3</div>
                    <div className="media-body">
                      <h5>Get your first Order</h5>
                      <p>
                        After Promoting your store on your social media, you can
                        get your first order really quickly and you will start
                        your online business journey within an hour.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <img
                  src="https://ik.imagekit.io/imagesdata/biz_images/Untitled_design__8___3_.png?updatedAt=1687708326304"
                  alt="iphone"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        {/* // end .section 
      <div className="section">
        <div className="container">
          <div className="section-title">
            <small>TESTIMONIALS</small>
            <h3>What our Customers Says</h3>
          </div>
          <div className="testimonials owl-carousel">
            <div className="testimonials-single">
              <img
                src="images/client.png"
                alt="client"
                className="client-img"
              />
              <blockquote className="blockquote">
                Uniquely streamline highly efficient scenarios and 24/7
                initiatives. Conveniently embrace multifunctional ideas through
                proactive customer service. Distinctively conceptualize 2.0
                intellectual capital via user-centric partnerships.
              </blockquote>
              <h5 className="mt-4 mb-2">Crystal Gordon</h5>
              <p className="text-primary">United States</p>
            </div>
            <div className="testimonials-single">
              <img
                src="images/client.png"
                alt="client"
                className="client-img"
              />
              <blockquote className="blockquote">
                Uniquely streamline highly efficient scenarios and 24/7
                initiatives. Conveniently embrace multifunctional ideas through
                proactive customer service. Distinctively conceptualize 2.0
                intellectual capital via user-centric partnerships.
              </blockquote>
              <h5 className="mt-4 mb-2">Crystal Gordon</h5>
              <p className="text-primary">United States</p>
            </div>
            <div className="testimonials-single">
              <img
                src="images/client.png"
                alt="client"
                className="client-img"
              />
              <blockquote className="blockquote">
                Uniquely streamline highly efficient scenarios and 24/7
                initiatives. Conveniently embrace multifunctional ideas through
                proactive customer service. Distinctively conceptualize 2.0
                intellectual capital via user-centric partnerships.
              </blockquote>
              <h5 className="mt-4 mb-2">Crystal Gordon</h5>
              <p className="text-primary">United States</p>
            </div>
          </div>
        </div>
      </div>*/}
        {/* // end .section */}
        {/* <div className="section light-bg" id="gallery">
        <div className="container">
          <div className="section-title">
            <small>GALLERY</small>
            <h3>App Screenshots</h3>
          </div>
        
          <div className="img-gallery owl-carousel owl-theme">
            <div style={{ padding: 10 }}>
              <img
                src="https://www.nearbybazar.com/lobecho/lobecho-screenshot-1.jpg"
                alt="image"
              />
            </div>
            <div style={{ padding: 10 }}>
              <img
                src="https://www.nearbybazar.com/lobecho/lobecho-screenshot-2.jpg"
                alt="image"
              />
            </div>
            <div style={{ padding: 10 }}>
              <img
                src="https://www.nearbybazar.com/lobecho/lobecho-screenshot-3.jpg"
                alt="image"
              />
            </div>
            <div style={{ padding: 10 }}>
              <img
                src="https://www.nearbybazar.com/lobecho/lobecho-screenshot-4.jpg"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>*/}
        {/* // end .section */}
        <div className="section" id="pricing">
          <div className="container">
            <div className="section-title">
              <small>PRICING</small>
              <h3>Pay As You Go</h3>
            </div>
            <div className="card-deck">
              <div className="card pricing">
                <div className="card-head">
                  <small className="text-primary">FREE</small>
                  <span className="price">
                    ₹0<sub>/m</sub>
                  </span>
                </div>
                <ul className="list-group list-group-flush">
                  <div className="list-group-item">Single Store</div>
                  <div className="list-group-item">Unlimited Catalogue</div>
                  <div className="list-group-item">Variants and Addons</div>
                  <div className="list-group-item">Training Material</div>
                  <div className="list-group-item">Customer Details</div>
                  <div className="list-group-item">Cash on Delivery Mode</div>
                </ul>
                <div className="card-body">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nearbybazarbusiness"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    Download App
                  </a>
                </div>
              </div>
              <div className="card pricing popular">
                <div className="card-head">
                  <small className="text-primary">Pay Yearly</small>
                  <span className="price">
                    ₹499<sub>/m</sub>
                  </span>
                  <span
                    className="price"
                    style={{ color: "#ccc", fontSize: "1.2rem" }}
                  >
                    Billed Annually ₹5988<sub>/Y</sub>
                  </span>
                </div>
                <ul className="list-group list-group-flush">
                  <div className="list-group-item">All Free Features</div>
                  <div className="list-group-item">Online Payments</div>
                  <div className="list-group-item">Shiprocket Integration</div>
                  <div className="list-group-item">
                    Free Shiprocket Advance Plan*
                  </div>
                  <div className="list-group-item">Multiple Themes</div>

                  <div className="list-group-item">Reviews</div>
                  <div className="list-group-item">Coupons</div>
                  <div className="list-group-item">Account Manager Support</div>
                </ul>
                <div className="card-body">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nearbybazarbusiness"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    Start Now
                  </a>
                </div>
              </div>
              <div className="card pricing">
                <div className="card-head">
                  <small className="text-primary">Pay Monthly</small>
                  <span className="price">
                    ₹666<sub>/m</sub>
                  </span>
                </div>
                <ul className="list-group list-group-flush">
                  <div className="list-group-item">All Free Features</div>
                  <div className="list-group-item">Online Payments</div>
                  <div className="list-group-item">Shiprocket Integration</div>
                  <div className="list-group-item">
                    Free Shiprocket Advance Plan*
                  </div>

                  <div className="list-group-item">Multiple Themes</div>

                  <div className="list-group-item">Reviews</div>
                  <div className="list-group-item">Coupons</div>

                  <div className="list-group-item">Account Manager Support</div>
                </ul>
                <div className="card-body">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nearbybazarbusiness"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    Start Now
                  </a>
                </div>
              </div>
            </div>
            {/* // end .pricing */}
          </div>
        </div>
        {/* // end .section */}
        <div className="section pt-0">
          <div className="container">
            <div className="section-title">
              <small>FAQ</small>
              <h3>Frequently Asked Questions</h3>
            </div>
            <div className="row pt-4">
              <div className="col-md-6">
                <h4 className="mb-3">Is LoBecho 100% Free?</h4>
                <p className="light-font mb-5">
                  It is 100% free to start your online store on LoBecho with
                  limited features. Although for all the features and dedicated
                  account manager you might consider buying one of our plans, we
                  charge this money to pay our highly motivated and hard working
                  team. Here at LoBecho we strives to give the highest quality
                  at the lowest price.{" "}
                </p>
                <h4 className="mb-3">Is your training stuff limited?</h4>
                <p className="light-font mb-5">
                  No, We constantly increase our training stuff to give the best
                  out of the box groundbreaking training by which we helps you
                  to make your own online store from ground zero. Not only this
                  but also we assist you to accelerate your sales and boost your
                  income online.{" "}
                </p>
              </div>
              <div className="col-md-6">
                <h4 className="mb-3">How can i start my own online store?</h4>
                <p className="light-font mb-5">
                  It's very easy with LoBecho to start your online store. Just
                  download the LoBecho App, register your phone number and
                  create your own online store. Upload your products and share
                  your online store with your social media customers. Don't
                  worry our training stuff will help you do this very quickly.
                </p>
                <h4 className="mb-3">How will i get personal help?</h4>
                <p className="light-font mb-5">
                  We have dedicated whatsapp support to help you in your issues,
                  Although if you buy any of our prepaid plan you will be
                  getting an account manager to whom you can call and discuss
                  about your problems anytime directly without any IVR.{" "}
                </p>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-6">
                <h4 className="mb-3">Is LoBecho an Indian Company?</h4>
                <p className="light-font mb-5">
                  LoBecho is a product of SKLEPON AI SERVICES PRIVATE LIMITED,
                  which is a purely made in India company registered with
                  startup India. All your data will be secured with Secured
                  Socket Layers and stored in servers situated in India Only.
                  For more detailed information you might consider reading our
                  Privacy-Policy.{" "}
                </p>
              </div>
              <div className="col-md-6">
                {" "}
                <h4 className="mb-3">Is LoBecho available in Hindi?</h4>
                <p className="light-font mb-5">
                  Yes, As LoBecho is a purely Indian Product we are currently
                  providing support of two major spoken languages in India,
                  Hindi and English. We are working hard and soon launch our app
                  in more languages such as Bengali, Marathi, Tamil, Telugu,
                  Gujarati, Punjabi and Urdu.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* // end .section */}
        <div className="section bg-gradient">
          <div className="container">
            <div className="call-to-action">
              <div className="box-icon">
                <span className="ti-mobile gradient-fill ti-3x" />
              </div>
              <h2>Download For Android</h2>
              <p className="tagline">
                Available for all major android mobiles. Launching really very
                soon on iOS.{" "}
              </p>
              <div className="my-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.nearbybazarbusiness"
                  className="btn btn-light"
                >
                  <img
                    src="https://www.nearbybazar.com/lobecho/playicon.png"
                    alt="icon"
                  />{" "}
                  Google play
                </a>
              </div>
              <p className="text-primary">
                <small>
                  <i>*Works on Android Lolipop and above. </i>
                </small>
              </p>
            </div>
          </div>
        </div>
        {/* // end .section */}
        <div className="light-bg py-5" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center text-lg-left">
                <p className="mb-2">
                  {" "}
                  <span className="ti-location-pin mr-2" /> H-2058 Gaurcity-2 ,
                  Noida Extension , Noida, UP - INDIA
                </p>
                <div className=" d-block d-sm-inline-block">
                  <p className="mb-2">
                    <span className="ti-email mr-2" />{" "}
                    <a className="mr-4" href="mailto:help@lobecho.com">
                      help@lobecho.com
                    </a>
                  </p>
                </div>
                <div className="d-block d-sm-inline-block">
                  <p className="mb-0">
                    <span className="ti-headphone-alt mr-2" />{" "}
                    <a href="tel:+918447449489">(+91) 844-744-9489</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="social-icons">
                  <a href="#">
                    <span className="ti-facebook" />
                  </a>
                  <a href="#">
                    <span className="ti-twitter-alt" />
                  </a>
                  <a href="#">
                    <span className="ti-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // end .section */}
        <footer className="my-5 text-center">
          {/* Copyright removal is not prohibited! */}
          <p className="mb-2">
            <small>COPYRIGHT © 2023. ALL RIGHTS RESERVED.</small>
          </p>
          <small>
            <a
              href="https://www.lobecho.com/legal/terms-and-conditions"
              className="m-2"
            >
              TERMS
            </a>
            <a
              href="https://www.lobecho.com/legal/privacy-policy"
              className="m-2"
            >
              PRIVACY
            </a>
          </small>
        </footer>
      </div>
    </div>
  );
};

export default MainSite;

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getYoutubeVideoType, youtubeParser } from "../../../../helper/helper";
import Button from "../../components/Button/Button";
import styles from "./style.module.scss";

export const ProductImagesSlider = ({
  images,
  imageKey,
  limit = 4,
  youtubeVideoURL,
}) => {
  const [heroImage, setHeroImage] = useState("");
  const [loadVideo, setLoadVideo] = useState(false);
  useEffect(() => {
    if (images.length) setHeroImage(images[0][imageKey]);
  }, [imageKey, images]);

  const swiperRef = useRef(null);
  const handlePrevClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleTileClick = (index) => {
    setLoadVideo(false);
    setHeroImage(images[index][imageKey]);
  };
  const handleVideoTileClick = (index) => {
    setLoadVideo(true);
    setHeroImage("");
  };

  const youtubeVideoId = youtubeVideoURL
    ? youtubeParser(youtubeVideoURL)
    : null;

  const youtubeVideoType = getYoutubeVideoType(youtubeVideoURL);
  const youtubeVideoMobileHeight =
    youtubeVideoType === "shorts" ? 500 : window?.innerWidth / 1.777;
  const isMobile = window?.innerWidth < 768;

  return (
    <div className={styles.root}>
      {loadVideo && youtubeVideoId ? (
        <iframe
          width="100%"
          height={isMobile ? youtubeVideoMobileHeight : "500"}
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          title="Product Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <img src={heroImage} alt="hero" className={styles.heroImage} />
      )}
      <div className={`mt-4 ${styles.swiperContainer}`}>
        {images.length > limit && (
          <Button
            Icon={ChevronLeftIcon}
            className={styles.leftButton}
            onClick={handlePrevClick}
          />
        )}
        <div className="d-block">
          <Swiper spaceBetween={10} ref={swiperRef} slidesPerView={4}>
            {images.map((item, index) => {
              return (
                <>
                  <SwiperSlide
                    className={`${styles.tileImageContainer} ${
                      item[imageKey] === heroImage ? styles.tileSelected : ""
                    }`}
                  >
                    <div
                      className={styles.tileImageOverlay}
                      onClick={() => handleTileClick(index)}
                    ></div>
                    <img
                      alt=""
                      src={item[imageKey]}
                      className={`img-fluid ${styles.tileImage}`}
                    />
                  </SwiperSlide>
                  {index === 0 && youtubeVideoId ? (
                    <SwiperSlide
                      className={`${styles.tileImageContainer} ${
                        loadVideo ? styles.tileSelected : ""
                      }`}
                    >
                      <div
                        className={styles.videoTileImageOverlay}
                        onClick={() => handleVideoTileClick()}
                      >
                        <img
                          alt=""
                          src={
                            "https://ik.imagekit.io/imagesdata/biz_images/youtube_icon.png?updatedAt=1696358364735"
                          }
                        />
                      </div>

                      <img
                        alt=""
                        src={`https://img.youtube.com/vi/${youtubeVideoId}/mqdefault.jpg`}
                        className={`img-fluid ${styles.tileImage}`}
                      />
                    </SwiperSlide>
                  ) : null}
                </>
              );
            })}
          </Swiper>
        </div>
        {images.length > limit && (
          <Button
            Icon={ChevronRightIcon}
            className={styles.rightButton}
            onClick={handleNextClick}
          />
        )}
      </div>
      <div></div>
    </div>
  );
};

import { LinearProgress, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import APIHook from "../helper/APIHook";
import { cartContext } from "../helper/cartContext";
import ThemeChooser from "../helper/ThemeChooser";
import emptyCart from "../resources/empty_cart.jpg";

const Account = () => {
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [nearbybazarAPIReview, resultAPIReview] = APIHook();
  const [nearbybazarAPIReorder, resultAPIReorder] = APIHook();
  const [reorderClick, setReorderClick] = useState(false);
  const [cartValue, setCartValue] = useContext(cartContext);
  const [bottomDrawer, setBottomDrawer] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [reviewOrderId, setReviewOrderId] = useState("");

  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const history = useHistory();
  const submitReview = () => {
    addReview();
  };
  const addReview = () => {
    const data = {
      link: storeName,
      order_id: reviewOrderId,
      rating: ratingValue,
      reviewText: reviewText,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIReview(data, "/front/addreview");
  };

  const openReviewSection = (order_id, review, rating) => {
    setBottomDrawer(true);
    setReviewOrderId(order_id);
    setRatingValue(parseInt(rating));
    setReviewText(review);
  };

  const getOrders = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/getorders");
  };
  const reOrder = async (orderID, customerID) => {
    const data = {
      link: storeName,
      order_id: orderID,
      customer_id: customerID,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIReorder(data, "/front/reorder");
  };

  const logout = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIReorder(data, "/front/logout");
    history.push({
      pathname: "/" + storeNameLink + "/store",
    });
  };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (resultAPICart.status === "error") {
    if (resultAPICart.message_code === "LOGIN")
      history.push({
        pathname: "/" + storeNameLink + "/login",
        state: {
          data: "account",
        },
      });
  }
  if (resultAPIReorder.status === "success") {
    setCartValue(parseInt(resultAPIReorder.data.cart_count));
    history.push({
      pathname: "/" + storeNameLink + "/cart",
    });
  }

  if (reorderClick) return <LinearProgress color="secondary" />;
  else
    return (
      <div>
        {resultAPICart.status === "success" ? (
          <ThemeChooser
            props={{
              resultAPICart: resultAPICart,
              bottomDrawer: bottomDrawer,
              setBottomDrawer: setBottomDrawer,
              ratingValue: ratingValue,
              setRatingValue: setRatingValue,
              TextField: TextField,
              setReviewText: setReviewText,
              reviewText: reviewText,
              submitReview: submitReview,
              logout: logout,
              reOrder: reOrder,
              setReorderClick: setReorderClick,
              openReviewSection: openReviewSection,
              emptyCart: emptyCart,
              storeNameLink: storeNameLink,
            }}
            name="account"
          />
        ) : (
          <LinearProgress color="secondary" />
        )}
      </div>
    );
};
export default Account;

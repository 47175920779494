import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const InvoiceFooter = ({ invoiceData }) => {
  return (
    <>
      <View style={[styles.row, styles.marginTop20]}>
        <View>
          <Text style={styles.address}>Notes:</Text>
          <Text style={styles.address}>Thank you for your business.</Text>
        </View>
        {invoiceData?.shopName ? (
          <View style={{ flexDirection: "column", alignItems: "flex-end" }}>
            <Text style={styles.textRight}>For {invoiceData?.shopName}</Text>
            <Text
              style={[
                styles.marginTop40,
                styles.signAndStamp,
                styles.textRight,
              ]}
            >
              Sign. & Stamp
            </Text>
          </View>
        ) : null}
      </View>
    </>
  );
};

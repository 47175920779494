import { Snackbar, TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Alert } from "@material-ui/lab";
import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../../components/Navbar";

const Login = ({
  renderOTP,
  phone,
  setPhone,
  generateOTP,
  setError,
  setErrorMsg,
  setToastOpen,
  setOTP,
  otp,
  success,
  login,
  toastOpen,
  handleClose,
  resultAPIOTP,
  error,
  errorMsg,
  storeNameLink,
  history,
}) => {
  return (
    <Fragment>
      <Navbar storeName={storeNameLink} />
      <Row style={!renderOTP ? { display: "block" } : { display: "none" }}>
        <Col xs={12} xl={12}>
          <div className="otp-banner">
            <div
              onClick={() => {
                history.go(-3);
              }}
              className="d-flex ml-0 mb-4"
            >
              <ArrowBackIcon className="productHeaderIcon" /> Go Back
            </div>
            <h6>OTP Verification</h6>
            Please enter your 10 digit mobile number.
          </div>
        </Col>
      </Row>{" "}
      <Row style={renderOTP ? { display: "block" } : { display: "none" }}>
        <Col xs={12} xl={12}>
          <div className="otp-banner">
            <div
              onClick={() => {
                history.goBack();
              }}
              className="d-flex ml-0 mb-4"
            >
              <ArrowBackIcon className="productHeaderIcon" /> Cancel
            </div>
            <h6>OTP Verification</h6>
            <br />
            Enter the OTP sent to the number {phone}
          </div>
        </Col>
      </Row>
      <Container>
        {" "}
        <Row style={!renderOTP ? { display: "block" } : { display: "none" }}>
          <Col xs={12} xl={12}>
            <div className="input-otp" style={{ display: "flex" }}>
              <TextField
                className="form-control"
                onChange={(e) => {
                  if (/^[0-9]{0,10}$/.test(e.target.value))
                    setPhone(e.target.value);
                  else e.target.value = "";
                }}
                label="Mobile-Number"
                disabled={renderOTP}
                value={phone}
                type="number"
              />{" "}
            </div>
          </Col>
          <Col xs={12} xl={12}>
            <div className="send-otp-button">
              <button
                onClick={() => {
                  if (phone.length === 10) generateOTP(phone);
                  else {
                    console.log("i am in error");
                    setError(true);
                    setErrorMsg("Phone Number must be of 10 Digits.");
                    setToastOpen(true);
                  }
                }}
              >
                Send OTP
              </button>
            </div>
          </Col>
        </Row>
        <Row style={renderOTP ? { display: "block" } : { display: "none" }}>
          <Col xs={12} xl={12}>
            <div className="input-otp">
              <TextField
                className="form-control"
                label="Enter the OTP"
                onChange={(e) => {
                  if (/^[0-9]{0,6}$/.test(e.target.value))
                    setOTP(e.target.value);
                  else e.target.value = "";
                }}
                value={otp}
                type="number"
              />
              <div className="re-send-otp-button">
                <button onClick={() => generateOTP(phone)}>Resend OTP</button>
              </div>
              <div className="send-otp-button">
                <button
                  onClick={() => {
                    if (otp.length === 6) login(otp);
                    else {
                      console.log("i am in error");
                      setError(true);
                      setErrorMsg("OTP must be of 6 Digits.");
                      setToastOpen(true);
                    }
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {success ? (
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {resultAPIOTP.message}
          </Alert>
        </Snackbar>
      ) : null}
      {error ? (
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
      ) : null}
    </Fragment>
  );
};
export default Login;

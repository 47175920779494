/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";

const Search = () => {
  const location = useLocation();
  const searchName = location.pathname.split("/")[3];
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const history = useHistory();
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  const [nearbybazarAPIProducts, resultAPIProducts] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [cartValue, setCartValue] = useContext(cartContext);
  const [searchValue, setSearchValue] = useState(searchName);
  const getProducts = async (search) => {
    const data = {
      link: storeName,
      search: search,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIProducts(data, "/front/search");
  };
  const addToCart = async (pid, quantity) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: "{}",
      addon: "{}",
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/addtocart/");
  };

  useEffect(() => {
    getProducts(searchName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (resultAPICart.status === "success") {
    if (cartValue !== parseInt(resultAPICart.data.cart_items))
      setCartValue(parseInt(resultAPICart.data.cart_items));
  }
  if (resultAPIProducts.status === "success") {
    const products = resultAPIProducts.data.products;

    return (
      <ThemeChooser
        props={{
          searchValue: searchValue,
          setSearchValue: setSearchValue,
          getProducts: getProducts,
          products: products,
          storeNameLink: storeNameLink,
          addToCart: addToCart,
          history: history,
        }}
        name="search"
      />
    );
  } else {
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
  }
};

export default Search;

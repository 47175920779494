import React from "react";
import { Image } from "react-bootstrap";

export const ProductImageBackdrop = ({ src }) => {
  return (
    <div className="product-image-backdrop">
      <Image className="product-image-backdrop-image" src={src} />
    </div>
  );
};

import AddIcon from "@material-ui/icons/Add";
import React, { useContext, useEffect, useState } from "react";
import { cartContext } from "../../../helper/cartContext";

const ProductAddToCartButton = ({
  quantityInCart,
  onChangeQuantity,
  minimumOrderQuantity = 1,
}) => {
  console.log("qunatity in cart", quantityInCart);
  const [quantity, setQuantity] = useState(parseInt(quantityInCart));
  const [cartValue, setCartValue] = useContext(cartContext);

  useEffect(() => {
    if (quantityInCart !== -1) setQuantity(parseInt(quantityInCart));
  }, [quantityInCart]);

  return quantity === 0 ? (
    <div className="btn-add width-100">
      <button
        type="button"
        class="btn btn-outline-primary btn-add width-100"
        onClick={() => {
          setQuantity((prevState) => prevState + minimumOrderQuantity);

          onChangeQuantity(quantity + minimumOrderQuantity);
        }}
      >
        <div>
          {" "}
          <span className="addIcon">ADD TO CART</span>
          <AddIcon className="addIcon" />
        </div>
      </button>
    </div>
  ) : (
    <div className="btn-add quantity width-100">
      <button
        type="button"
        className="quantity__minus"
        disabled={quantity === 0}
        onClick={() => {
          if (quantity > minimumOrderQuantity) {
            setQuantity((prevState) => prevState - 1);
            onChangeQuantity(quantity - 1);
          } else {
            setQuantity(0);
            onChangeQuantity(0);
          }
        }}
      >
        <span className="changeQuantity">-</span>
      </button>
      <input value={quantity} className="quantity__input" disabled />
      <button
        type="button"
        className="quantity__plus"
        onClick={() => {
          setQuantity((prevState) => prevState + 1);

          onChangeQuantity(quantity + 1);
        }}
      >
        <span className="changeQuantity">+</span>
      </button>
    </div>
  );
};

export default ProductAddToCartButton;

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { Col, Row } from "react-bootstrap";
import DetailsCard from "../../components/DetailsCard";
import Navbar from "../../components/Navbar";
import Searchbar from "../../components/Searchbar";

const Search = ({
  searchValue,
  setSearchValue,
  getProducts,
  products,
  history,
  storeNameLink,
  addToCart,
}) => {
  return (
    <div>
      <Navbar storeName={storeNameLink} />
      <div className="container">
        <div className="pl-2 py-4 d-flex align-items-center">
          <div
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBackIcon className="productHeaderIcon" />
          </div>

          <div className="product-page-heading-title">Search</div>
        </div>
        <Searchbar
          placeholder="Search Products.."
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onButtonHit={() => {
            getProducts(searchValue);
          }}
        />
        <hr />
        <div>
          {products.length > 0 ? (
            <div className="products-under-category-list">
              <Row gutter={40}>
                {Object.values(products).map((product, index) => (
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 4 }}
                    md={{ span: 4 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                    key={products.product_id}
                  >
                    <DetailsCard
                      image={product.product_data.image}
                      text={product.product_data.productname}
                      price={product.product_data.price}
                      special={product.product_data.special}
                      haveOptions={product.product_data.haveOptions}
                      storeName={storeNameLink}
                      productID={product.product_data.product_id}
                      quantityInCart={product.product_data.quantity_in_cart}
                      minimumOrderQuantity={
                        product.product_data.minimum_quantity
                      }
                      addToCart={(quantity) => {
                        addToCart(product.product_data.product_id, quantity);
                      }}
                    />
                  </Col>
                ))}
              </Row>

              <div class="margin-bottom-150px"></div>
            </div>
          ) : (
            <div className="noProductsFound">No Products Found.</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Search;

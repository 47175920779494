import React, { useEffect, useState } from "react";
import { ItemInFocus } from "../../components/ItemInFocus/ItemInFocus";
import Slider from "../../components/Slider/Slider";
import { CategoryListSlider } from "../../widgets/CategoryListSlider/CategoryListSlider";
import { ProductListSlider } from "../../widgets/ProductListSlider/ProductListSlider";
const Catalog = ({ resultAPI, storeNameLink, addToCart, catalogID }) => {
  const [catalog, setCatalog] = useState([]);
  const [randomProductIndicies, setRandomProductIndicies] = useState([]);
  const randomProductIndex = (productList) => {
    if (productList.length)
      return Math.floor(Math.random() * productList.length);
    return 0;
  };
  const generateRandomProductIndicies = (data) => {
    if (data) {
      const temp = [];
      data.map((item, index) => {
        temp.push(randomProductIndex(item.products));
        return 0;
      });
      return temp;
    }
  };

  useEffect(() => {
    const findCatalog = (catalogs, id) => {
      const result = catalogs.filter((item) => item.biz_shop_catalog_id === id);
      return result;
    };

    if (resultAPI?.data?.all_category) {
      setRandomProductIndicies(
        generateRandomProductIndicies(resultAPI.data.all_category)
      );
      setCatalog(findCatalog(resultAPI.data.all_catalog, catalogID));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAPI]);

  return (
    <div className="max-width mt-5">
      {catalog.length > 0 && (
        <Slider
          images={catalog}
          callSign="image"
          centerTitle={catalog[0].name}
          lessHeight
          dark
          storeNameLink={storeNameLink}
        />
      )}
      <CategoryListSlider
        forCatalog
        title="Shop By Categories"
        data={resultAPI.data.all_category}
        storeNameLink={`${storeNameLink}/category`}
        dataKey="biz_shop_category_id"
      />
      {randomProductIndicies.length > 0 &&
        resultAPI.data.all_category?.map((item, index) => {
          if (item.products.length > 0)
            return (
              <div key={index + "productslider"}>
                <ProductListSlider
                  category={item.name}
                  data={item.products}
                  storeNameLink={storeNameLink}
                  addToCart={addToCart}
                  categoryId={item.biz_shop_category_id}
                  totalCount={item.total_products_count}
                />
                {item.products.length > 5 && (
                  <ItemInFocus
                    link={`/${storeNameLink}/product/${
                      item.products[randomProductIndicies[index]].product_data
                        .product_id
                    }`}
                    description=""
                    className="my-5 py-5"
                    image={
                      item.products[randomProductIndicies[index]].product_data
                        .image
                    }
                    detailsOnLeft={index % 2 === 0}
                    name={
                      item.products[randomProductIndicies[index]].product_data
                        .productname
                    }
                    price={
                      item.products[randomProductIndicies[index]].product_data
                        .price
                    }
                    special={
                      item.products[randomProductIndicies[index]].product_data
                        .special !== "0"
                        ? item.products[randomProductIndicies[index]]
                            .product_data.special
                        : undefined
                    }
                  />
                )}
              </div>
            );
        })}
    </div>
  );
};
export default Catalog;

import { Image, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";

export const Watermark = ({ url }) => {
  return (
    <View style={styles.watermarkContainer}>
      <Image style={styles.watermarkImage} src={url} />
    </View>
  );
};

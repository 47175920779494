import React from "react";
import styles from "./style.module.scss";

export const IconWithText = ({
  className,
  heading,
  subHeading,
  Icon,
  flipIcon,
}) => {
  return (
    <div className={`${styles.root} ${className} row`}>
      <div className="mt-1">
        <Icon className={`${styles.icon} ${flipIcon ? styles.flipIcon : ""}`} />
      </div>
      <div className="ml-2">
        {heading && <div className={styles.heading}>{heading}</div>}
        {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
      </div>
    </div>
  );
};

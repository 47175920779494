import React, { useContext } from "react";
import Default from "../themes/default/Main";
import Vaquita from "../themes/vaquita/Main";
import { themeContext } from "./themeContext";

const ThemeChooser = ({ props, name }) => {
  const { theme } = useContext(themeContext);
  const themes = { default: Default, vaquita: Vaquita };
  const currentTheme = theme !== "" ? theme : "vaquita";
  const Theme = themes[currentTheme];
  return <Theme props={props} name={name} />;
};
export default ThemeChooser;

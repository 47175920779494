import { LinearProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import { accountContext } from "../helper/AccountContext";
import ThemeChooser from "../helper/ThemeChooser";

const Login = ({ ...props }) => {
  const [accountData, setAccountData] = useContext(accountContext);
  const [nearbybazarAPIOTP, resultAPIOTP] = APIHook();
  const [nearbybazarAPILogin, resultAPILogin] = APIHook();
  const [nearbybazarAPICart, resultAPICart] = APIHook();

  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [renderOTP, setRenderOTP] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const history = useHistory();
  const [count, setCount] = useState(1);

  var transferAfterLogin =
    props.location.state !== undefined ? "account" : "checkout";

  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const getOrders = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/getorders");
  };
  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (resultAPICart.status === "success") {
    history.push({
      pathname: "/" + storeNameLink + "/orderconfirm",
      state: {
        data: "account",
      },
    });
  }

  const generateOTP = async (number) => {
    const data = {
      link: storeName,
      phone: number,
    };
    setCount(1);
    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPIOTP(data, "/front/generateotp/");
    setToastOpen(true);
  };

  const handleClose = () => {
    setToastOpen(false);
    setError(false);
    setSuccess(false);
  };

  const login = async (number) => {
    console.log(phone);
    const data = {
      link: storeName,
      phone: phone,
      otp: number,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPILogin(data, "/front/logincustomer/");
  };

  if (!error && resultAPILogin.status === "error") {
    setError(true);
    setToastOpen(true);
    setErrorMsg(resultAPILogin.message);
  }
  if (resultAPIOTP.status === "error") {
    setError(true);
    setToastOpen(true);
    setErrorMsg(resultAPIOTP.message);
  }

  if (resultAPIOTP.status === "success" && !renderOTP && count === 1) {
    setRenderOTP(true);
    setSuccess(true);
    setCount(2);
  }

  if (resultAPILogin.status === "success") {
    if (resultAPILogin.data.have_account === "1") {
      setAccountData(resultAPILogin.data);
      transferAfterLogin =
        transferAfterLogin !== "account" ? "orderconfirm" : "account";

      history.push({
        pathname: "/" + storeNameLink + "/" + transferAfterLogin,
        state: {
          data: resultAPILogin.data,
        },
      });
    } else {
      history.push({
        pathname: "/" + storeNameLink + "/checkout",
        state: {
          otp: otp,
          phone: phone,
        },
      });
    }
  }
  const handleBackEvent = () => {
    history.go(-2);
  };
  useEffect(() => {
    window.addEventListener("popstate", handleBackEvent);
    return () => window.removeEventListener("popstate", handleBackEvent);
  });

  if (resultAPICart.status !== "success" && resultAPICart.status !== "error") {
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
  } else
    return (
      <ThemeChooser
        name="login"
        props={{
          renderOTP: renderOTP,
          phone: phone,
          setPhone: setPhone,
          generateOTP: generateOTP,
          setError: setError,
          setErrorMsg: setErrorMsg,
          setToastOpen: setToastOpen,
          storeNameLink: storeNameLink,
          setOTP: setOTP,
          otp: otp,
          success: success,
          login: login,
          toastOpen: toastOpen,
          handleClose: handleClose,
          resultAPIOTP: resultAPIOTP,
          error: error,
          errorMsg: errorMsg,
          history: history,
        }}
      />
    );
};

export default Login;

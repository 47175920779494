/* eslint-disable jsx-a11y/accessible-emoji */
import { LinearProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";
import APIHook from "../helper/APIHook";
import ThemeChooser from "../helper/ThemeChooser";
import { cartContext } from "../helper/cartContext";
import emptyCart from "../resources/empty_cart.jpg";

const Cart = () => {
  const [nearbybazarAPICart, resultAPICart] = APIHook();
  const [nearbybazarAPICartAdd, resultAPICartAdd] = APIHook();
  const [cartValue, setCartValue] = useContext(cartContext);
  const [cartData, setCartData] = useState([]);
  const [bottomDrawer, setBottomDrawer] = useState(false);
  const [coupon, setCoupon] = useState("");

  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  const getCart = async () => {
    const data = {
      link: storeName,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICart(data, "/front/getcart");
  };
  useEffect(() => {
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToCart = async (pid, quantity, variant, addon) => {
    const data = {
      link: storeName,
      product_id: pid,
      quantity: quantity,
      variant: variant,
      addon: addon,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICartAdd(data, "/front/addtocart/");
  };
  const applyCoupon = async (type) => {
    const data = {
      link: storeName,
      coupon: coupon,
      type: type,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICartAdd(data, "/front/applycoupon/");
  };
  const applyCouponDirect = async (type, couponApply) => {
    const data = {
      link: storeName,
      coupon: couponApply,
      type: type,
    };

    console.log("SENDING API REQUEST WITH DATA " + data);
    nearbybazarAPICartAdd(data, "/front/applycoupon/");
  };

  const showOptions = (options) => {
    const optionData = JSON.parse(options);
    var variant = [];
    var addon = [];
    if (optionData.length !== 0) {
      variant = optionData[0];
      addon = optionData[1];
      return (
        <div>
          {" "}
          {variant !== undefined
            ? Object.values(variant).map((variantItem) => (
                <div className="cart-product-variant">
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {variantItem.name}
                  </span>{" "}
                  : {variantItem.value} (+₹{variantItem.price})
                </div>
              ))
            : null}
          {addon !== undefined
            ? Object.values(addon).map((addonItem) => (
                <div className="cart-product-variant">
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {addonItem.name}
                  </span>
                  <div>
                    {Object.values(addonItem.value).map((addonValue) => (
                      <div className="cart-product-variant">
                        {addonValue.name} (+₹{addonValue.price})
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : null}
        </div>
      );
    } else return null;
  };

  if (resultAPICart.status === "success" && cartData.length === 0) {
    setCartData(resultAPICart);
    console.log("cart data updated with initial data");
  } else if (
    resultAPICartAdd.status === "success" &&
    cartData.length !== 0 &&
    JSON.stringify(cartData) !== JSON.stringify(resultAPICartAdd)
  ) {
    setCartData(resultAPICartAdd);
    setBottomDrawer(false);
    console.log("cart data updated");
    setCartValue(parseInt(resultAPICartAdd.data.cart_items));
  }
  if (cartData.status === "success")
    return (
      <ThemeChooser
        props={{
          cartData: cartData,
          storeNameLink: storeNameLink,
          showOptions: showOptions,
          addToCart: addToCart,
          setBottomDrawer: setBottomDrawer,
          applyCoupon: applyCoupon,
          bottomDrawer: bottomDrawer,
          setCoupon: setCoupon,
          resultAPICartAdd: resultAPICartAdd,
          applyCouponDirect: applyCouponDirect,
          emptyCart: emptyCart,
        }}
        name="cart"
      />
    );
  else
    return (
      <div>
        <LinearProgress color="secondary" />
        <LoaderComponent storeName={storeName} />
      </div>
    );
};

export default Cart;

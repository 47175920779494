import React, { useState } from "react";
import styles from "./styles.module.css";

const InputText = ({
  onEnterAction = () => {},
  onClickAction = () => {},
  placeholder,
  value,
  Icon,
  buttonText,
}) => {
  const [val, setVal] = useState(value);
  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && onEnterAction) {
      onEnterAction(e);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={val}
          className={styles.input}
          placeholder={placeholder}
          onChange={(e) => {
            setVal(e.target.val);
          }}
          onKeyDown={_handleKeyDown}
        />
        <div className={styles.button} onClick={onClickAction}>
          <span>{buttonText}</span>
          <Icon className={styles.icon} />
        </div>
      </div>
    </div>
  );
};
export default InputText;

import { LinearProgress } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import nearbybazar from "../../../../resources/nearbybazar_logo_purple.jpg";
import Whatsapp from "../../../../resources/whatsapp.svg";

const Information = ({ resultAPI, heading, call, storeNameLink }) => {
  return (
    <div>
      {resultAPI.status === "success" ? (
        resultAPI.data.is_premium !== 1 ? (
          <a
            style={{ textDecoration: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://play.google.com/store/apps/details?id=com.nearbybazarbusiness`}
          >
            <div className="lobecho-branding">
              Made using LoBecho. <u>Download App</u>
            </div>
          </a>
        ) : null
      ) : null}

      {resultAPI.status === "success" ? (
        <React.Fragment>
          <div className="container">
            <title>{resultAPI.data.store_name + ": Order Online Now"} </title>
            <meta
              name="description"
              content={
                "Order Online From " +
                resultAPI.data.store_name +
                " and get quick delivery on all of your orders, we are dedicated to serve you at the top most priority. Here at " +
                resultAPI.data.store_name +
                " we always give best and quality products and we are always there for your help. Why wait? Start Ordering Now!"
              }
            />
            <meta
              name="keywords"
              content={
                resultAPI.data.store_name +
                "," +
                resultAPI.data.store_name +
                " online store," +
                resultAPI.data.store_name +
                "order online, " +
                resultAPI.data.store_name +
                " website, " +
                resultAPI.data.store_name +
                " delivery"
              }
            />

            <h1
              style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              {heading}
            </h1>
            <div
              class="container"
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
                marginBottom: "30px",
                fontSize: "1.8em",
                color: "#666",
              }}
            >
              {resultAPI.data[call]}
            </div>
          </div>
          <div style={{ background: "#efefef", paddingBottom: 150 }}>
            <div class="container">
              <div className="store-main-card">
                <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                  <Col xl={1} sm={2} xs={3} style={{ padding: 0 }}>
                    <Image
                      className="store-details-card-image"
                      src={resultAPI.data.store_image}
                    />
                  </Col>
                  <Col
                    xl={10}
                    sm={resultAPI.data.is_premium === 1 ? 10 : 8}
                    xs={resultAPI.data.is_premium === 1 ? 9 : 6}
                    style={{ padding: 0, paddingTop: 5, paddingLeft: 10 }}
                  >
                    <div className="store-details-card">
                      <div className="store-details-card-address">
                        <h5 style={{ fontSize: "1.6rem", fontWeight: "bold" }}>
                          {resultAPI.data.store_name}
                        </h5>
                        <p
                          style={{
                            maxWidth: 200,
                            fontSize: "1.2rem",
                            color: "#444",
                            letterSpacing: 0.5,
                            paddingLeft: 2,
                            fontWeight: "bold",
                          }}
                        >
                          {resultAPI.data.store_address}
                          <br />
                          {resultAPI.data.store_city},{" "}
                          {resultAPI.data.store_state}
                          <br />
                          <div>
                            <div>
                              <div style={{ display: "inline-block" }}>
                                {resultAPI.data.rating}
                              </div>
                              <StarIcon
                                style={{ color: "#f0b800", marginTop: -3 }}
                              />
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                {"  "}
                                {resultAPI.data.review_count} Reviews
                              </div>
                            </div>
                          </div>
                          {resultAPI.data.store_status === "0" ? (
                            <div
                              style={{ color: "#e0071c", fontWeight: "bold" }}
                            >
                              Store offline
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </Col>
                  {resultAPI.data.is_premium !== 1 ? (
                    <Col xl={1} sm={2} xs={3} style={{ padding: 0 }}>
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://play.google.com/store/apps/details?id=com.nearbybazarbusiness`}
                      >
                        <div className="storePoweredByCard">
                          <Image
                            className="store-powered-by-card-image"
                            src={nearbybazar}
                          />
                          <h6 className="storePoweredBy">MADE USING</h6>
                        </div>
                      </a>
                    </Col>
                  ) : null}
                </Row>
              </div>
              {resultAPI.data.whatsapp_support_status === "1" ? (
                <div className="btn-fab">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://wa.me/+91${resultAPI.data.seller_phone}?text=Hello, I just checked your website of ${resultAPI.data.store_name}. I need some help!`}
                  >
                    <Image src={Whatsapp} className="img-fluid" alt="wa-icon" />
                  </a>
                </div>
              ) : null}
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "2em",
                    marginTop: 40,
                  }}
                >
                  Information
                </div>
                <a href={"/" + storeNameLink + "/about-us"}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.6em",
                    }}
                  >
                    About Us
                  </div>
                </a>
                <a href={"/" + storeNameLink + "/terms-and-conditions"}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.6em",
                    }}
                  >
                    Terms & Conditions
                  </div>
                </a>
                <a href={"/" + storeNameLink + "/return-policy"}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.6em",
                    }}
                  >
                    Return & Refund Policy
                  </div>
                </a>
                <a href={"/" + storeNameLink + "/privacy-policy"}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.6em",
                    }}
                  >
                    Privacy Policy
                  </div>
                </a>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "2em",
                    marginTop: 40,
                  }}
                >
                  Follow Us
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.6em",
                      display: "inline-block",
                      marginRight: 5,
                    }}
                  >
                    <a href={resultAPI.data.facebook}>
                      <div>
                        <img
                          src="https://www.nearbybazar.com/biz_images/frontend/fb.webp"
                          target="_blank"
                          style={{ width: 32, height: 32 }}
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.6em",
                      display: "inline-block",
                    }}
                  >
                    <a href={resultAPI.data.insta}>
                      <div>
                        <img
                          src="https://www.nearbybazar.com/biz_images/frontend/insta.webp"
                          target="_blank"
                          style={{ width: 32, height: 32 }}
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "1.2em",
                marginTop: 50,
              }}
            >
              &copy; Copyright 2021 {resultAPI.data.store_name} | All Right
              Reserved
            </div>
          </div>
        </React.Fragment>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </div>
  );
};
export default Information;

import { ArrowRightAlt } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { whichDevice } from "../../lib/device";
import { convertMoneyToText } from "../../lib/money";
import Button from "../Button/Button";
import styles from "./style.module.scss";

export const ItemInFocus = ({
  image,
  link,
  name = "Catalog Name",
  description = "Make a dramatic first impression with our stunning collection of shorts from ripped denims to high waist ones, skinny fits to stylish flared jeans.",
  buttonText = "Buy Now",
  price,
  special,
  onClick = () => {},
  className,
  detailsOnLeft,
}) => {
  const isPC = whichDevice() === "pc";
  const ImageContainer = ({ left }) => {
    return (
      <>
        {left && <div className="col-sm-4"></div>}
        <div className="col-sm-8">
          <div className={styles.imageContainer}>
            <Link
              to={{
                pathname: link,
              }}
            >
              <img src={image} className={styles.image} />
            </Link>
          </div>
        </div>
      </>
    );
  };
  const DescContainer = ({ left }) => {
    const priceText = convertMoneyToText(price);
    const specialText = convertMoneyToText(special);
    return (
      <div
        className={`col-sm-5 ${
          left ? styles.pcDescLeft : styles.pcDesc
        }  d-flex flex-column justify-content-center align-items-center`}
      >
        <div className={`pb-2 ${styles.catalogName}`}>{name}</div>
        {special ? (
          <div className="d-flex pb-2">
            <div className={`${styles.priceStrike}`}>
              <strike>{priceText}</strike>
            </div>
            <div className={`pl-2 ${styles.special}`}>{specialText}</div>
          </div>
        ) : (
          price && <div className={`${styles.price} pb-2`}>{priceText}</div>
        )}

        {description && (
          <div className={`pt-4 pb-2 ${styles.description}`}>{description}</div>
        )}
        <Link
          to={{
            pathname: link,
          }}
        >
          <Button
            className="mt-4"
            text={buttonText}
            animated
            Icon={ArrowRightAlt}
          />
        </Link>
      </div>
    );
  };

  return (
    <div className={`max-width ${styles.root} ${className}`}>
      <div className="row p-3">
        {detailsOnLeft && isPC ? (
          <>
            <DescContainer left />
            <ImageContainer left />
          </>
        ) : (
          <>
            <ImageContainer />
            <DescContainer />
          </>
        )}
      </div>
    </div>
  );
};

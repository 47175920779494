import { LinearProgress } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Label } from "../../../../globalComponents/Label";
import { WeightPerItem } from "../../../../globalComponents/WeightPerItem";
import { convertMoneyToText } from "../../../vaquita/lib/money";
import ProductCard from "../../components/DetailsCard";
import Navbar from "../../components/Navbar";
import ProductAddToCartButton from "../../components/ProductAddToCartButton";
import { ProductImagesSlider } from "../../components/ProductImagesSlider/ProductImagesSlider";

const Product = ({
  storeNameLink,
  resultAPI,
  resultAPIOptions,
  quantityInCart,
  setQuantityInCart,
  currentVariant,
  setCurrentVariant,
  currentAddon,
  setCurrentAddon,
  variantSelection,
  setVariantSelection,
  setNextRequired,
  addonSelection,
  setAddonSelection,
  bottomDrawer,
  setBottomDrawer,
  addToCart,
  buyNowButton,
  setBuyNowButton,
  history,
  drawerJSX,
  moveForwardButton,
  multipleOptionsDrawer,
  setMultipleOptionsDrawer,
}) => {
  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
  const deliveryFeePerItem =
    resultAPI?.data?.product?.delivery_fee_per_item > 0
      ? convertMoneyToText(resultAPI?.data?.product?.delivery_fee_per_item)
      : undefined;

  const evaluatePrice = (price, special) => {
    if (price === special || special === "0") {
      return <p>₹ {parseFloat(price).toFixed(2)}</p>;
    } else {
      const discount = parseInt((1 - special / price) * 100);
      return (
        <React.Fragment>
          <span>₹ {parseFloat(special).toFixed(2)}</span>{" "}
          <span style={{ textDecoration: "line-through", fontSize: "1.2rem" }}>
            ₹ {parseFloat(price).toFixed(2)}
          </span>
          <span className="discountCircle">{discount}% OFF</span>
        </React.Fragment>
      );
    }
  };

  if (resultAPI.status === "success") {
    if (
      resultAPI.data.quantity_in_cart !== 0 &&
      quantityInCart === -1 &&
      resultAPI.data.addon.length === 0 &&
      resultAPI.data.variant.length === 0
    )
      setQuantityInCart(resultAPI.data.quantity_in_cart);
    if (
      currentVariant.length === 0 &&
      resultAPI.data.variant.length !== 0 &&
      resultAPIOptions.status !== "success"
    )
      setCurrentVariant(resultAPI.data.variant);

    if (
      currentAddon.length === 0 &&
      resultAPI.data.addon.length !== 0 &&
      resultAPIOptions.status !== "success"
    )
      setCurrentAddon(resultAPI.data.addon);

    if (resultAPIOptions.status === "success") {
      if (resultAPIOptions.data.variant.length > 0) {
        if (
          JSON.stringify(currentVariant) !==
          JSON.stringify(resultAPIOptions.data.variant)
        ) {
          if (variantSelection.length === 0) {
            var temp = JSON.parse(JSON.stringify(variantSelection));
            for (var i = 0; i < resultAPIOptions.data.variant.length; i++) {
              temp[resultAPIOptions.data.variant[i].variant_child_id] = "";
            }
            setVariantSelection(temp);
          }

          setCurrentVariant(resultAPIOptions.data.variant);
        }
      } else if (currentVariant.length > 0) {
        setCurrentVariant([]);
        setNextRequired("false");
      }
      if (resultAPIOptions.data.addon.length > 0) {
        if (
          JSON.stringify(currentAddon) !==
          JSON.stringify(resultAPIOptions.data.addon)
        ) {
          var temp1 = JSON.parse(JSON.stringify(addonSelection));

          for (let i = 0; i < resultAPIOptions.data.addon.length; i++) {
            temp1[resultAPIOptions.data.addon[i].addon_child_id.toString()] =
              [];
            console.log("ADDON TEMP::", temp1);
            for (
              var j = 0;
              j < resultAPIOptions.data.addon[i].options.length;
              j++
            ) {
              temp1[resultAPIOptions.data.addon[i].addon_child_id.toString()][
                resultAPIOptions.data.addon[i].options[
                  j
                ].addon_child_option_id.toString()
              ] = "";
            }
          }
          setAddonSelection(temp1);

          setCurrentAddon(resultAPIOptions.data.addon);
        }
      } else if (currentAddon.length > 0) {
        setCurrentAddon([]);
      }
    }

    const productinfo = resultAPI.data;

    return (
      <div>
        <Navbar storeName={storeNameLink} />

        <div className="product-container">
          <div className="pl-2 py-4 d-flex align-items-center">
            <div
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIcon className="productHeaderIcon" />
            </div>

            <div className="product-page-heading-title">
              {" "}
              {resultAPI.data.category_name}
            </div>
          </div>

          <div className="product-info">
            <Row style={styles.row}>
              <Col xs={12} lg={6} style={styles.col}>
                <div>
                  <ProductImagesSlider
                    images={resultAPI.data.product_images}
                    imageKey="image"
                    youtubeVideoURL={resultAPI.data.product?.video_url}
                  />
                  {/* <Carousel controls={false}>
                    {Object.values(resultAPI.data.product_images).map(
                      (image, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block product-carousel-image"
                            src={image.image}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      )
                    )}
                    <Carousel.Item key="product-video">
                      <iframe
                        width="100%"
                        height="220"
                        src="https://www.youtube.com/embed/TIL1dHDLIzg"
                        title="adasd"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel.Item>
                  </Carousel> */}
                </div>
              </Col>
              <Col xs={12} lg={6}>
                {" "}
                <div className="product-details">
                  <div className="product-name">
                    <h4>{productinfo.product.productname}</h4>
                  </div>

                  <div className="product-price">
                    <h3>
                      {evaluatePrice(
                        productinfo.product.price,
                        productinfo.product.special
                      )}
                    </h3>
                    {productinfo?.product?.gst > 0 &&
                    productinfo?.product?.is_gst_excluding === "1" ? (
                      <h4
                        style={{
                          fontStyle: "italic",
                          color: "#999",
                        }}
                      >
                        ({productinfo?.product?.gst}% GST EXTRA)
                        <br />
                      </h4>
                    ) : null}
                    {productinfo?.product?.minimum_quantity > 1 ? (
                      <Label
                        text={`Minimum Order Quantity: ${productinfo.product.minimum_quantity}`}
                        className="mr-2"
                      />
                    ) : null}
                    {deliveryFeePerItem ? (
                      <Label
                        text={`Delivery Fee Per Item: ${deliveryFeePerItem}`}
                        className="mr-2"
                      />
                    ) : null}
                    <WeightPerItem weight={productinfo.product.weight} />
                    <br /> <br /> <br />
                    <p
                      style={{
                        whiteSpace: "pre-wrap",
                        fontSize: "1.4em",
                        color: "#999",
                      }}
                    >
                      {productinfo.product.productname !==
                      productinfo.product_description.description
                        ? productinfo.product_description.description
                        : null}
                    </p>
                  </div>
                  <div className="product-button-container">
                    <table className="width-100">
                      <td className="width-50 p-1">
                        {currentVariant.length > 0 ||
                        currentAddon.length > 0 ? (
                          <Button
                            className="btn-add pl-5 pr-5 rounded-sm width-100"
                            variant="primary"
                            onClick={() => {
                              setBottomDrawer(true);

                              console.log(bottomDrawer);
                            }}
                          >
                            <span className="addIcon">ADD TO CART</span>
                          </Button>
                        ) : (
                          <ProductAddToCartButton
                            quantityInCart={
                              quantityInCart === -1 ? 0 : quantityInCart
                            }
                            minimumOrderQuantity={parseInt(
                              productinfo?.product?.minimum_quantity
                            )}
                            onChangeQuantity={(quantity) => {
                              addToCart(
                                productinfo.product.product_id,
                                quantity
                              );
                              setQuantityInCart(quantity);
                            }}
                          />
                        )}
                      </td>
                      <td className="width-50 p-1">
                        {quantityInCart < 1 ? (
                          <Button
                            className="btn-add pl-5 pr-5 rounded-sm width-100"
                            variant="primary"
                            onClick={async () => {
                              if (
                                currentVariant.length > 0 ||
                                currentAddon.length > 0
                              ) {
                                setBottomDrawer(true);
                              } else {
                                if (buyNowButton !== "Loading...") {
                                  setBuyNowButton("Loading...");
                                  await addToCart(
                                    productinfo.product.product_id,
                                    productinfo?.product?.minimum_quantity > 0
                                      ? productinfo?.product?.minimum_quantity
                                      : "1"
                                  );
                                  console.log("add to cart done..");

                                  // window.location = "/" + storeName + "/cart";
                                  history.push({
                                    pathname: "/" + storeNameLink + "/cart",
                                  });
                                }
                              }

                              console.log(bottomDrawer);
                            }}
                          >
                            <span className="addIcon">{buyNowButton}</span>
                          </Button>
                        ) : (
                          <Link to={`/${storeNameLink}/cart`}>
                            <Button
                              className="btn-add pl-5 pr-5 rounded-sm width-100"
                              variant="primary"
                            >
                              <span className="addIcon">GO TO CART</span>
                            </Button>{" "}
                          </Link>
                        )}
                      </td>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
            {resultAPI.data.category_products.length > 0 ? (
              <div
                style={{
                  fontSize: "1.8em",
                  fontWeight: "bold",
                  marginTop: "60px",
                  marginBottom: "15px",
                  paddingLeft: "20px",
                }}
              >
                Related Products:
              </div>
            ) : null}
            <Row gutter={40} style={{ paddingLeft: "20px" }}>
              {Object.values(resultAPI.data.category_products).map(
                (product, index) =>
                  index <= 7 && (
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 4 }}
                      md={{ span: 4 }}
                      lg={{ span: 3 }}
                      xl={{ span: 3 }}
                      key={product.product_id}
                    >
                      <ProductCard
                        image={product.product_data.image}
                        text={product.product_data.productname}
                        price={product.product_data.price}
                        special={product.product_data.special}
                        haveOptions={product.product_data.haveOptions}
                        storeName={storeNameLink}
                        productID={product.product_data.product_id}
                        quantityInCart={product.product_data.quantity_in_cart}
                        minimumOrderQuantity={
                          product.product_data.minimum_quantity
                        }
                        addToCart={(quantity) => {
                          addToCart(product.product_data.product_id, quantity);
                        }}
                      />
                    </Col>
                  )
              )}
            </Row>
          </div>
          <div className="margin-bottom-150px"></div>

          <Drawer
            anchor={"bottom"}
            open={bottomDrawer}
            onClose={() => {
              setBottomDrawer(false);
            }}
          >
            {drawerJSX(currentVariant, currentAddon)}
            {moveForwardButton()}
          </Drawer>

          <Drawer
            anchor={"bottom"}
            open={multipleOptionsDrawer}
            onClose={() => {
              setMultipleOptionsDrawer(false);
            }}
          >
            <div className="multipleOptionDrawerText">
              This product have multiple customizations,what you like to do?
            </div>
            <Button
              className="buttonMultipleOptionDrawer"
              onClick={() => {
                setMultipleOptionsDrawer(false);
                setBottomDrawer(true);
                setQuantityInCart(parseInt(quantityInCart) + 1);
                addToCart(
                  resultAPI.data.product.product_id,
                  parseInt(quantityInCart) +
                    productinfo?.product?.minimum_quantity >
                    0
                    ? productinfo?.product?.minimum_quantity
                    : 1
                );
              }}
            >
              Repeat Last
            </Button>{" "}
            <Button
              className="buttonMultipleOptionDrawer"
              onClick={() => {
                window.location =
                  "/" +
                  storeNameLink +
                  "/product/" +
                  resultAPI.data.product.product_id +
                  "/addtocart";
              }}
            >
              I'll Choose Again
            </Button>
          </Drawer>
        </div>
      </div>
    );
  } else {
    return <LinearProgress color="secondary" />;
  }
};
export default Product;

import { ArrowRightAlt } from "@material-ui/icons";
import React from "react";
import Button from "../Button/Button";
import styles from "./style.module.scss";

export const ShowAllCard = ({ actionText }) => {
  return (
    <div className={styles.root}>
      <Button text={actionText} Icon={ArrowRightAlt} />
    </div>
  );
};

import React from "react";
import Button from "../Button/Button";
import styles from "./style.module.scss";
export const CategoryCard = ({
  image,
  catalogName,
  name,
  catalog_id,
  forCatalog,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <img src={image} className={styles.image} />
      </div>
      <div className={`pt-4 pb-2 ${styles.catalogName}`}>{catalogName}</div>
      {!forCatalog && (
        <>
          <div className={`pb-2 ${styles.categoryName}`}>{name}</div>
          <Button text="View" md />{" "}
        </>
      )}
    </div>
  );
};

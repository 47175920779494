import { AlarmOn, LocalOffer, VerifiedUser } from "@material-ui/icons";
import React from "react";
import { IconWithText } from "../../components/IconWithText/IconWithText";
import styles from "./style.module.scss";

export const InfoBand = ({ className }) => {
  return (
    <div className={`${className} ${styles.root} max-width p-4`}>
      <div className="row">
        <IconWithText
          Icon={VerifiedUser}
          className="col-sm-4 mt-5"
          heading="Best Quality"
          subHeading={"No compromise on quality"}
        />
        <IconWithText
          Icon={AlarmOn}
          className="col-sm-4 mt-5"
          heading="24X7 support"
          subHeading={"online support for customers"}
        />
        <IconWithText
          Icon={LocalOffer}
          className="col-sm-4 mt-5"
          flipIcon
          heading="Exciting offers"
          subHeading={"special coupons for you"}
        />
      </div>
    </div>
  );
};

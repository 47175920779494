import React from "react";
import styles from "./styles.module.css";

const Logo = ({ url }) => {
  return (
    <div className={styles.logoContainer}>
      <img src={url} alt="Logo" className={styles.logo} />
    </div>
  );
};

export default Logo;

import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { AccountProvider } from "./helper/AccountContext";
import { CartProvider } from "./helper/cartContext";
import { ThemeProvider } from "./helper/themeContext";
import Account from "./screens/Account";
import Cart from "./screens/Cart";
import Catalog from "./screens/Catalog";
import Category from "./screens/Category";
import Checkout from "./screens/Checkout";
import ConfirmOrder from "./screens/ConfirmOrder";
import EditAddress from "./screens/EditAddress";
import Information from "./screens/Information";
import Invoice from "./screens/Invoice";
import Login from "./screens/Login";
import PaymentSuccess from "./screens/PaymentSuccess";
import Product from "./screens/Product";
import ProductsList from "./screens/ProductsList";
import RequestPayment from "./screens/RequestPayment";
import Search from "./screens/Search";
import Store from "./screens/Store";
import Success from "./screens/Success";

function App() {
  const hostDomain = require("./resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `https://www.nearbybazar.com/favicon/?link=${storeName}`;
  }, [storeName]);

  return (
    <ThemeProvider>
      <AccountProvider>
        <CartProvider>
          {storeName !== "" ? (
            <Router>
              <div>
                <Switch>
                  <Route path="/:link/store" component={Store} />
                  <Route
                    path={`/${storeNameLink}/categories`} //direct to default theme
                    component={Category}
                  />
                  {/*themeChooser enabled*/}
                  <Route path={`/${storeNameLink}/cart`} component={Cart} />

                  <Route path="/:link/account" component={Account} />
                  <Route
                    path={`/${storeNameLink}/product/:ProductID`} //themeChooser enabled
                    component={Product}
                  />
                  <Route
                    path={`/${storeNameLink}/search/:searchItem`} //themeChooser enabled
                    component={Search}
                  />
                  <Route
                    path={`/${storeNameLink}/checkout`} //OBSELETE! direct to default theme
                    component={Checkout}
                  />
                  {/*themeChooser enabled*/}
                  <Route path={`/${storeNameLink}/login`} component={Login} />
                  <Route
                    path={`/${storeNameLink}/category/:CategoryID`} //themeChooser enabled
                    component={ProductsList}
                  />
                  <Route
                    path={`/${storeNameLink}/catalog/:CatalogID`} //themeChooser enabled
                    component={Catalog}
                  />
                  <Route
                    path={`/${storeNameLink}/invoice/:InvoiceID`}
                    component={Invoice}
                  />
                  <Route
                    path={`/${storeNameLink}/orderconfirm`} //themeChooser enabled
                    component={ConfirmOrder}
                  />
                  {/*themeChooser enabled*/}
                  <Route
                    path={`/${storeNameLink}/success`}
                    component={Success}
                  />
                  <Route
                    path={`/${storeNameLink}/requestPayment`} //direct to default theme
                    component={RequestPayment}
                  />
                  <Route
                    path={`/${storeNameLink}/paymentsuccess`} //themeChooser enabled
                    component={PaymentSuccess}
                  />
                  <Route
                    path={`/${storeNameLink}/editaddress`}
                    component={EditAddress}
                  />
                  <Route
                    path={`/${storeNameLink}/about-us`} //themeChooser enabled
                    component={Information}
                  />
                  <Route
                    path={`/${storeNameLink}/terms-and-conditions`} //themeChooser enabled
                    component={Information}
                  />
                  <Route
                    path={`/${storeNameLink}/privacy-policy`} //themeChooser enabled
                    component={Information}
                  />
                  <Route
                    path={`/${storeNameLink}/return-policy`} //themeChooser enabled
                    component={Information}
                  />
                  <Route
                    path={`/${storeNameLink}/shipping-policy`} //themeChooser enabled
                    component={Information}
                  />
                  <Redirect exact from="/:link" to="/:link/store" />
                  <Redirect exact from="/" to="/home/store" />
                </Switch>
              </div>
            </Router>
          ) : null}
        </CartProvider>
      </AccountProvider>
    </ThemeProvider>
  );
}

export default App;

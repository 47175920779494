import { FormatQuote, Star } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import styles from "./styles.module.css";

const Stars = ({ rating }) => {
  return rating > 0 ? (
    <div className="d-flex mt-4 mb-3">
      {[1, 1, 1, 1, 1].map((item, index) => {
        return index < rating ? (
          <Star className={styles.starActive} />
        ) : (
          <Star className={styles.starInactive} />
        );
      })}
    </div>
  ) : (
    <></>
  );
};

const ReviewCard = ({ name, review, rating, className }) => {
  const [expanded, setExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const clampRef = useRef(null);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const element = clampRef.current;
    if (element?.scrollHeight > element?.clientHeight) {
      setIsClamped(true);
    } else {
      setIsClamped(false);
    }
  }, [review]);
  return (
    <div className={`${className} py-4 px-3`}>
      <div className={styles.card}>
        <FormatQuote className={styles.quoteTop} />
        <FormatQuote className={styles.quoteBottom} />
        <Stars rating={rating} />
        <div className="position-relative">
          <div
            ref={clampRef}
            className={`${styles.review} ${expanded ? "" : styles.clamped}`}
          >
            "{review}"
          </div>
          {isClamped && !expanded && (
            <div className={styles.readMore} onClick={toggleExpansion}>
              Read More
            </div>
          )}
          {expanded ? (
            <div className={styles.hide} onClick={toggleExpansion}>
              Hide
            </div>
          ) : null}
        </div>
        <div className={styles.name}>-{name}</div>
      </div>
    </div>
  );
};

const Reviews = ({ data }) => {
  const swiperRef = useRef(null);
  const [isEnd, setIsEnd] = useState(data?.length <= 3);
  const [isBeginning, setIsBeginning] = useState(true);

  const handleSlideChange = (e) => {
    e?.isEnd ? setIsEnd(true) : setIsEnd(false);

    e?.isBeginning ? setIsBeginning(true) : setIsBeginning(false);
  };

  const handlePrevClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const getSwiperSlide = () => {
    const renderableElements = [];
    const ITEMS_PER_SLIDE = 3;
    var count = 0;
    var keyCounter = 0;
    if (data.length === 0) {
      renderableElements.push(<></>);
    }
    while (count < data.length) {
      keyCounter++;
      const renderableData = [];
      const end =
        count + ITEMS_PER_SLIDE <= data.length
          ? count + ITEMS_PER_SLIDE
          : data.length;
      for (let i = count; i < end; i++) {
        if (data[i]) renderableData.push(data[i]);
      }

      renderableElements.push(
        <SwiperSlide key={`${keyCounter}-reviews`}>
          <div
            className={`row max-width justify-content-center p-0 mt-4 ${styles.boxSizing}`}
          >
            {renderableData.map((item, index) => {
              return (
                <ReviewCard
                  className="col-lg-4 col-sm-6"
                  name={item.name}
                  review={item.review}
                  rating={item.rating}
                  key={`review-${item.biz_shop_review_id}`}
                />
              );
            })}
          </div>
        </SwiperSlide>
      );
      count =
        count + ITEMS_PER_SLIDE <= data.length
          ? count + ITEMS_PER_SLIDE
          : data.length;
    }
    return renderableElements;
  };

  return data?.length ? (
    <div className="max-width my-5 py-5 position-relative">
      <div className={styles.heading}>What our customers are saying</div>
      <div
        className={`${styles.leftButton} ${styles.button} ${
          isBeginning ? styles.disabled : ""
        }`}
        onClick={handlePrevClick}
      >
        <ChevronLeftIcon className={styles.buttonIcon} />
      </div>

      <div
        className={`${styles.rightButton} ${styles.button} ${
          isEnd ? styles.disabled : ""
        }`}
        onClick={handleNextClick}
      >
        <ChevronRightIcon className={styles.buttonIcon} />
      </div>

      <div className="p-4 px-5">
        <Swiper
          spaceBetween={0}
          ref={swiperRef}
          slidesPerView={1}
          onSlideChange={(e) => {
            handleSlideChange(e);
          }}
        >
          {getSwiperSlide().map((item, index) => {
            return item;
          })}
        </Swiper>
      </div>
    </div>
  ) : null;
};

export default Reviews;

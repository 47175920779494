import React from "react";
import "./global.css";
import Account from "./pages/Account";
import Cart from "./pages/Cart";
import Catalog from "./pages/Catalog";
import Category from "./pages/Category";
import ConfirmOrder from "./pages/ConfirmOrder";
import Information from "./pages/Information";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import PaymentSuccess from "./pages/PaymentSuccess";
import Product from "./pages/Product";
import Search from "./pages/Search";
import Home from "./pages/Store/Home";
import Success from "./pages/Success";
import { Footer } from "./widgets/Footer/Footer";
import Header from "./widgets/Header/Header";

const Main = ({ props, name }) => {
  const hostDomain = require("../../resources/hostdomain.json");
  const domain = window.location.hostname;
  const storeName =
    domain !== hostDomain.domain
      ? domain
      : window.location.pathname.split("/")[1];
  const storeNameLink = domain !== hostDomain.domain ? "home" : storeName;
  switch (name) {
    case "home":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Home
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "information":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Information
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "cart":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Cart
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "product":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Product
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "category":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Category
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "catalog":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Catalog
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "account":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Account
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "confirm-order":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <ConfirmOrder
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "login":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Login
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "success":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <Success
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "payment-success":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />
            <PaymentSuccess
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    case "search":
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header
              storeName={storeName}
              storeNameLink={storeNameLink}
              initialSearchText={props.searchValue}
            />
            <Search
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
    default:
      return (
        <div className="vh-100">
          <div className="theme-vaquita">
            <Header storeName={storeName} storeNameLink={storeNameLink} />

            <PageNotFound
              {...props}
              storeNameLink={storeNameLink}
              storeName={storeName}
            />
            <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
          </div>
        </div>
      );
  }

  /*  return (
    <div className="theme-vaquita">
      <Page />
      <Footer className="vaquita-mt-2xxl" storeNameLink={storeNameLink} />
    </div>
  );*/
};

export default Main;

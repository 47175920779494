import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ThemeChooser from "../helper/ThemeChooser";

const Success = ({ ...props }) => {
  const history = useHistory();
  var orderID = "";
  const location = useLocation();
  const hostDomain = require("../resources/hostdomain.json");
  var storeName = window.location.pathname.split("/")[1];
  const domain = window.location.hostname;

  if (domain !== hostDomain.domain) {
    storeName = domain;
  }
  var storeNameLink = domain !== hostDomain.domain ? "home" : storeName;

  if (props.location.state === undefined) {
    history.push({
      pathname: "/" + storeNameLink + "/store",
    });
  } else {
    orderID = props.location.state.orderid;
  }
  return (
    <ThemeChooser
      props={{ orderID: orderID, storeNameLink: storeNameLink }}
      name="success"
    />
  );
};

export default Success;

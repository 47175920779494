import React, { createContext, useState } from "react";
export const themeContext = createContext();
export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState("");
  return (
    <themeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
      {props.children}
    </themeContext.Provider>
  );
};

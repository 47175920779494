import { useContext, useState } from "react";
import APICreator from "./APICreator";
import { themeContext } from "./themeContext";

export default () => {
  const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };
  const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const [errorMessageAPI, setErrorMessageAPI] = useState("");
  const [resultAPI, setResultAPI] = useState([]);
  const { setTheme } = useContext(themeContext);
  var serializeJSON = function (data) {
    data.session_id = getCookie("PHPSESSID1");
    return Object.keys(data)
      .map(function (keyName) {
        return (
          encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
        );
      })
      .join("&");
  };
  const nearbybazarAPI = async (data, url, type = "store") => {
    //console.log("API REQUEST IN PROCESS FROM HOOK " + url);

    try {
      setErrorMessageAPI("");
      setResultAPI([]);
      //console.log(serializeJSON(data));
      const response = await APICreator.post(
        `${type}${url}`,
        serializeJSON(data)
      );
      // console.log(response.data);
      if (response.data.session_id) {
        setCookie("PHPSESSID1", response.data.session_id, 1);
      }
      if (response.data.status === "error") {
        if (response.data.message_code === "NOSTORE")
          window.location = "https://www.lobecho.com";
        if (response.data.message_code === "NOSTORE_REDIRECT")
          window.location = response.data.redirect;
      }
      if (response?.data?.theme) {
        // console.log("theme", response?.data?.theme);
        if (response.data.theme !== "" && setTheme)
          setTheme(response.data.theme);
      }
      setResultAPI(response.data);
      return response.data;
    } catch (e) {
      setErrorMessageAPI("Something is not right...");
      console.log(e);
    }
  };

  return [nearbybazarAPI, resultAPI, errorMessageAPI];
};
